import {
  // AlertColor,
  Avatar,
  Button,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import LocationAutoComplete from '../../../components/common/location-autocomplete';
import { useEffect, useState } from 'react';
import { ReactComponent as Visibility } from './../../assets/login/visibility.svg';
import { ReactComponent as VisibilityOff } from './../../assets/login/visibility_off.svg';
import globalObject from '../../../common/global-variables';
import { changePassword } from '../../../service/securityService';
import { FormikProps, useFormik } from 'formik';
import * as Yup from 'yup';
import { getUserById, updateUser } from '../../../service/userService';
import { UserDetails } from '../../models/user.model';
// import { UserDetails } from '../../models/user.model';

const NewProfile = () => {
  const [user, setUser] = useState({} as UserDetails);
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [showChangePasswordForm, setShowChangePasswordForm] = useState(false);
  // const [showToast, setShowToast] = useState(false);
  // const [toastMsg, setToastMsg] = useState({
  //   severity: 'success',
  //   msg: '',
  // } as {
  //   severity: AlertColor;
  //   msg: string;
  // });

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  useEffect(() => {
    getUserById(globalObject.userObject.id, true).then((res) => setUser(res.data));
  }, []);

  const userDetailsSchema = Yup.object().shape({
    firstName: Yup.string().required('First Name is required'),
    lastName: Yup.string().required('Last Name is required'),
    phoneNumber: Yup.string().required('Contact Number is required'),
    location: Yup.string().required('Location is required'),
    zipCode: Yup.string().required('Zip Code is required'),
  });

  const changePasswordSchema = Yup.object().shape({
    currentPassword: Yup.string().required('Current Password is required'),
    newPassword: Yup.string()
      .notOneOf([Yup.ref('currentPassword')], 'Current password and new password must be different')
      .min(8, 'Password must be at least 8 characters long')
      .required('New Password is required'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('newPassword')], 'New password and confirm password must be the same')
      .required('Confirm password is required'),
  });

  const userformik = useFormik({
    initialValues: user || {
      id: undefined,
      firstName: '',
      lastName: '',
      phoneNumber: '',
      email: '',
      operations: '',
      roles: '',
      level: '',
      managerId: '',
      location: '',
      zipCode: '',
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      console.log(values);
      values &&
        updateUser(values, true)
          .then(() => console.log('edited'))
          .catch(() => console.log('canceled'));
      console.log('fadsfda', values);
      // setShowToast(true);
      // setToastMsg({ severity: 'success', msg: 'Profile updated successfully!' });
    },
    validationSchema: userDetailsSchema,
  });

  const setLocationCallback = (loc?: string) => {
    userformik.setFieldValue('location', loc);
  };

  const formik = useFormik({
    initialValues: {
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
    },
    onSubmit: (values) => {
      handleSubmit(values);
    },
    validationSchema: changePasswordSchema,
  });

  // const handleToastClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
  //   if (reason === 'clickaway') {
  //     return;
  //   }
  //   setShowToast(false);
  // };

  function getError(formik: FormikProps<any>, fieldName: string) {
    if ((submitted || formik.touched[fieldName]) && formik.errors[fieldName])
      return formik.errors[fieldName]?.toString();
    return null;
  }

  const handleSubmit = (values: any) => {
    changePassword(values.newPassword, values.currentPassword, values.flag)
      .then((resp) => {
        if (resp.status == 200) {
          // setShowToast(true);
          // setToastMsg({
          //   severity: 'success',
          //   msg: 'Password has been reset successfully!',
          // });
          localStorage.removeItem('token');
          localStorage.removeItem('refreshtoken');
          // setErrorMessage('');
          // setShowAert(true);
        }
      })

      .catch((_err) => {
        // setShowToast(true);
        // setToastMsg({
        //   severity: 'error',
        //   msg: 'Failed to Reset Password.',
        // });
      });
  };

  return (
    <div style={{ width: '100%', gap: '1.87rem', display: 'flex', flexDirection: 'column' }}>
      <div
        style={{
          height: '8.8125rem',
          width: '100%',
        }}>
        <Paper
          className='new-job-requisition'
          sx={{
            width: '100%',
            height: '100%',
          }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginLeft: '1.88rem',
              gap: '7px',
            }}>
            <span style={{ fontSize: '1.25rem', fontWeight: 600 }}>Profile</span>
            <span
              style={{
                fontSize: '0.875rem',
                fontWeight: 400,
                color: 'var(--Secondary-Grey-1, var(--Secondary-colors-Grey, #868686))',
              }}>
              Manage your profile and contact details
            </span>
          </div>
          <div
            style={{
              display: 'flex',
              marginRight: '2.5rem',
              alignItems: 'center',
              gap: '2.31rem',
            }}></div>
        </Paper>
      </div>
      <div
        style={{
          width: '100%',
          height: '100%',
        }}>
        <Paper
          sx={{
            width: '100%',
            height: '100%',
          }}>
          <div style={{ padding: '1.94rem 1.94rem 0rem 1.88rem' }}>
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '4.25rem' }}>
              <Avatar
                alt='Remy Sharp'
                sx={{ width: '7.625rem', height: '7.625rem', marginRight: '1.75rem' }}
                // src={require('../../../assets/user-icon.png')}
              />
              <div style={{ gap: '20px', display: 'flex' }}>
                <Button
                  className='button'
                  sx={{
                    textTransform: 'none',
                    fontWeight: 400,
                    fontSize: '1rem',
                    width: '8.25rem',
                    height: '2.5rem',
                    color: 'white !important',
                    background: '#2D7DFE !important',
                  }}>
                  Change Picture
                </Button>
                <Button
                  className='button'
                  sx={{
                    width: '8.25rem',
                    height: '2.5rem',
                    borderRadius: '5px',
                    border: '1px solid #2D7DFE',
                    backgroundColor: '#FFF',
                    color: 'black',
                    textTransform: 'none',
                    marginRight: '10px',
                    fontSize: '1rem',
                    fontWeight: 400,
                  }}>
                  Delete Picture
                </Button>
              </div>
            </div>
            {/* <span>{JSON.stringify(userformik.values)}</span> */}
            <div>
              <div className='field-container'>
                <div style={{ width: '50%' }}>
                  <Typography sx={{ fontWeight: 600 }}>First Name</Typography>
                  <span className='feildHeader'>Your display name in the application</span>
                </div>
                <div style={{ width: '50%' }}>
                  <TextField
                    className='input-field'
                    fullWidth
                    sx={{
                      height: 'var(--Font-size-h2, 2.5rem)',
                    }}
                    name='profileName'
                    value={userformik.values.firstName}
                    onChange={(e) => {
                      userformik.setFieldValue('firstName', e.target.value);
                    }}
                    onBlur={userformik.handleBlur}></TextField>
                  <div className='mt-0 form-err-message'>{getError(userformik, 'firstName')}</div>
                </div>
              </div>
              <div className='field-container'>
                <div style={{ width: '50%' }}>
                  <Typography sx={{ fontWeight: 600 }}>Last Name</Typography>
                  <span className='feildHeader'>Your display name in the application</span>
                </div>
                <div style={{ width: '50%' }}>
                  <TextField
                    className='input-field'
                    fullWidth
                    sx={{
                      height: 'var(--Font-size-h2, 2.5rem)',
                    }}
                    name='profileName'
                    value={userformik.values.lastName}
                    onChange={(e) => {
                      userformik.setFieldValue('lastName', e.target.value);
                    }}
                    onBlur={userformik.handleBlur}></TextField>
                  <div className='mt-0 form-err-message'>{getError(userformik, 'lastName')}</div>
                </div>
              </div>
              <div className='field-container'>
                <div style={{ width: '50%' }}>
                  <Typography sx={{ fontWeight: 600 }}>Role</Typography>
                  <span className='feildHeader'>
                    {'Your jobs primary focus or key responsibilities'}
                  </span>
                </div>
                <div style={{ width: '50%' }}>
                  <TextField
                    className='input-field'
                    fullWidth
                    sx={{
                      height: 'var(--Font-size-h2, 2.5rem)',
                    }}
                    disabled
                    value={user.roles && user.roles?.map((role: any) => role.name)}></TextField>
                </div>
              </div>
              <div className='field-container'>
                <div style={{ width: '50%' }}>
                  <Typography sx={{ fontWeight: 600 }}>Email</Typography>
                  <span className='feildHeader'>
                    Your primary means of communication and username
                  </span>
                </div>
                <div style={{ width: '50%' }}>
                  <TextField
                    fullWidth
                    className='input-field'
                    sx={{
                      height: 'var(--Font-size-h2, 2.5rem)',
                    }}
                    disabled
                    value={`${user.email}`}></TextField>
                </div>
              </div>
              <div className='field-container'>
                <div style={{ width: '50%' }}>
                  <Typography sx={{ fontWeight: 600 }}>Contact Number</Typography>
                  <span className='feildHeader'>Your availability and primary contact</span>
                </div>
                <div style={{ width: '50%' }}>
                  <TextField
                    className='input-field'
                    fullWidth
                    sx={{
                      height: 'var(--Font-size-h2, 2.5rem)',
                    }}
                    name='phoneNumber'
                    value={userformik.values.phoneNumber}
                    onChange={userformik.handleChange}
                    onBlur={userformik.handleBlur}
                    error={Boolean(
                      userformik.touched.phoneNumber && userformik.errors.phoneNumber,
                    )}></TextField>
                  <div className='mt-0 form-err-message'>{getError(userformik, 'phoneNumber')}</div>
                </div>
              </div>
              <div className='field-container'>
                <div style={{ width: '50%' }}>
                  <Typography sx={{ fontWeight: 600 }}>Location</Typography>
                  <span className='feildHeader'>Your current work location</span>
                </div>
                <div style={{ width: '50%' }}>
                  {' '}
                  <div className='input-field' style={{ marginBottom: '0.62rem' }}>
                    <LocationAutoComplete
                      onSelect={setLocationCallback}
                      initialValue={userformik.values.location}
                    />
                    <div className='mt-0 form-err-msg'>{getError(userformik, 'location')}</div>
                  </div>
                </div>
              </div>
              <div className='field-container'>
                <div style={{ width: '50%' }}>
                  <Typography sx={{ fontWeight: 600 }}>Zip Code</Typography>
                  <span className='feildHeader'>Your unique area code</span>
                </div>
                <div style={{ width: '50%' }}>
                  <TextField
                    className='input-field'
                    fullWidth
                    sx={{
                      height: 'var(--Font-size-h2, 2.5rem)',
                    }}
                    name='zipCode'
                    value={userformik.values.zipCode}
                    onChange={userformik.handleChange}
                    onBlur={userformik.handleBlur}
                    error={Boolean(
                      userformik.touched.zipCode && userformik.errors.zipCode,
                    )}></TextField>
                  <div className='mt-0 form-err-message'>{getError(userformik, 'zipCode')}</div>
                </div>
              </div>
              <div
                style={{
                  alignItems: 'center',
                  marginBottom: '1.87rem',
                }}>
                <hr style={{ background: '#868686' }} />
              </div>
              <div className='field-container' style={{ flexDirection: 'column' }}>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    width: '100%',
                  }}>
                  <div
                    style={{
                      width: '50%',
                      height: '7.125rem',
                      marginLeft: '2rem',
                      marginRight: '28rem',
                      marginTop: '2.06rem',
                      alignItems: 'center',
                    }}>
                    <Typography sx={{ fontWeight: 600 }}>Change Password</Typography>
                    <span className='feildHeader'>
                      Set a unique password to protect your account
                    </span>
                  </div>
                  <div>
                    <Button
                      className='button'
                      sx={{
                        height: '2.5rem',
                        borderRadius: '5px',
                        border: '1px solid #2D7DFE',
                        backgroundColor: '#FFF',
                        color: 'black',
                        textTransform: 'none',
                        marginRight: '1.88rem',
                        fontSize: '1rem',
                        fontWeight: 400,
                        // marginTop: '3.06rem',
                        // justifyContent: 'center',
                      }}
                      onClick={() => setShowChangePasswordForm(!showChangePasswordForm)}>
                      {showChangePasswordForm ? 'Cancel' : 'Change Password'}
                    </Button>
                  </div>
                </div>
                {showChangePasswordForm && (
                  <form onSubmit={formik.handleSubmit} style={{ width: '100%' }}>
                    <div style={{ marginLeft: '2rem', marginTop: '2rem' }}>
                      <div className='field-container'>
                        <div style={{ width: '50%' }}>
                          <Typography sx={{ fontWeight: 600 }}>Current Password</Typography>
                          <span className='feildHeader'>Your existing password</span>
                        </div>
                        <div style={{ width: '50%' }}>
                          <OutlinedInput
                            fullWidth
                            className='input-field'
                            sx={{ height: 'var(--Font-size-h2, 2.5rem)' }}
                            placeholder='*********'
                            type={showCurrentPassword ? 'text' : 'password'}
                            value={formik.values.currentPassword}
                            error={Boolean(
                              formik.errors.currentPassword && formik.touched.currentPassword,
                            )}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            name='currentPassword'
                            endAdornment={
                              <InputAdornment position='end'>
                                <IconButton
                                  size='small'
                                  aria-label='toggle password visibility'
                                  onClick={() => setShowCurrentPassword((show) => !show)}
                                  onMouseDown={handleMouseDownPassword}
                                  edge='end'>
                                  {showCurrentPassword ? (
                                    <Visibility className='my-svg-icon' />
                                  ) : (
                                    <VisibilityOff className='my-svg-icon' />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            }
                          />
                          <div className='mt-0 form-err-message'>
                            {getError(formik, 'currentPassword')}
                          </div>
                        </div>
                      </div>

                      <div className='field-container'>
                        <div style={{ width: '50%' }}>
                          <Typography sx={{ fontWeight: 600 }}>New Password</Typography>
                          <span className='feildHeader'>Enter new password</span>
                        </div>
                        <div style={{ width: '50%' }}>
                          <OutlinedInput
                            fullWidth
                            className='input-field'
                            sx={{ height: 'var(--Font-size-h2, 2.5rem)' }}
                            placeholder='*********'
                            type={showNewPassword ? 'text' : 'password'}
                            value={formik.values.newPassword}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            name='newPassword'
                            error={Boolean(formik.errors.newPassword && formik.touched.newPassword)}
                            endAdornment={
                              <InputAdornment position='end'>
                                <IconButton
                                  size='small'
                                  aria-label='toggle password visibility'
                                  onClick={() => setShowNewPassword((show) => !show)}
                                  onMouseDown={handleMouseDownPassword}
                                  edge='end'>
                                  {showNewPassword ? (
                                    <Visibility className='my-svg-icon' />
                                  ) : (
                                    <VisibilityOff className='my-svg-icon' />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            }
                          />
                          <div className='mt-0 form-err-message'>
                            {getError(formik, 'newPassword')}
                          </div>
                        </div>
                      </div>
                      <div className='field-container'>
                        <div style={{ width: '50%' }}>
                          <Typography sx={{ fontWeight: 600 }}>Verify New Password</Typography>
                          <span className='feildHeader'>Enter new password</span>
                        </div>
                        <div style={{ width: '50%' }}>
                          <OutlinedInput
                            className='input-field'
                            fullWidth
                            sx={{ height: 'var(--Font-size-h2, 2.5rem)' }}
                            placeholder='*********'
                            type={showConfirmPassword ? 'text' : 'password'}
                            value={formik.values.confirmPassword}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={Boolean(
                              formik.errors.confirmPassword && formik.touched.confirmPassword,
                            )}
                            name='confirmPassword'
                            endAdornment={
                              <InputAdornment position='end'>
                                <IconButton
                                  size='small'
                                  aria-label='toggle password visibility'
                                  onClick={() => setShowConfirmPassword((show) => !show)}
                                  onMouseDown={handleMouseDownPassword}
                                  edge='end'>
                                  {showConfirmPassword ? (
                                    <Visibility className='my-svg-icon' />
                                  ) : (
                                    <VisibilityOff className='my-svg-icon' />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            }
                          />
                          <div className='mt-0 form-err-message'>
                            {getError(formik, 'confirmPassword')}
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                )}
              </div>
            </div>
          </div>
          <div
            style={{
              gap: '24.56rem',
              alignItems: 'center',
              marginBottom: '1.87rem',
            }}>
            <hr style={{ background: '#868686' }} />
          </div>
          <div style={{ gap: '20px', display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              className='button'
              sx={{
                width: '8.25rem',
                height: '2.5rem',
                borderRadius: '5px',
                border: '1px solid #2D7DFE',
                backgroundColor: '#FFF',
                color: 'black',
                textTransform: 'none',
                fontSize: '1rem',
                fontWeight: 400,
              }}
              onClick={() => {
                setSubmitted(true);
                formik.handleSubmit();
              }}>
              Cancel
            </Button>
            <Button
              className='button'
              sx={{
                textTransform: 'none',
                fontWeight: 400,
                fontSize: '1rem',
                width: '8.25rem',
                height: '2.5rem',
                marginRight: '2rem',
                color: 'white !important',
                background: '#2D7DFE !important',
              }}
              onClick={() => {
                setSubmitted(true);
                userformik.handleSubmit();
              }}
              type='submit'>
              Save
            </Button>
          </div>
        </Paper>
      </div>
    </div>
  );
};
export default NewProfile;
