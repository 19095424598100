import { AlertColor, Backdrop, CircularProgress, Paper, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
// import PageHeader from '../../../components/common/page-header/page-header';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import HiringTeam from './hiring-team';
import JobDefinitionDetails from './job-definition-details';
import SourcingStrategy from './sourcing-strategy';
import InterViewProcess from './interview-process';
import { ReactComponent as DoneIcon } from './../../assets/common-assets/Vector.svg';
import { getTadSjd, tadUpdateSjd } from '../../service/tadSjdService';
import { useParams } from 'react-router-dom';
import { TadSjdModel } from '../../models/tad-sjd-form-model';
import globalObject from '../../../common/global-variables';
import Notify from '../../common/notify';
import useNavigate from '../../../common/useNavigate';
import TadPageHeader from '../common/page-header/tad-page-header';
import { ReactComponent as PlusIcon } from './../../assets/dashboard-assets/plusIcon.svg';

const TadSourceJobDefinitionEdit = () => {
  let { sjdId } = useParams();
  if (sjdId) sjdId = atob(sjdId);
  const [activeStep, setActiveStep] = useState<number | null>(1);
  const [sjd, setSjd] = useState({} as any);
  const [navigate] = useNavigate();
  const [loading, setLoading] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMsg, setToastMsg] = useState({
    severity: 'success',
    msg: '',
  } as {
    severity: AlertColor;
    msg: string;
  });

  const [formValue, setFormValue] = useState({
    details: {},
    sourcingDetails: {},
    hiringTeam: {},
    interviewProcessDetails: {},
  } as TadSjdModel);

  useEffect(() => {
    setLoading(true);
    getTadSjd(sjdId)
      .then((res) => {
        setLoading(false);
        setSjd(res.data);
      })
      .catch((e) => console.log(e));
  }, []);

  const appendFieldValues = (values: any, formName: string) => {
    const updatedValue = formValue;
    updatedValue[formName as keyof typeof formValue] = values;
    setFormValue(updatedValue);
  };

  const StepperList = () => {
    const steps = [
      {
        number: 1,
        title: 'Job Definition Details For',
        description: 'Product Manager',
        location: 'Hyderabad, Amazon',
      },
      { number: 2, title: 'Sourcing Strategy' },
      { number: 3, title: 'Hiring Team' },
      { number: 4, title: 'Interview Process' },
    ];

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '18.5625rem',
          height: '19.5625rem',
          borderRight: '0.0625rem solid #C1C0C0',
          overflowY: 'auto',
        }}>
        {steps.map((step) => (
          <div
            key={step.number}
            // onClick={() => handleExpand(step.number)}
            style={{
              display: 'flex',
              alignItems: 'center',
              padding: '8px',
              backgroundColor: '#fff',
              borderBottom: '0.0625rem solid #C1C0C0',
              borderLeft:
                activeStep === step.number
                  ? ' 0.1875rem solid #DC3857'
                  : activeStep && activeStep > step.number
                  ? '0.1875rem solid #1976D2'
                  : '0.1875rem solid #fff',
              boxSizing: 'border-box',
              justifyContent: 'space-between',
              flex: 1,
            }}>
            <div style={{ display: 'flex', flex: 1, padding: '10px' }}>
              <div
                style={{
                  width: '1.875rem',
                  height: '1.875rem',
                  borderRadius: '50%',
                  backgroundColor:
                    activeStep === step.number
                      ? 'var(--Status-Red-500, #DC3857)'
                      : activeStep && activeStep > step.number
                      ? 'var(--Primary-Blue-500, #1976D2)'
                      : 'var(--Secondary-colors-Grey-3, #F1F4F8)',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginRight: '1.25rem',
                  color:
                    activeStep === step.number
                      ? 'var(--Secondary-colors-White, #FFF)'
                      : 'var(--Primary-Dark-grey-500, #333)',
                }}>
                <Typography variant='body2' style={{ display: 'flex', fontWeight: 'bold' }}>
                  {activeStep && activeStep > step.number ? <DoneIcon /> : step.number}
                </Typography>
              </div>
              <div style={{ flex: 1, display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                <Typography
                  sx={{
                    fontSize: '1rem',
                    fontWeight: '400',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  }}>
                  {step.title}
                </Typography>
                {step.description && (
                  <Typography
                    sx={{
                      fontSize: '1rem',
                      fontWeight: '600',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                    }}>
                    {step.description}
                  </Typography>
                )}
                {step.location && (
                  <Typography
                    sx={{
                      fontSize: '1rem',
                      fontWeight: '400',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                    }}>
                    {step.location}
                  </Typography>
                )}
              </div>
              {activeStep === step.number && (
                <div>
                  <ArrowForwardIosIcon
                    style={{ width: '1rem', height: '1rem', marginTop: '5px' }}
                  />
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    );
  };

  const saveSjd = (values: any, formName: string) => {
    // setShowOverlay(true);
    appendFieldValues(values, formName);
    let request: any = {};
    Object.keys(formValue).forEach((key, _i) => {
      request = { ...request, ...formValue[key as keyof typeof formValue] };
    });
    const team = [...request.hiringTeam, { id: globalObject.userObject.id }];
    request.hiringTeam = team;
    tadUpdateSjd(request)
      .then((_resp) => {
        setShowToast(true);
        setToastMsg({
          severity: 'success',
          msg: 'Source job requisition created successfully!',
        });
        navigate([
          {
            label: 'JOB DEFINITIONS',
            link: '/source-job-definition',
          },
        ]);
      })
      .catch((_err) => {
        setShowToast(true);
        setToastMsg({
          severity: 'error',
          msg: 'Something went wrong. Please try later!',
        });
      });
  };

  return (
    <>
      <div className='main-div'>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{ marginBottom: '1.19rem' }}>
            <TadPageHeader />
          </div>
          <div style={{ gap: '1.94rem', display: 'flex', flexWrap: 'nowrap' }}>
            <div className='paper' style={{ height: '19.5625rem' }}>
              <Paper sx={{ width: '18.5625rem' }}>
                <StepperList />
              </Paper>
            </div>

            <div
              style={{ width: '100%', display: 'flex', gap: '1.63rem', flexDirection: 'column' }}>
              {activeStep == 1 && (
                <>
                  <div
                    className='paper'
                    style={{
                      height: '8.8125rem',
                      width: '100%',
                    }}>
                    <Paper
                      className='new-job-requisition'
                      sx={{
                        width: '100%',
                        height: '100%',
                      }}>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          marginLeft: '1.88rem',
                          gap: '7px',
                        }}>
                        <span style={{ fontSize: '1.25rem', fontWeight: 600 }}>
                          Job Definition Details
                        </span>
                        <span
                          style={{
                            fontSize: '0.875rem',
                            fontWeight: 400,
                            color: 'var(--Secondary-Grey-1, var(--Secondary-colors-Grey, #868686))',
                          }}>
                          Outline the key responsibilities, required qualifications, and performance
                          expectations for a specific role.
                        </span>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          marginRight: '3.05rem',
                          alignItems: 'center',
                          gap: '2.31rem',
                        }}>
                        <button
                          onClick={() =>
                            navigate([
                              {
                                label: 'AddCandidate',
                                link: '/candidate/add-candidate',
                              },
                            ])
                          }
                          style={{
                            width: '11.375rem',
                            height: '3.125rem',
                            flexShrink: 0,
                            borderRadius: '0.3125rem',
                            background: 'var(--Primary-Blue-500, #1976D2)',
                            color: '#FFF',
                            border: 'none',
                            fontFamily: 'Roboto',
                            fontSize: '1rem',
                            fontWeight: 500,
                            cursor: 'pointer',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            padding: '0 1rem',
                            gap: '0.5rem',
                          }}>
                          <PlusIcon style={{ width: '1.2rem', height: '1.2rem' }} />
                          Add Candidates
                        </button>
                      </div>
                    </Paper>
                  </div>

                  <JobDefinitionDetails
                    sjdDetails={
                      Object.keys(formValue.details).length != 0 ? formValue.details : sjd
                    }
                    save={(values) => {
                      appendFieldValues(values, 'details');
                      setActiveStep(activeStep + 1);
                    }}
                  />
                </>
              )}

              {activeStep == 2 && (
                <>
                  <div
                    className='paper'
                    style={{
                      height: '8.8125rem',
                      width: '100%',
                    }}>
                    <Paper
                      className='new-job-requisition'
                      sx={{
                        width: '100%',
                        height: '100%',
                      }}>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          marginLeft: '1.88rem',
                          gap: '7px',
                        }}>
                        <span style={{ fontSize: '1.25rem', fontWeight: 600 }}>
                          Sourcing Strategy
                        </span>
                        <span
                          style={{
                            fontSize: '0.875rem',
                            fontWeight: 400,
                            color: 'var(--Secondary-Grey-1, var(--Secondary-colors-Grey, #868686))',
                          }}>
                          Outline a comprehensive plan to identify, attract, and engage potential
                          candidates
                        </span>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          marginRight: '3.05rem',
                          alignItems: 'center',
                          gap: '2.31rem',
                        }}>
                        <button
                          style={{
                            width: '11.375rem',
                            height: '3.125rem',
                            flexShrink: 0,
                            borderRadius: '0.3125rem',
                            background: 'var(--Primary-Blue-500, #1976D2)',
                            color: '#FFF',
                            border: 'none',
                            fontFamily: 'Roboto',
                            fontSize: '1rem',
                            fontWeight: 500,
                            cursor: 'pointer',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            padding: '0 1rem',
                            gap: '0.5rem',
                          }}>
                          <PlusIcon style={{ width: '1.2rem', height: '1.2rem' }} />
                          Add Candidates
                        </button>
                      </div>
                    </Paper>
                  </div>
                  <SourcingStrategy
                    previous={() => {
                      setActiveStep(activeStep - 1);
                    }}
                    sourcingDetails={
                      Object.keys(formValue.sourcingDetails).length != 0
                        ? formValue.sourcingDetails
                        : sjd
                    }
                    save={(values) => {
                      appendFieldValues(values, 'sourcingDetails');
                      setActiveStep(activeStep + 1);
                      return new Promise((resolve, _reject) => resolve(true));
                    }}
                  />
                </>
              )}
              {activeStep == 3 && (
                <HiringTeam
                  previous={() => setActiveStep(activeStep - 1)}
                  hiringDetails={
                    Object.keys(formValue.hiringTeam).length != 0 ? formValue.hiringTeam : sjd
                  }
                  save={(values) => {
                    appendFieldValues(values, 'hiringTeam');
                    setActiveStep(activeStep + 1);
                    return new Promise((resolve, _reject) => resolve(true));
                  }}
                />
              )}
              {activeStep == 4 && (
                <InterViewProcess
                  interviewProcessDetails={
                    Object.keys(formValue.interviewProcessDetails).length != 0
                      ? formValue.interviewProcessDetails
                      : sjd
                  }
                  previous={() => setActiveStep(activeStep - 1)}
                  save={(values) => {
                    saveSjd(values, 'interviewProcessDetails');
                    return new Promise((resolve, _reject) => resolve(true));
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <Notify
        open={showToast}
        severity={toastMsg.severity}
        onClose={() => setShowToast(false)}
        message={toastMsg.msg}></Notify>
      {loading && (
        <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}>
          <CircularProgress color='inherit' />
        </Backdrop>
      )}
    </>
  );
};

export default TadSourceJobDefinitionEdit;
