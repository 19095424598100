import { Box, Button, InputLabel, Paper, TextField } from '@mui/material';
import React, { useState } from 'react';
import Rating from '@mui/material/Rating';
import StarIcon from '@mui/icons-material/Star';
import { ReactComponent as CloseIcon } from '../../assets/sjd/close.svg';
import { ReactComponent as AddIcon } from '../../assets/sjd/add.svg';
import { ReactComponent as ThumbsDownIcon } from '../../assets/candidate-assets/thumb_down_alt.svg';
import { ReactComponent as ThumbsUpIcon } from '../../assets/candidate-assets/thumb_up_alt.svg';
import { ReactComponent as WatchIcon } from '../../assets/candidate-assets/watch.svg';
import { ReactComponent as WineBarIcon } from '../../assets/candidate-assets/wine_bar.svg';
import { ReactComponent as FileIcon } from '../../assets/candidate-assets/insert_drive_file.svg';
import { ReactComponent as DeleteOutlineIcon } from '../../assets/candidate-assets/delete_outline.svg';
import { ReactComponent as DownloadOutlineIcon } from '../../assets/candidate-assets/download copy.svg';
import DoneIcon from '@mui/icons-material/Done';
import { handleCandidateInitials } from '../../common/commonUtil';
// import { useFormik } from 'formik';

const TadInterviewFeedback = () => {
  const options = [
    {
      label: 'Shortlisted',
      icon: ThumbsUpIcon,
      comment: 'I would recommend moving the candidate forward.',
    },
    {
      label: 'Selected',
      icon: WineBarIcon,
      comment: 'The candidate has been chosen for the job.',
    },
    {
      label: 'Rejected',
      icon: ThumbsDownIcon,
      comment: 'Not suitable for this job description or lacks the required technical skills.',
    },
    {
      label: 'On Hold',
      icon: WatchIcon,
      comment: 'The candidate is neither shortlisted nor rejected.',
    },
  ];
  const assessedSkills = [
    { id: 0, skill: 'Confidence' },
    { id: 1, skill: 'Problem Solving' },
  ];

  const recruiter = [{ id: '0', name: 'Alena Johson' }];
  const [skills, setSkills] = useState(assessedSkills);
  // const [feedbackRow, setFeedbackRow] = useState({});

  const handleClose = (id: number) => {
    const updatedSkills = skills.filter((skill) => skill.id != id);
    setSkills(updatedSkills);
  };

  const addNewRow = () => {
    setSkills((skills) => [...skills, { id: skills.length, skill: '' }]);
  };
  const handleSkillChange = (id: number, skill: string) => {
    const temp = skills;
    temp[id].skill = skill || '';
    setSkills(() => [...temp]);
  };

  // const formik = useFormik({
  //   initialValues: {
  //     attachment: undefined as unknown as File,
  //   },
  //   onSubmit: (values) => {
  //     upload(values);
  //     formik.resetForm();
  //     formik.setFieldValue('attachement', undefined);
  //   },
  //   validate: (values) => {
  //     const errors: { documentName?: string; attachment?: string } = {};
  //     if (!values.documentName || values.documentName == '')
  //       errors['documentName'] = 'Document name is required';
  //     else if (values.documentName.length > 200)
  //       errors['documentName'] = 'Document Name should not exceed 200 characters';
  //     if (!values.attachment) errors['attachment'] = 'Please attach a document';
  //     return errors;
  //   },
  // });

  const [selectedOption, setSelectedOption] = useState<null | string>(null);
  return (
    <Paper style={{ paddingBottom: '30px' }}>
      <div className='header-container'>
        <div className='header-content-title'>
          <span>Interview Feedback</span>
        </div>
      </div>
      <div style={{ paddingLeft: '1.63rem', paddingRight: '1.37rem', marginTop: '20px' }}>
        <div>
          <span className='header-label'>
            How would you rate the candidate performance overall after the interview?
          </span>
        </div>
        <div>
          <Rating
            className='rating'
            name='half-rating'
            defaultValue={2.5}
            precision={0.5}
            icon={<StarIcon className='filled-star' fontSize='inherit' />}
            emptyIcon={<StarIcon className='empty-star' fontSize='inherit' />}
            style={{ gap: '1rem', marginTop: '10px' }}
          />
        </div>
        <div style={{ marginTop: '10px' }}>
          <span className='header-label'>Assessed Skills</span>
          {skills.map((skill, index) => (
            <Box key={index} className='box-bg'>
              <TextField
                variant='outlined'
                size='small'
                value={skill.skill}
                onChange={(e) => {
                  handleSkillChange(skill.id, e.target.value);
                }}
              />
              <Rating
                className='rating'
                name='half-rating'
                defaultValue={2.5}
                precision={0.5}
                icon={<StarIcon className='filled-star' fontSize='inherit' />}
                emptyIcon={<StarIcon className='empty-star' fontSize='inherit' />}
                style={{ gap: '1rem', marginLeft: '20px' }}
              />
              <CloseIcon
                style={{ marginLeft: '350px' }}
                onClick={() => handleClose(skill.id)} // Pass skill.id here
              />
            </Box>
          ))}
        </div>

        <div
          style={{ marginTop: '20px', display: 'flex', alignItems: 'center' }}
          onClick={addNewRow}>
          <AddIcon className='add-icon path' />
          <span style={{ color: '#1976D2', textDecoration: 'underline' }}>Add</span>
        </div>
        <TextField
          fullWidth
          placeholder='Additional feedback if any'
          multiline
          minRows={3}
          maxRows={6}
          sx={{ marginTop: '40px' }}
        />

        <div style={{ marginTop: '40px' }}>
          {/* <span className='header-label'>Upload a screenshot</span> */}
          {/* <div
            style={{
              display: 'flex',
              height: '3.75rem',
              borderRadius: '0.3125rem',
              border: '1px solid var(--Secondary-colors-Grey, #868686)',
              background: 'var(--Secondary-colors-White, #FFF)',
              alignItems: 'center',
              marginTop: '20px',
            }}>
            <div
              style={{
                marginLeft: '0.94rem',
                marginRight: '1.31rem',
                textAlign: 'center',
                width: '7.375rem',
                height: 'var(--Font-size-h2, 2.5rem)',
                borderRadius: '0.3125rem',
                border: '1px solid #2D7DFE',
                background: 'var(--Secondary-colors-White, #FFF)',
                alignItems: 'center',
                justifyContent: 'center',
              }}>
              <span>Choose File</span>
            </div>
            <span>No file chosen</span>
          </div> */}

          <InputLabel
            htmlFor='browse-document'
            style={{
              color: 'var(--Primary-Dark-grey-500, #333)',
              fontFamily: 'Roboto',
              fontSize: '1rem',
              fontStyle: 'normal',
              fontWeight: '600',
              lineHeight: '1.5625rem',
            }}>
            Upload a screenshot
          </InputLabel>
          <TextField
            id='browse-document'
            type='file'
            name='attachment'
            size='small'
            fullWidth
            style={{ marginTop: '20px' }}
            InputProps={{
              style: {
                height: '60px',
              },
            }}
            // onChange={(event: any) => formik.setFieldValue('attachment', event.target.files[0])}
          />
        </div>

        <div style={{ marginTop: '40px' }}>
          <span className='header-label'>Result</span>
          {options.map((option, index) => (
            <div
              key={index}
              style={{
                height: '4.125rem',
                display: 'flex',
                marginTop: '30px',
                alignItems: 'center',
                paddingLeft: '10px',
                justifyContent: 'space-between ',
                background: option.label === selectedOption ? '#F1F4F8' : '',
              }}
              onClick={() => setSelectedOption(option.label)}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <option.icon style={{ color: '#1976D2' }} />
                <div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      paddingLeft: '20px',
                    }}>
                    <span className='semi-bold'>{option.label}</span>
                    <span>{option.comment}</span>
                  </div>
                </div>
              </div>
              <div style={{ marginRight: '30px' }}>
                {option.label === selectedOption && <DoneIcon style={{ fill: '#2D7DFE' }} />}
              </div>
            </div>
          ))}
        </div>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            style={{
              background: '#1976D2',
              color: '#ffffff',
              marginTop: '20px',
              width: '7rem',
              height: 'var(--Font-size-h2, 2.5rem)',
            }}>
            Submit
          </Button>
        </div>
        <hr style={{ background: '#868686', marginTop: '20px' }} />
        <div style={{ marginTop: '40px', display: 'flex', alignItems: 'center' }}>
          <div
            style={{
              backgroundColor: '#1976D2',
              color: 'white',
              padding: '0.5rem',
              borderRadius: '50%',
              width: '1.875rem',
              height: '1.875rem',
              fontSize: '1rem',
              fontWeight: '400',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-around',
            }}>
            {handleCandidateInitials(recruiter[0].name)}
          </div>

          <div
            style={{
              marginLeft: '40px',
              color: '#1976D2',
              textDecoration: 'underline',
              fontWeight: '400',
            }}>
            {recruiter[0].name}
          </div>

          <div>
            <Rating
              className='rating'
              name='half-rating'
              defaultValue={2.5}
              precision={0.5}
              icon={<StarIcon className='filled-star' fontSize='inherit' />}
              emptyIcon={<StarIcon className='empty-star' fontSize='inherit' />}
              style={{ gap: '1rem', marginLeft: '20px' }}
            />
            <span>3/5</span>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            height: '4.875rem',
            borderRadius: '0.3125rem',
            background: 'var(--Secondary-colors-Grey-3, #F1F4F8)',
            alignItems: 'center',
            marginTop: '20px',
          }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginRight: '100px',
              marginLeft: '20px',
            }}>
            <span className='semi-bold'>Interview Date</span>
            <span>07/08/2024</span>
          </div>

          <div style={{ display: 'flex', flexDirection: 'column', marginRight: '100px' }}>
            <span className='semi-bold'>Interview Level</span>
            <span>HR Interview</span>
          </div>

          <div style={{ display: 'flex', flexDirection: 'column', marginRight: '100px' }}>
            <span className='semi-bold'>Result</span>
            <span>Shortlisted</span>
          </div>
        </div>
        <div style={{ marginTop: '30px', display: 'flex', flexDirection: 'column' }}>
          <span className='semi-bold'>Comments</span>
          <span style={{ height: '6.25rem' }}>
            Joshi has a strong foundation in Java and Python with excellent problem-solving and
            communication skills. She needs to improve her knowledge of advanced data structures and
            gain more experience with our tech stack; recommend proceeding to the next interview
            stage.
          </span>
          <span className='semi-bold'>Interview recording link</span>
          <span style={{ color: '#1976D2', textDecoration: 'underline' }}>
            https://peopletechgroupinc-my.sharepoint.com/personal/gopalkrishna_holla_people
            tech_com/Documents/Recordings/Data%20Governance%20Meeting-20240724_070633-Meeting%
            20Recording.mp4?web=1&referrer=Teams.TEAMS-ELECTRON&referrerScenario=RecapOpenInStr
            eamButton.view.efba8020-cd2a-4feb-b5cb-8d3e55a3173d
          </span>
        </div>
        <div style={{ marginTop: '30px' }}>
          <span className='semi-bold'>Attachments</span>
          <div
            style={{
              display: 'flex',
              background: 'var(--Secondary-colors-Grey-3, #F1F4F8)',
              height: ' 3.875rem',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}>
            <div style={{ display: 'flex', marginLeft: '0.5rem' }}>
              <FileIcon />
              <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '1rem' }}>
                <span>Feedback by Richard M.PNG</span>
                <span style={{ color: 'var(--Secondary-colors-Grey, #868686)' }}>02/09/2024</span>
              </div>
            </div>
            <div style={{ display: 'flex', marginRight: '0.5rem' }}>
              <DownloadOutlineIcon style={{ marginRight: '3rem' }} />
              <DeleteOutlineIcon />
            </div>
          </div>
        </div>
      </div>
    </Paper>
  );
};

export default TadInterviewFeedback;
