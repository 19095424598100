import React, { useState } from 'react';
import {
  Grid,
  // IconButton,
  Button,
  Paper,
  Typography,
  IconButton,
  // List,
  // ListItem,
  // ListItemIcon,
  // ListItemText,
  // Radio,
} from '@mui/material';
// import tadLoader from '../../../assets/common-assets/Whole animation.gif';
// import WarningIcon from '@mui/icons-material/Warning';
// import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
// import DownloadIcon from '@mui/icons-material/Download';
// import DeleteIcon from '@mui/icons-material/Delete';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import useNavigate from '../../../../common/useNavigate';
import { scrollToTop } from '../../../common/commonUtil';
import './addcandidate.scss';
import { ReactComponent as CloudUploadIcon } from '../../../assets/candidate-assets/cloud_uploadBlack.svg';

interface Props {
  handleResumeUpload: (file: File) => void;
  mode?: 'edit-page' | 'details-page';
}

const UploadResume = ({ handleResumeUpload }: Props) => {
  // const [selectedResumeId, setSelectedResumeId] = useState<number | null>(null);
  const [navigate] = useNavigate();
  const [submitted, setSubmitted] = useState(false);

  // const resumes = [
  //   {
  //     id: 1,
  //     name: 'Ramana_Joshi_Resume2.pdf',
  //     size: '200 KB',
  //     status: 'Already Exists',
  //     lastUploaded: 'Uploaded now',
  //   },
  //   {
  //     id: 2,
  //     name: 'Ramana_Joshi_Resume1.pdf',
  //     size: '200 KB',
  //     status: 'Already Exists',
  //     lastUploaded: 'Last uploaded on 21/08/2024',
  //   },
  //   {
  //     id: 3,
  //     name: 'Sunil Gupta_LeadUXDesigner.pdf',
  //     size: '200 KB',
  //     status: 'Uploaded now',
  //   },
  //   {
  //     id: 4,
  //     name: 'Chandini Garg_Fresher.docx',
  //     size: '240 KB',
  //     status: 'Uploaded now',
  //   },
  //   {
  //     id: 5,
  //     name: 'Akhil_Arjun_Resume.pdf',
  //     size: '345 KB',
  //     status: 'Uploading',
  //     uploadProgress: 60,
  //   },
  // ];

  const formik = useFormik({
    initialValues: {
      resume: null,
    },
    validationSchema: Yup.object({
      resume: Yup.mixed().required('A resume file is required'),
    }),
    onSubmit: (values) => {
      console.log('Form values:', values);
    },
  });

  // const handleDownload = (resumeName: string) => {
  //   alert(`Downloading: ${resumeName}`);
  // };

  // const handleDelete = (resumeId: number) => {
  //   alert(`Deleting resume with ID: ${resumeId}`);
  // };

  return (
    <div style={{ height: '100%', width: '100%' }}>
      <Paper className='paper' sx={{ width: '100%' }}>
        <div style={{ margin: '3.81rem 2rem 0rem 1.94rem' }}>
          <div style={{ marginBottom: '1rem' }}>
            <span className='text'>
              The system will try to auto-fill information from the candidates resume. Please
              carefully review the information for accuracy after this initial parsing. Then, follow
              the next steps to complete the entire process.
            </span>
          </div>

          <form onSubmit={formik.handleSubmit}>
            <div className='upload' style={{ marginBottom: '1rem' }}>
              <Grid
                container
                display='flex'
                flexDirection='column'
                alignItems='center'
                justifyContent='center'
                gap={2}>
                <IconButton component='label' className='file-field' sx={{ fontSize: '3rem' }}>
                  <CloudUploadIcon fontSize='inherit' />
                  <input
                    type='file'
                    accept='.pdf, .docx, .jpg, .png'
                    multiple
                    style={{ display: 'none' }}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      const files = e.target.files;
                      if (files) {
                        Array.from(files).forEach((file) => {
                          handleResumeUpload(file);
                          formik.setFieldValue('resumes', (prevResumes: File[]) => [
                            ...prevResumes,
                            file,
                          ]);
                        });
                      }
                    }}
                  />
                </IconButton>

                {formik.errors.resume && submitted && (
                  <Typography variant='body2' color='red'>
                    {formik.errors.resume}
                  </Typography>
                )}

                <Typography variant='body2' style={{ fontWeight: 'bold', textAlign: 'center' }}>
                  Drop candidate resume here
                </Typography>

                <Typography variant='body2' style={{ fontWeight: 'bold', textAlign: 'center' }}>
                  Or{' '}
                  <span
                    style={{ color: '#1976D2', cursor: 'pointer' }}
                    onClick={() => alert('select multiple resumes')}>
                    Select multiple resumes
                  </span>{' '}
                  (.doc, .docx, .pdf, .jpg, or .png)
                </Typography>
              </Grid>
            </div>

            <div style={{ padding: '1rem' }}>
              <Typography variant='h6' style={{ fontWeight: 'bold' }}>
                Uploaded Resumes
              </Typography>
              {/* <List>
                {resumes.map((resume) => (
                  <ListItem key={resume.id} divider>
                    <ListItemIcon>
                      <InsertDriveFileIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            width: '100%',
                          }}>
                          <Typography variant='body1' style={{ fontWeight: 500 }}>
                            {resume.name}
                          </Typography>
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'flex-start',
                              gap: '3rem',
                              marginLeft: 'auto',
                              marginTop: '1rem',
                              marginRight: '36rem',
                            }}>
                            {resume.status === 'Already Exists' && (
                              <div style={{ display: 'flex' }}>
                                <WarningIcon style={{ color: 'red' }} />
                                <Typography variant='body2' style={{ color: 'red' }}>
                                  {resume.status}
                                </Typography>
                              </div>
                            )}
                            {resume.status === 'Uploaded now' && (
                              <Typography variant='body2'>{resume.lastUploaded}</Typography>
                            )}
                            {resume.status === 'Uploading' ? (
                              <div style={{ display: 'flex', alignItems: 'center' }}>
                                <img
                                  src={tadLoader}
                                  alt='Loading'
                                  style={{
                                    width: '3rem',
                                    height: '2.0625rem',
                                    marginRight: '0.5rem',
                                  }}
                                />
                                <Typography variant='body2' style={{ color: 'green' }}>
                                  {resume.uploadProgress}%
                                </Typography>
                              </div>
                            ) : (
                              resume.lastUploaded && (
                                <Typography variant='body2' style={{ color: '#888' }}>
                                  {resume.lastUploaded}
                                </Typography>
                              )
                            )}
                          </div>
                        </div>
                      }
                      secondary={<Typography variant='body2'>{resume.size}</Typography>}
                    />
                    <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                      <Radio
                        checked={selectedResumeId === resume.id}
                        onChange={() => setSelectedResumeId(resume.id)}
                        value={resume.id}
                        sx={{
                          width: '1.5rem',
                          height: '1.5rem',
                          flexShrink: 0,
                          '& .MuiSvgIcon-root': {
                            fontSize: '1.5rem',
                          },
                        }}
                      />
                      <IconButton onClick={() => handleDownload(resume.name)}>
                        <DownloadIcon />
                      </IconButton>
                      <IconButton onClick={() => handleDelete(resume.id)}>
                        <DeleteIcon />
                      </IconButton>
                    </div>
                  </ListItem>
                ))}
              </List> */}
            </div>

            <div style={{ marginTop: '0.9rem' }}>
              <div style={{ padding: '1.87rem 1.88rem', borderTop: '1px solid #868686' }}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}>
                  <Button
                    className='button'
                    onClick={() => {
                      navigate([
                        {
                          label: 'Candidate',
                          link: '/candidate',
                        },
                      ]);
                      scrollToTop();
                    }}
                    sx={{
                      width: '7.375rem',
                      height: '2.5rem',
                      borderRadius: '5px',
                      border: '1px solid #2D7DFE',
                      backgroundColor: '#FFF',
                      color: 'black',
                      textTransform: 'none',
                      fontSize: '1rem',
                      '&:hover': {
                        backgroundColor: '#2D7DFE',
                        color: 'white',
                      },
                    }}>
                    Cancel
                  </Button>
                  <Button
                    className='button'
                    type='submit'
                    onClick={() => setSubmitted(true)}
                    sx={{
                      textTransform: 'none',
                      fontWeight: 400,
                      fontSize: '1rem',
                      width: '7.375rem',
                      height: '2.5rem',
                      color: 'white !important',
                      background: '#2D7DFE !important',
                    }}>
                    Next
                  </Button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </Paper>
    </div>
  );
};

export default UploadResume;
