export enum LookupTypes {
  QUALIFICATION = 'qualification',
  PRIORITY = 'priority',
  PAY_TYPE = 'payType',
  JOB_TYPE = 'jobType',
  DOMAIN = 'domain',
  COMPANY = 'company',
  DEPARTMENT = 'department',
  OPERATION = 'operation',
  ROLE = 'role',
  SOURCE_CHANNEL = 'sourceChannel',
  CANDIDATE_STATUS = 'candidateStatus',
  SJD_STATUS = 'sjdStatus',
  CANDIDATE_EVENT_TYPE = 'candidateEventType',
  SJD_EVENT_TYPE = 'sjdEventType',
  CLIENT = 'client',
  ANSWER_TYPE = 'answerType',
  QUESTIONNAIRE_TYPE = 'questionnaireType',
  HIRING_MANAGER = 'hiringManager',
  PROVIDER = 'provider',
  TECH_STACK = 'techstack',
  LEVEL = 'level',
  PERMISSION_TYPE = 'permissionType',
  MODULE = 'module',
  SUBMODULE = 'subModule',

  // new -tad lookups
  TAD_QUALIFICATION = 'tadQualification',
  TAD_PRIORITY = 'tadPriority',
  TAD_PAY_TYPE = 'tadPayType',
  TAD_JOB_TYPE = 'tadJobType',
  TAD_DOMAIN = 'tadDomain',
  TAD_HIRING_MANAGER = 'tadHiringManager',
  //  TAD_DEPARTMENT = ("tadDepartment"),
  TAD_OPERATION = 'tadOperation',
  //  TAD_ROLE = ("tadRole"),
  TAD_SOURCE_CHANNEL = 'tadSourceChannel',
  //  TAD_CANDIDATE_STATUS = ("tadCandidateStatus"),
  TAD_SJD_STATUS = 'tadSjdStatus',
  //  TAD_CANDIDATE_EVENT_TYPE = ("tadCandidateEventType"),
  TAD_SJD_EVENT_TYPE = 'tadSjdEventType',
  TAD_CLIENT = 'tadClient',
  TAD_CREATE_QUESTIONNAIRE_TYPE = 'tadCreateQuestionnaireType',
  TAD_QUESTIONNAIRE_LEVEL = 'tadQuestionnaireLevel',
  TAD_QUESTIONS_TYPE = 'tadQuestionsType',
  TAD_ANSWER_TYPE = 'tadAnswersType',
  //  TAD_PROVIDER("tadProvider"),
  //  TAD_TECH_STACK("tadTechstack"),
  //  TAD_LEVEL("tadLevel"),
  //  TAD_PERMISSION_TYPE("tadPermissionType"),
  //  TAD_MODULE("tadModule"),
  //  TAD_SUBMODULE("tadSubModule"),
  TAD_WORKING_MODEL = 'tadWorkingModel',
  TAD_EXPERIENCE_TYPE = 'tadExperienceType',
  TAD_VISA_TYPE = 'tadVisaType',
  TAD_TARGET_COMPANIES = 'tadTargetCompanies',
  TAD_TYPE_OF_STUDY = 'tadTypeOfStudy',
  TAD_CANDIDATE_DEGREE_NAMES = 'tadCandidateDegreeNames',
  TAD_CANDIDATE_DEGREE_SPECIALIZATIONS = 'tadDegreeSpecializations',
  TAD_CANDIDATE_CERTIFICATION_SPECIALIZATIONS = 'tadCandidateCertificationSpecializations',
  TAD_GENDER = 'tadGender',
  TAD_REASONS = 'tadReasons',
  TAD_ROLE = 'tadRole',
  TAD_PROJECT_BILLING_TYPE = 'tadProjectBillingType',
  TAD_INTERVIEW_PROCESS_TYPE = 'tadInterviewProcessType',
  TAD_INTERVIEW_MAIN_STAGE='tadInterviewMainStage',
  TAD_INTERVIEW_MAIN_SUBSTAGE='tadInterviewMainSubStage',
  TAD_CANDIDATE_STATUS='tadCandidateStatus'
}
