import React, { useState, useRef } from 'react';
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Button,
} from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import DeleteIcon from '@mui/icons-material/Delete';
import { ReactComponent as MoreVertIcon } from '../../../assets/dashboard-assets/more_vert.svg';

const CandidateJobDefinition = () => {
  const [documentName, setDocumentName] = useState<string>('');
  const [file, setFile] = useState<File | null>(null);
  const [data, setData] = useState<any[]>([]);
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setFile(event.target.files[0]);
    }
  };

  const handleUpload = () => {
    if (documentName && file) {
      const fileURL = URL.createObjectURL(file);
      const newAttachment = {
        documentName: documentName,
        fileURL: fileURL,
        uploadedBy: 'Your Name',
        uploadedOn: new Date().toLocaleString(),
      };

      setTimeout(() => {
        setData([...data, newAttachment]);
        setDocumentName('');
        setFile(null);

        if (fileInputRef.current) {
          fileInputRef.current.value = '';
        }
        alert('Resume uploaded successfully!');
      }, 1000);
    } else {
      alert('Please fill in the document name and choose a file.');
    }
  };

  const handleDownload = (fileURL: string, fileName: string) => {
    const a = document.createElement('a');
    a.href = fileURL;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const handleDelete = (index: number) => {
    const updatedData = data.filter((_, i) => i !== index);

    URL.revokeObjectURL(data[index].fileURL);

    setData(updatedData);
  };

  return (
    <>
      <Paper style={{ height: '33.6875rem', width: '100%' }}>
        <div
          style={{
            height: '61px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            backgroundColor: 'var(--Secondary-Grey-2, #E7E7E7)',
          }}>
          <div style={{ display: 'flex' }}>
            <span
              className='heading'
              style={{ height: '24px', fontSize: '18px', marginLeft: '1.81rem' }}>
              Attachments({data.length})
            </span>
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <MoreVertIcon style={{ marginRight: '10px' }} />
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            marginTop: '20px',
            marginLeft: '26.61px',
          }}>
          <Typography className='input-field'>Document Name</Typography>

          <TextField
            variant='outlined'
            fullWidth
            placeholder='Ex: Cover Letter, Portfolio Document etc.'
            value={documentName}
            onChange={(e) => setDocumentName(e.target.value)}
            sx={{ width: '95%' }}
          />
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            marginTop: '20px',
            marginLeft: '26.61px',
          }}>
          <Typography
            style={{
              color: 'var(--Primary-Dark-grey-500, #333)',
              fontFamily: 'Roboto',
              fontSize: '1rem',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: '1.5625rem',
              marginBottom: '8px',
            }}>
            Browse Document
          </Typography>

          <input
            ref={fileInputRef} // Bind file input to the ref
            type='file'
            onChange={handleFileChange}
            style={{
              width: '95%',
              height: '3.75rem',
              flexShrink: 0,
              borderRadius: '0.3125rem',
              border: '1px solid var(--Secondary-Grey-2, #E7E7E7)',
              background: 'var(--Secondary-colors-White, #FFF)',
              padding: '0.5rem',
            }}
          />

          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              marginRight: '2rem',
              marginTop: '1rem',
            }}>
            <Button
              variant='contained'
              onClick={handleUpload}
              sx={{
                width: '7rem',
                height: '2.5rem',
                flexShrink: 0,
                borderRadius: '0.3125rem',
                background: 'var(--Secondary-colors-Grey, #868686)',
              }}>
              Upload
            </Button>
          </div>
        </div>

        {data.length > 0 ? (
          <div className='qc-table' style={{ marginLeft: '2.02rem', marginRight: '1.25rem' }}>
            <Table>
              <TableHead style={{ borderBottom: '0.125rem solid #C1C0C0' }}>
                <TableRow>
                  <TableCell>Document Name</TableCell>
                  <TableCell>Uploaded By</TableCell>
                  <TableCell>Uploaded On</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody style={{}}>
                {data.map((item, index) => (
                  <TableRow
                    key={index}
                    style={{
                      width: '43.875rem',
                      height: '4.875rem',
                      padding: 0,
                      backgroundColor: 'var(--Secondary-colors-Grey-3, #F1F4F8)',
                      opacity: 0.2,
                    }}>
                    <TableCell>
                      <span
                        style={{
                          textDecoration: 'underline',
                          fontSize: '1rem',
                          fontWeight: 600,
                          color: '#1976D2',
                        }}>
                        {item.documentName}
                      </span>
                    </TableCell>
                    <TableCell
                      style={{
                        fontSize: '1rem',
                        fontWeight: 600,
                        textDecoration: 'underline',
                        color: '#1976D2',
                      }}>
                      {item.uploadedBy}
                    </TableCell>
                    <TableCell>{item.uploadedOn}</TableCell>
                    <TableCell
                      style={{
                        padding: 0,
                        marginTop: '1.69rem',
                        marginBottom: '1.31rem',
                        marginRight: '0.78rem',
                      }}>
                      <DownloadIcon
                        sx={{
                          cursor: 'pointer',
                          width: '1.5rem',
                          height: '1.5rem',
                          flexShrink: 0,
                          marginRight: '1rem',
                          color: '#323232',
                        }}
                        onClick={() => handleDownload(item.fileURL, item.documentName)}
                      />
                      <DeleteIcon
                        sx={{
                          width: '1.5rem',
                          height: '1.5rem',
                          flexShrink: 0,
                          cursor: 'pointer',
                          color: '#323232',
                        }}
                        onClick={() => handleDelete(index)}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        ) : (
          <Typography
            variant='body1'
            style={{
              textAlign: 'center',
              marginTop: '1rem',
              color: '#888',
            }}>
            No resumes uploaded yet.
          </Typography>
        )}
      </Paper>
    </>
  );
};

export default CandidateJobDefinition;
