import Questionnaires from './questionnaire/tad-questionnaire';
// import PageHeader from '../../../components/common/page-header/page-header';
import {
  Backdrop,
  Chip,
  CircularProgress,
  Drawer,
  IconButton,
  Link,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from '@mui/material';
import { ReactComponent as LocationIcon } from '../../assets/sjd/location_on.svg';
import { ReactComponent as JobIcon } from '../../assets/sjd/work_outline.svg';
// import { ReactComponent as HiringIcon } from '../../assets/sjd/assignment_ind.svg';
import { ReactComponent as CandidatesIcon } from '../../assets/sjd/multiple.svg';
import { ReactComponent as AddIcon } from '../../assets/sjd/add (1).svg';
import { ReactComponent as DotsIcon } from '../../assets/dashboard-assets/more_vert.svg';
// import AddHiringTeam from './../../assets/sjd/Vector.svg';
import Edit from '../../assets/sjd/Edit.svg';
// import Share from './../../assets/sjd/Share.svg';
// import Export from '../../assets/sjd/Export.svg';
// import Duplicate from '../../assets/sjd/Duplicate.svg';
import PutOnHold from '../../assets/sjd/Put on Hold.svg';
// import Publish from '../../assets/sjd/Publish.svg';
// import Archive from '../../assets/sjd/Archive.svg';
import { getIdFromName, getNameFromId } from '../../../common/commonUtil';
import { LookupTypes } from '../../../common/lookupTypes';
import dateFormat from 'dateformat';
import globalObject from '../../../common/global-variables';
import JobBoards from './job-boards';
import JobDefinitionDetails from './job-definition-details';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getTadSjd, tadUpdateSjd } from '../../service/tadSjdService';
import HiringTeam from './hiring-team';
import SourcingStrategy from './sourcing-strategy';
import { parseLocation } from '../../common/commonUtil';
import CandidateActivityTracking from './candidate-activity-tracking';
import SjdCandidateTable from './sjd-candidate-table/sjd-candidate-table';
import { SJD_STATUS } from '../../../constants/common';
import AddCandidateDrawer from '../candidate/add-candidate-drawer';
import TadPageHeader from '../common/page-header/tad-page-header';
import KeyboardArrowUpSharpIcon from '@mui/icons-material/KeyboardArrowUpSharp';
import useNavigate from '../../../common/useNavigate';
import ContactCard from '../common/contact-card';

const TadJobDefinitionDetailsPage = () => {
  let { sjdId } = useParams();
  if (sjdId) sjdId = atob(sjdId);
  const [sjd, setSjd] = useState({} as any);
  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [contactAnchor, setContactAnchor] = useState<null | HTMLElement>(null);
  const [statusAnchorEl, setStatusAnchorEl] = useState<null | HTMLElement>(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const [contact, setContact] = useState({} as any);
  const reference: any = useRef<HTMLDivElement>(null);
  const [navigate] = useNavigate();

  const headers = [
    { id: 1, label: 'Candidates' },
    { id: 2, label: 'Job Details' },
    { id: 3, label: 'Hiring Team' },
    { id: 4, label: 'Sourcing Strategy' },
    // { id: 1, label: 'Recommendations' },
    { id: 5, label: 'Questionnaire' },
    // { id: 1, label: 'Interview Process' },
    { id: 6, label: 'Job Boards' },
    { id: 7, label: 'History' },
  ];

  const references: any[] = [];
  headers.forEach(() => references.push(useRef<HTMLDivElement>(null)));

  const priorityColorMapping: { [key: string]: string } = {
    Low: '#ACDDA9',
    Medium: '#FDDA95',
    High: '#EFA3B2',
  };

  const priorityOptions = globalObject.lookupvalues[LookupTypes.TAD_PRIORITY]?.map(
    (status: any) => ({
      ...status,
      color: priorityColorMapping[status.name],
    }),
  );

  const statusColorMapping: { [key: string]: string } = {
    'In Progress': '#ADD8E6',
    'On Hold': '#FDDA95',
    Completed: '#C7E8C5',
    Archived: '#D3D3D3',
    Cancelled: '#FFC0CB',
  };

  const statusOptions = globalObject.lookupvalues[LookupTypes.TAD_SJD_STATUS]?.map(
    (status: any) => ({
      ...status,
      color: statusColorMapping[status.name],
    }),
  );

  const actionsList = [
    // {
    //   name: 'Add Hiring Team',
    //   icon: AddHiringTeam,
    // },
    { name: 'Edit', icon: Edit },
    // { name: 'Share', icon: Share },
    // { name: 'Export', icon: Export },
    // { name: 'Duplicate', icon: Duplicate },
    { name: 'Put On Hold', icon: PutOnHold },
    // { name: 'Publish', icon: Publish },
    // { name: 'Archive', icon: Archive },
  ];

  const sjdActionPatch = (sjd: any, action: string) => {
    setLoading(true);
    const patchObj: any = {};
    patchObj.id = sjd.id;
    if (action === 'Remove') patchObj.active = false;
    else if (action === 'Publish') patchObj.publish = true;
    else if (action === 'Unpublish') patchObj.publish = false;
    else patchObj.sjdStatusId = getIdFromName(action, LookupTypes.TAD_SJD_STATUS);
    setLoading(true);
    tadUpdateSjd(patchObj).then((_resp) => {
      getTadSjd(sjdId)
        .then((res) => {
          setLoading(false);
          setSjd(res.data);
        })
        .catch((e) => console.log(e));
    });
  };

  // const handleTabs = (value: any) => {
  //   setSelectedTab(value);
  // };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    const elementPos = references[newValue].current.getBoundingClientRect().top;
    const tabsPos = document.getElementById('page-tabs')?.getBoundingClientRect().top;
    const offsetPos = elementPos + window.pageYOffset - (!tabsPos ? 0 : tabsPos + 5);
    window.scrollTo({
      top: offsetPos,
      behavior: 'smooth',
    });
    setSelectedTab(newValue);
  };

  useEffect(() => {
    setLoading(true);
    getTadSjd(sjdId)
      .then((res) => {
        setLoading(false);
        setSjd(res.data);
      })
      .catch((e) => console.log(e));
  }, []);
  const sourcingChannels = sjd.sourcingChannels?.map((channel: any) => channel.name) || [];

  return (
    <div className='main-div'>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ marginBottom: '1.19rem' }}>
          <TadPageHeader
            title={`${sjd.name} (${getNameFromId(
              sjd.workingModelId,
              LookupTypes.TAD_WORKING_MODEL,
            )})`}
          />
        </div>
        <div>
          <Paper
            className='paper'
            sx={{
              height: '14.5rem',
              padding: '1.06rem 0rem 0rem 1.19rem',
              display: 'flex',
              justifyContent: 'space-between',
              marginBottom: '1.88rem',
            }}>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
              <div style={{ marginLeft: '0.3rem' }}>
                <div className='overview' style={{ gap: '0.5rem' }}>
                  <Typography sx={{ fontSize: 'var(--Font-size-h5, 1.4375rem)' }}>
                    {`${
                      sjd.name?.charAt(0).toUpperCase() + sjd.name?.slice(1).toLowerCase()
                    } (${getNameFromId(sjd.workingModelId, LookupTypes.TAD_WORKING_MODEL)})`}
                  </Typography>
                  <div
                    style={{ cursor: 'pointer' }}
                    onClick={(e) => setStatusAnchorEl(e.currentTarget)}>
                    <Chip
                      label={getNameFromId(sjd.sjdStatusId, LookupTypes.TAD_SJD_STATUS)}
                      style={{
                        borderRadius: '0.3125rem',
                        backgroundColor: (() => {
                          const selectedOption = statusOptions.find(
                            (status: any) => status.id === sjd.sjdStatusId,
                          );
                          return selectedOption ? selectedOption.color : 'transparent';
                        })(),
                        color: '#333333',
                        fontWeight: '400',
                        textTransform: 'uppercase',
                      }}
                    />
                  </div>
                  <Menu
                    anchorEl={statusAnchorEl}
                    open={Boolean(statusAnchorEl)}
                    onClose={() => setStatusAnchorEl(null)}>
                    {statusOptions.map((item: any) => (
                      <MenuItem
                        key={item.id}
                        value={item.id}
                        onClick={() => {
                          setStatusAnchorEl(null);
                          sjdActionPatch(sjd, item.name);
                        }}>
                        {' '}
                        <span
                          style={{
                            display: 'inline-block',
                            width: '10px',
                            height: '10px',
                            backgroundColor: item.color,
                            marginRight: '8px',
                          }}></span>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Menu>
                  <Chip
                    label={getNameFromId(sjd.priorityId, LookupTypes.TAD_PRIORITY)}
                    style={{
                      borderRadius: '0.3125rem',
                      backgroundColor: (() => {
                        const selectedOption = priorityOptions.find(
                          (status: any) =>
                            status.id === (sjd.priorityId !== undefined && sjd.priorityId),
                        );
                        return selectedOption ? selectedOption.color : 'transparent';
                      })(),
                      color: '#333333',
                      fontWeight: '400',
                      textTransform: 'uppercase',
                    }}
                  />
                </div>
              </div>
              <div className='overview'>
                <LocationIcon style={{ marginRight: '0.5rem' }} />
                <span style={{ fontSize: '1rem' }}>
                  {parseLocation(sjd.jobLocation).join(', ')}
                </span>
              </div>
              <div className='overview'>
                <JobIcon style={{ marginRight: '0.5rem' }} />
                <span style={{ fontSize: '1rem' }}>
                  {sjd.jobTypeId?.map((jobType: any) => jobType.name)}
                </span>
              </div>
              {/* <div className='overview'>
                <div style={{ display: 'flex', marginRight: '0.5rem' }}>
                  <HiringIcon style={{ marginRight: '0.5rem' }} />
                  <Link underline='always' style={{ fontSize: '1rem' }}>{`${
                    sjd.hiringTeam ? sjd.hiringTeam?.length : 0
                  } Members`}</Link>
                </div>
                <Link underline='always' className='overview'>
                  <AddIcon style={{ color: 'blue' }} />
                  <span style={{ fontSize: '1rem' }}>{'Add Hiring Team'}</span>
                </Link>
              </div> */}
              <div className='overview'>
                <div style={{ marginRight: '0.5rem' }}>
                  <CandidatesIcon style={{ marginRight: '0.5rem' }} />
                  <Link style={{ fontSize: '1rem' }}>{`${
                    sjd.totalCandidates ? sjd.totalCandidates : 0
                  } Candidates`}</Link>
                </div>
                <Link underline='always' className='overview'>
                  <AddIcon style={{ fill: 'red' }} />
                  <span style={{ fontSize: '1rem' }} onClick={() => setMenuOpen(true)}>
                    {'Add Candidates'}
                  </span>
                </Link>
              </div>
            </div>
            <div style={{ display: 'flex' }}>
              <div style={{ display: 'flex' }}>
                <div style={{ marginRight: '3rem', marginTop: '2rem' }}>
                  <div style={{ marginRight: '3rem', marginBottom: '3.06rem' }}>
                    <Typography className='feildHeader' sx={{ fontSize: '0.875rem' }}>
                      Client
                    </Typography>
                    <span>{getNameFromId(sjd.clientId, LookupTypes.TAD_CLIENT)}</span>
                  </div>
                  <div style={{ marginRight: '3rem', marginBottom: '3.06rem' }}>
                    <Typography className='feildHeader' sx={{ fontSize: '0.875rem' }}>
                      Operations
                    </Typography>
                    <span>{getNameFromId(sjd.operationId, LookupTypes.TAD_OPERATION)}</span>
                  </div>
                </div>
                <div style={{ marginRight: '3rem', marginTop: '2rem' }}>
                  <div style={{ marginRight: '3rem', marginBottom: '3.06rem' }}>
                    <Typography className='feildHeader' sx={{ fontSize: '0.875rem' }}>
                      Target Date
                    </Typography>
                    <span>{sjd.endDate && dateFormat(new Date(sjd.endDate), 'dd/mm/yyyy')}</span>
                  </div>
                  <div
                    style={{ width: '16.3125rem', marginRight: '3rem', marginBottom: '3.06rem' }}>
                    <Typography className='feildHeader' sx={{ fontSize: '0.875rem' }}>
                      Skills
                    </Typography>
                    <span>{sjd.primarySkills?.map((s: any) => s.skill.name).join(', ')}</span>
                  </div>
                </div>
                <div style={{ marginRight: '3rem', marginTop: '2rem' }}>
                  <div style={{ marginRight: '3rem', marginBottom: '3.06rem' }}>
                    <Typography className='feildHeader' sx={{ fontSize: '0.875rem' }}>
                      Hiring Manager
                    </Typography>

                    {sjd.hiringManagers?.map((s: any) => (
                      <span
                        key={s}
                        style={{
                          cursor: 'pointer',
                          color: '#1976d2',
                          textDecoration: 'underline',
                        }}
                        onClick={(e) => {
                          setContact(s);
                          setContactAnchor(e.currentTarget);
                        }}>
                        {`${s.firstName} ${s.lastName} `}
                      </span>
                    ))}
                  </div>
                  <div style={{ marginRight: '3rem', marginBottom: '3.06rem' }}>
                    <Typography className='feildHeader' sx={{ fontSize: '0.875rem' }}>
                      Sponsorship Required
                    </Typography>
                    <span>{sjd.sponsorshipRequired ? 'Yes' : 'No'}</span>
                  </div>
                </div>
              </div>
              <div>
                <IconButton onClick={(e) => setAnchorEl(e.currentTarget)}>
                  <DotsIcon />
                </IconButton>
                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={() => setAnchorEl(null)}>
                  {actionsList.map((action, index) => (
                    <MenuItem
                      key={index}
                      onClick={() => {
                        setAnchorEl(null);
                        if (action.name === 'Put On Hold') {
                          sjdActionPatch(sjd, SJD_STATUS.ON_HOLD);
                        } else if (action.name === 'Edit') {
                          navigate([
                            {
                              label: 'New Job Definition',
                              link: `/job-definition/${btoa(sjd.id)}`,
                            },
                          ]);
                        }
                      }}>
                      <ListItemIcon>
                        <img
                          src={action.icon}
                          alt={action.name}
                          style={{ width: '20px', height: '20px' }}
                        />
                      </ListItemIcon>
                      <ListItemText primary={action.name} />
                    </MenuItem>
                  ))}
                </Menu>
              </div>
            </div>
          </Paper>
        </div>
        <div style={{ width: '100%' }}>
          <div style={{ marginBottom: '-7px', marginLeft: '-18px' }}>
            <Tabs className='tab' value={selectedTab} onChange={handleChange}>
              {headers.map((header, index) => (
                <Tab
                  className='selected-tab'
                  sx={{
                    color: 'var(--Body-P, var(--Primary-Dark-grey-500, #333))',
                    fontWeight: 400,
                    fontSize: '1rem',
                    textTransform: 'none',
                  }}
                  key={header.id}
                  value={index}
                  label={
                    header.label === 'CANDIDATES'
                      ? `${header.label} (${sjd.totalCandidates ? sjd.totalCandidates : 0})`
                      : header.label === 'Hiring Team'
                      ? `${header.label} (${sjd.hiringTeam ? sjd.hiringTeam?.length : 0})`
                      : header.label
                  }
                />
              ))}
            </Tabs>
          </div>
        </div>
        <div ref={reference}>
          <div ref={references[0]}>
            <SjdCandidateTable sjd={sjdId} />
          </div>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ display: 'flex', gap: '2rem' }}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '2rem',
                  width: '50%',
                }}>
                <div style={{ width: '100%' }} ref={references[1]}>
                  <JobDefinitionDetails sjdDetails={sjd} mode='details-page' />
                </div>
                <div ref={references[5]}>
                  <JobBoards incomingJobBoards={sourcingChannels} />
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '2rem',
                  width: '50%',
                  marginBottom: '2rem',
                }}>
                <div style={{ width: '100%' }} ref={references[2]}>
                  <HiringTeam hiringDetails={sjd} mode='details-page' />
                </div>
                <div style={{ width: '100%' }} ref={references[3]}>
                  <SourcingStrategy sourcingDetails={sjd} mode='details-page' />
                </div>
                <div style={{ width: '100%' }} ref={references[4]}>
                  <Questionnaires jobDescription={sjd} />
                </div>
                <div style={{ flex: 1 }} ref={references[6]}>
                  <CandidateActivityTracking _value={sjd.events} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <ContactCard
          contact={contact}
          anchorEl={contactAnchor}
          onClose={() => {
            setContactAnchor(null);
            setContact(null);
          }}
        />
        {loading && (
          <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}>
            <CircularProgress color='inherit' />
          </Backdrop>
        )}
        <Drawer
          sx={{ width: '21.0625rem', height: '64rem', flexShrink: 0 }}
          anchor='right'
          open={menuOpen}
          onClose={() => setMenuOpen(false)}>
          <AddCandidateDrawer />
        </Drawer>
        <div className='move-to-top'>
          <div className='circle'>
            <a
              style={{ cursor: 'pointer' }}
              onClick={() => {
                reference.current?.scrollIntoView({
                  behavior: 'smooth',
                  block: 'start',
                  inline: 'nearest',
                });
                setTimeout(() => (window.location.href = '#root'), 10);
              }}>
              <Tooltip title='Move to top'>
                <KeyboardArrowUpSharpIcon fontSize='large' />
              </Tooltip>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
export default TadJobDefinitionDetailsPage;
