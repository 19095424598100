import ReactDOM from 'react-dom/client';
import './index.scss';
import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import App from './components/app';
import Contact from './components/contact';
import Login from './components/login';
import './assets/fonts/ambit/Ambit-Bold.ttf';
import './assets/fonts/ambit/Ambit-BoldItalic.ttf';
import './assets/fonts/ambit/Ambit-Light.ttf';
import './assets/fonts/ambit/Ambit-Regular.ttf';
import './assets/fonts/ambit/Ambit-SemiBold.ttf';
import Dashboard from './components/dashboard/dashboard';
import Questionnaire from './components/questionnaire/questionnaire';
import Reporting from './components/reporting/reporting';
import CandidateSearch from './components/candidate-search/candidate-search';
import SourceJobDefinition from './components/source-job-definition/source-job-definition';
import SourceJobDefinitionEdit from './components/source-job-definition/source-job-definition-edit';
import SourceJobDefinitionCreate from './components/source-job-definition/source-job-definition-create';
import UserSettings from './components/user-setting/user-settings';
import AddCandidate from './components/candidate/add-candidate';
import CandidateEdit from './components/candidate/candidate-edit';
import QuestionnaireCreate from './components/questionnaire/questionnaire-create';
import Applicants from './components/applicants/applicants';
import EmployeeReferral from './components/employee-referral/employee-referral';
// import LmsDashboard from './components/lms/lms-dashboard';
import AssignCourses from './components/lms/assign-courses';
import LmsCourseDashboard from './components/lms/lms_course_dashboard';
import ErrorPage from './error-page';
import './tad-revamp-ui/theme/variables.scss';
import TadDashboard from './tad-revamp-ui/components/dashboard/tad-dashboard';
import TadSourceJobDefinitionEdit from './tad-revamp-ui/components/source-job-definition/tad-source-job-defination-edit';
import TadSourceJobRequisition from './tad-revamp-ui/components/source-job-definition/tad-source-job-requisition';
import TadJobDefinitionDetailsPage from './tad-revamp-ui/components/source-job-definition/job-definition-details-page';
import TadSourceJobDefinition from './tad-revamp-ui/components/source-job-definition/tad-source-job-definition-table';
import TadUserSettings from './tad-revamp-ui/components/user-settings/new-user-settings';
import TadLogin from './tad-revamp-ui/components/login';
import AddCandidates from './tad-revamp-ui/components/candidate/add-candidate/add-candidate';
import CandidateTable from './tad-revamp-ui/components/candidate/candidate-table';
import CandidateDetailsPage from './tad-revamp-ui/components/candidate/tad-candidate-details-page';
import ForgotPassword from './tad-revamp-ui/components/forgotPassword';

const tadSwitch = localStorage.getItem('switch');

const router = createBrowserRouter([
  {
    path: '/reset-password/:username/:token',
    element: <ForgotPassword />,
  },
  {
    path: '/login',
    element: tadSwitch == 'true' ? <TadLogin /> : <Login />,
  },
  // {
  //   path: '/loginrevamp',
  //   element: <LoginRevamp />,
  // },
  {
    path: '/',
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: '/contacts/:contactId',
        element: <Contact />,
      },
      {
        path: '/dashboard',
        element: tadSwitch == 'true' ? <TadDashboard /> : <Dashboard />,
        // element: <NewDashboard />,
      },
      {
        path: '/source-job-definition/candidate/:sjdId/:candidateId',
        element: <CandidateEdit />,
      },
      {
        path: '/source-job-definition/candidate/:sjdId',
        element: <AddCandidate />,
      },
      {
        path: '/source-job-definition/create',
        element: <SourceJobDefinitionCreate />,
      },
      {
        path: '/job-definition/:sjdId',
        element: <TadSourceJobDefinitionEdit />,
      },
      {
        path: '/source-job-definition/create-requisition',
        element: tadSwitch == 'true' && <TadSourceJobRequisition />,
      },
      {
        path: '/job-definition-details/:sjdId',
        element: <TadJobDefinitionDetailsPage />,
      },
      {
        path: '/source-job-definition/:sjdId',
        element: <SourceJobDefinitionEdit />,
      },
      {
        path: '/source-job-definition',
        element: tadSwitch == 'true' ? <TadSourceJobDefinition /> : <SourceJobDefinition />,
      },
      {
        path: '/questionnaire/create',
        element: <QuestionnaireCreate />,
      },
      {
        path: '/questionnaire',
        element: <Questionnaire />,
      },
      {
        path: '/candidate/add-candidate',
        element: <AddCandidates />,
      },
      {
        path: '/candidate',
        element: <CandidateTable />,
      },
      {
        path: '/candidate/:candidateId',
        element: <CandidateDetailsPage />,
      },
      {
        path: '/reporting',
        element: <Reporting />,
      },
      {
        path: '/candidate-search',
        element: <CandidateSearch />,
      },
      {
        path: '/settings',
        element: tadSwitch == 'true' ? <TadUserSettings /> : <UserSettings />,
      },
      {
        path: '/employee-referral',
        element: <EmployeeReferral />,
      },
      {
        path: '/applicants',
        element: <Applicants />,
      },
      {
        path: '/lms',
        element: <LmsCourseDashboard />,
      },
      {
        path: '/assign-courses',
        element: <AssignCourses />,
      },
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(<RouterProvider router={router} />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
