import {
  Avatar,
  Chip,
  // MenuItem,
  // Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';
// import { ReactComponent as MoreVertIcon } from '../../../assets/dashboard-assets/more_vert.svg';

const CandidateJobDefinition = () => {
  const data = [
    {
      sjd: { id: 'TAD1490', name: 'Product Manager' },
      value: '01',
      recruiters: '',
      status: 'In Progress',
    },
    {
      sjd: { id: 'TAD1490', name: 'PHP Developer' },
      value: '04',
      recruiters: '',
      status: 'In Progress',
    },
  ];
  return (
    <>
      <Paper style={{ height: '26.125rem', width: '100%' }}>
        <div
          style={{
            height: '61px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            backgroundColor: 'var(--Secondary-Grey-2, #E7E7E7)',
          }}>
          <div style={{ display: 'flex' }}>
            <span
              className='heading'
              style={{ height: '24px', fontSize: '18px', marginLeft: '1.81rem' }}>
              Job Definitions ({data.length})
            </span>
          </div>
        </div>
        <div className='qc-table' style={{ marginLeft: '2.02rem', marginRight: '1.25rem' }}>
          <Table>
            <TableHead style={{ borderBottom: '0.125rem solid #C1C0C0' }}>
              <TableRow>
                <TableCell>Job Definition</TableCell>
                <TableCell>Candidates</TableCell>
                <TableCell>Recruiters</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow style={{ height: '0.62rem', padding: 0 }}>
                <TableCell colSpan={4} style={{ padding: 0, border: 'none' }} />
              </TableRow>
              <TableRow
                style={{
                  width: '43.875rem',
                  height: '4.875rem',
                  //   background: '#F1F4F8',
                  padding: 0,
                }}>
                <TableCell>
                  <span
                    style={{
                      color: '#868686',
                      fontSize: '0.875rem',
                      display: 'flex',
                      fontWeight: '400',
                    }}>
                    {data[0].sjd.id}
                  </span>
                  <span
                    style={{
                      textDecoration: 'underline',
                      fontSize: '1rem',
                      fontWeight: 600,
                      color: '#1976D2',
                    }}>
                    {data[0].sjd.name}
                  </span>
                </TableCell>
                <TableCell
                  style={{
                    fontSize: '1rem',
                    fontWeight: 600,
                    textDecoration: 'underline',
                    color: '#1976D2',
                  }}>
                  {data[0].value}
                </TableCell>
                <TableCell>
                  <Avatar alt={data[0].sjd.name} src='/static/images/avatar/1.jpg'></Avatar>
                  {data[0].recruiters}
                </TableCell>
                <TableCell
                  style={{
                    padding: 0,
                    marginTop: '1.69rem',
                    marginBottom: '1.31rem',
                    marginRight: '0.78rem',
                  }}>
                  <Chip
                    label={data[0].status}
                    style={{
                      backgroundColor: '#F1D87E',
                      fontSize: '0.875rem',
                      fontWeight: '600',
                      height: '1.875rem',
                      width: 'auto',
                      padding: '0 8px',
                      borderRadius: '0.3125rem',
                      textOverflow: 'clip',
                      overflow: 'visible',
                      whiteSpace: 'nowrap',
                    }}
                  />
                </TableCell>
              </TableRow>
              <TableRow style={{ height: '0.62rem', background: 'transparent', padding: 0 }}>
                <TableCell colSpan={4} style={{ padding: 0, border: 'none' }} />
              </TableRow>
              <TableRow
                style={{
                  width: '43.875rem',
                  height: '4.875rem',
                  //   background: '#F1F4F8',
                  padding: 0,
                }}>
                <TableCell>
                  <span
                    style={{
                      color: '#868686',
                      fontSize: '0.875rem',
                      display: 'flex',
                      fontWeight: '400',
                    }}>
                    {data[1].sjd.id}
                  </span>
                  <span
                    style={{
                      textDecoration: 'underline',
                      fontSize: '1rem',
                      fontWeight: 600,
                      color: '#1976D2',
                    }}>
                    {data[1].sjd.name}
                  </span>
                </TableCell>
                <TableCell
                  style={{
                    fontSize: '1rem',
                    fontWeight: 600,
                    textDecoration: 'underline',
                    color: '#1976D2',
                  }}>
                  {data[1].value}
                </TableCell>
                <TableCell>
                  <Avatar alt={data[1].sjd.name} src='/static/images/avatar/2.jpg' />
                  {data[1].recruiters}
                </TableCell>
                <TableCell
                  style={{
                    padding: 0,
                    marginTop: '1.69rem',
                    marginBottom: '1.31rem',
                    marginRight: '0.78rem',
                  }}>
                  <Chip
                    label={data[1].status}
                    style={{
                      backgroundColor: '#F1D87E',
                      fontSize: '0.875rem',
                      fontWeight: '600',
                      height: '1.875rem',
                      width: 'auto',
                      padding: '0 8px',
                      borderRadius: '0.3125rem',
                      textOverflow: 'clip',
                      overflow: 'visible',
                      whiteSpace: 'nowrap',
                    }}
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </div>
      </Paper>
    </>
  );
};
export default CandidateJobDefinition;
