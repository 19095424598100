import React, { useState } from 'react';
import {
  Paper,
  Typography,
  FormControl,
  FormControlLabel,
  Select,
  MenuItem,
  FormLabel,
  Button,
  Checkbox,
  TextField,
  IconButton,
  AlertColor,
  Tabs,
  Tab,
  Switch,
  Rating,
  Tooltip,
  Backdrop,
  CircularProgress,
  // Radio,
  // RadioGroup,
} from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import { FormikProps, useFormik } from 'formik';
import * as Yup from 'yup';
import { generateQuestionnaire } from '../../../service/tadSjdService';
import Notify from '../../../common/notify';
import TadLoader from '../../../common/tad-loader';
// import { Gauge, gaugeClasses } from '@mui/x-charts/Gauge';
import './questionnaire.scss';
// import { ReactComponent as CancelIcon } from '../../assets/dashboard-assets/cancel.svg';
import { ReactComponent as AiIcon } from '../../../assets/dashboard-assets/Group 343.svg';
// import { ReactComponent as MoreIcon } from '../../../assets/dashboard-assets/more_vert.svg';
import { ReactComponent as DeleteIcon } from './../../../assets/sjd/delete_outline.svg';
import { ReactComponent as AddIcon } from './../../../assets/sjd/add.svg';
import { ReactComponent as RadionCheckedIcon } from './../../../assets/sjd/radio_button_checked.svg';
import { ReactComponent as FontIcon } from './../../../assets/sjd/font_download.svg';
import { ReactComponent as StarHalfIcon } from './../../../assets/sjd/star_half.svg';
import { ReactComponent as ToggleOff } from '../../../../assets/toggle_on.svg';
import { ReactComponent as ToggleOn } from '../../../../assets/toggle_on_1.svg';
import { LookupTypes } from '../../../../common/lookupTypes';
import globalObject from '../../../../common/global-variables';
import { getNameFromId } from '../../../common/commonUtil';
import { saveTadQuestionnaire } from '../../../service/tadQuestionnaireService';
import { getIdFromName } from '../../../../common/commonUtil';

interface QuestionnaireProps {
  jobDescription: any;
}

interface OwnQuestionType {
  questionType: number;
  answerType: number;
  question: string;
  required: boolean;
  expectedAnswer: string | undefined;
  options: OptionsType[] | undefined;
}

interface OptionsType {
  label: string;
  text?: string;
  isCorrect: boolean;
}

const Questionnaires = ({ jobDescription }: QuestionnaireProps) => {
  const savedQuestionnaire = jobDescription?.questionnaire?.[0];
  const questionnaireSchema = Yup.object().shape({
    questionnaireType: Yup.array().of(Yup.string()).min(1, 'Questionnaire Type are required'),
    questionnaireLevel: Yup.string().required('Questionnaire Level is required'),
  });

  const formik = useFormik({
    initialValues: {
      questionnaireType: [] as any,
      questionnaireLevel: '',
      noOfQuestions: '',
    },
    onSubmit: (values) => {
      setGenerate(true);
      setSelectedTab(values.questionnaireType[0]);
      if (createQuestionnaireType === 1) {
        generateAiQuestionnaire(values);
      }
    },
    validationSchema: questionnaireSchema,
    validateOnBlur: true,
  });

  const [showOptions, setShowOptions] = useState(true);
  const [loading, setLoading] = useState(false);
  const [createQuestionnaireType, setCreateQuestionnaireType] = useState(1);
  const [showOverlay, setShowOverlay] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMsg, setToastMsg] = useState({
    severity: 'success',
    msg: '',
  } as {
    severity: AlertColor;
    msg: string;
  });
  const [question, setQuestions] = useState([] as any);
  const [selectedTab, setSelectedTab] = useState(0);
  const [generate, setGenerate] = useState(false);
  const [ownQuestions, setOwnQuestions] = useState<OwnQuestionType[]>([]);

  const generateAiObject = (object: any) => {
    return object.map((q: any) => {
      const formattedOptions = q.options
        ? Object.keys(q.options).map((key) => ({
            label: key,
            text: q.options?.[key],
            isCorrect: q.correct_option?.startsWith(key) || false,
          }))
        : undefined;
      return {
        questionType: getIdFromName(
          q.questionType === `${'Technical(mcq'}'s)`
            ? 'Technical'
            : q.questionType === 'Technical(contextual)'
            ? 'Technical(contextual)'
            : q.questionType === 'general'
            ? 'General'
            : q.questionType,
          LookupTypes.TAD_QUESTIONS_TYPE,
        ),
        answerType: formattedOptions
          ? getIdFromName('Choice', LookupTypes.TAD_ANSWER_TYPE)
          : getIdFromName('Text', LookupTypes.TAD_ANSWER_TYPE),
        question: q.question,
        required: true,
        options: formattedOptions || null,
        expectedAnswer: q.correct_option || q.Expected_Answer || null,
      };
    });
  };

  const addNewQuestion = (type: any) => {
    const newQuestion: OwnQuestionType = {
      questionType: selectedTab,
      answerType: type,
      question: '',
      required: false,
      options:
        type === 1
          ? [
              { label: 'A', text: '', isCorrect: false },
              { label: 'B', text: '', isCorrect: false },
            ]
          : [],
      expectedAnswer: '',
    };

    setOwnQuestions([...ownQuestions, newQuestion]);
    setShowOptions(false);
  };

  const deleteQuestion = (originalIndex: number) => {
    const updatedQuestions = ownQuestions.filter((_, index) => index !== originalIndex);
    setOwnQuestions(updatedQuestions);
  };

  const addOption = (questionIndex: number) => {
    const updatedQuestions = ownQuestions.map((question, index) => {
      if (index === questionIndex && question.answerType === 1) {
        const newOption = { label: 'AB', text: '', isCorrect: false };
        return { ...question, options: [...(question.options || []), newOption] };
      }
      return question;
    });
    setOwnQuestions(updatedQuestions);
  };

  const removeOption = (questionIndex: number, optionIndex: number) => {
    const updatedQuestions = ownQuestions.map((question, index) => {
      if (index === questionIndex && question.answerType === 1) {
        const updatedOptions = question.options?.filter((_, i) => i !== optionIndex);
        return { ...question, options: updatedOptions };
      }
      return question;
    });
    setOwnQuestions(updatedQuestions);
  };

  const handleTextFieldChange = (
    originalIndex: number,
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | null,
    type: string,
    optionIndex?: number,
  ) => {
    let updatedQuestions;

    if (type === 'question' && event) {
      updatedQuestions = ownQuestions.map((question, index) =>
        index === originalIndex ? { ...question, question: event.target.value } : question,
      );
    } else if (type === 'option' && event) {
      updatedQuestions = ownQuestions.map((question, index) => {
        if (index === originalIndex) {
          const updatedOptions = question.options?.map((opt, i) =>
            i === optionIndex ? { ...opt, text: event.target.value } : opt,
          ) as OptionsType[];
          return { ...question, options: updatedOptions };
        }
        return question;
      });
    } else if (type === 'correctOption') {
      updatedQuestions = ownQuestions.map((question, index) => {
        if (index === originalIndex) {
          const updatedOptions = question.options?.map((opt, i) => ({
            ...opt,
            isCorrect: i === optionIndex,
          }));
          return { ...question, options: updatedOptions };
        }
        return question;
      });
    } else if (event) {
      updatedQuestions = ownQuestions.map((question, index) =>
        index === originalIndex ? { ...question, correctAnswer: event.target.value } : question,
      );
    }

    setOwnQuestions(updatedQuestions as OwnQuestionType[]);
  };

  const handleSaveQuestionnaire = () => {
    let obj;
    createQuestionnaireType === 2
      ? isFormValid() &&
        (obj = {
          questionnaireName: jobDescription.name,
          createQuestionnaireType: createQuestionnaireType,
          questionnaireLevel: formik.values.questionnaireLevel,
          questions: ownQuestions,
        })
      : (obj = {
          questionnaireName: jobDescription.name,
          createQuestionnaireType: createQuestionnaireType,
          questionnaireLevel: formik.values.questionnaireLevel,
          questions: generateAiObject(question),
        });
    setLoading(true);
    saveTadQuestionnaire(obj, jobDescription.id)
      .then((_resp) => {
        setLoading(false);
        setShowToast(true);
        setShowOverlay(false);
        setToastMsg({ severity: 'success', msg: 'Questionnaire created Successfully' });
        window.location.reload();
      })
      .catch((_err) => {
        setLoading(false);
        setShowToast(true);
        setShowOverlay(false);
        setToastMsg({ severity: 'error', msg: 'Something went wrong!' });
      });
  };

  // // const handleRadioChange = (originalIndex: number, value: string) => {
  // //   const updatedQuestions = ownQuestions.map((question, index) =>
  // //     index === originalIndex ? { ...question, question: value } : question,
  // //   );
  // //   setOwnQuestions(updatedQuestions);
  // // };

  const handleRequiredChange = (originalIndex: number, checked: boolean) => {
    const updatedQuestions = ownQuestions.map((question, index) =>
      index === originalIndex ? { ...question, required: checked } : question,
    );
    setOwnQuestions(updatedQuestions);
  };

  const validateQuestion = (question: string) => {
    return question.trim().length > 0;
  };

  const isFormValid = () => {
    return ownQuestions.every((question) => validateQuestion(question.question));
  };

  function getError(formik: FormikProps<any>, fieldName: string) {
    if (formik.touched[fieldName] && formik.errors[fieldName])
      return formik.errors[fieldName]?.toString();
    return null;
  }

  const generateAiQuestionnaire = (values: any) => {
    setShowOverlay(true);
    const questionnaireType = values.questionnaireType
      .map((e: any) => getNameFromId(e, LookupTypes.TAD_QUESTIONS_TYPE))
      .join(', ');
    generateQuestionnaire({
      jobDescription: jobDescription.name,
      questionnaireType: questionnaireType,
      questionnaireLevel: getNameFromId(
        values.questionnaireLevel,
        LookupTypes.TAD_QUESTIONNAIRE_LEVEL,
      ),
      noOfQuestions: Number(values.noOfQuestions) == 0 ? 4 : Number(values.noOfQuestions),
    })
      .then((resp) => {
        setQuestions(resp.data.questions);
        setShowToast(true);
        setShowOverlay(false);
        setToastMsg({ severity: 'success', msg: 'Questionnaire Added Successfully' });
      })
      .catch((err) => {
        console.log(err);
        setShowToast(true);
        setShowOverlay(false);
        setToastMsg({ severity: 'error', msg: 'Failed to Add Questionnaire' });
        setQuestions([]);
      });
  };

  const tabStyles = () => ({
    padding: '0px !important',
    paddingRight: '10px !important',
    paddingLeft: '10px !important',
    height: '5px !important',
    '&.Mui-selected': {
      fontWeight: '600 !important',
    },
    fontSize: '1rem',
    fontStyle: 'normal',
    fontWeight: '400 !important',
    lineHeight: '1.5rem',
    fontFamily: 'Roboto !important',
    color: 'var(--Primary-Dark-grey-500, #333) !important',
    textTransform: 'none !important',
  });

  // const [selectedOptions, setSelectedOptions] = useState<Record<number, number | undefined>>({});
  // const [score, setScore] = useState(0);
  // const [handleScoreResult, sethandleScoreResult] = useState(false);

  // const questionData = {
  //   jdTitle: 'HTML Hyperlink Creation',
  //   questions: [
  //     {
  //       questionNumber: 1,
  //       question: 'How do you create a hyperlink in HTML?',
  //       options: [
  //         { text: '<link>Example</link>', correct: false },
  //         { text: '<a href="www.example.com">Example</a>', correct: true },
  //         { text: '<a href="www.example.com">Example</a>', correct: false },
  //         { text: 'www.example.com Example', correct: false },
  //       ],
  //     },
  //     {
  //       questionNumber: 2,
  //       question: 'What is the correct HTML element for inserting a line break?',
  //       options: [
  //         { text: '<br>', correct: true },
  //         { text: '<break>', correct: false },
  //         { text: '<lb>', correct: false },
  //         { text: '<newline>', correct: false },
  //       ],
  //     },
  //     {
  //       questionNumber: 3,
  //       question: 'How do you create a hyperlink in HTML?',
  //       options: [
  //         { text: '<link>Example</link>', correct: false },
  //         { text: '<a href="www.example.com">Example</a>', correct: true },
  //         { text: '<a href="www.example.com">Example</a>', correct: false },
  //         { text: 'www.example.com Example', correct: false },
  //       ],
  //     },
  //     {
  //       questionNumber: 4,
  //       question: 'How do you create a hyperlink in HTML?',
  //       options: [
  //         { text: '<link>Example</link>', correct: false },
  //         { text: '<a href="www.example.com">Example</a>', correct: true },
  //         { text: '<a href="www.example.com">Example</a>', correct: false },
  //         { text: 'www.example.com Example', correct: false },
  //       ],
  //     },
  //     {
  //       questionNumber: 5,
  //       question: 'How do you create a hyperlink in HTML?',
  //       options: [
  //         { text: '<link>Example</link>', correct: false },
  //         { text: '<a href="www.example.com">Example</a>', correct: true },
  //         { text: '<a href="www.example.com">Example</a>', correct: false },
  //         { text: 'www.example.com Example', correct: false },
  //       ],
  //     },
  //   ],
  // };

  // const titleStyle: React.CSSProperties = {
  //   color: 'var(--Primary-Dark-grey-500, #333)',
  //   fontFamily: 'Roboto',
  //   fontSize: '1rem',
  //   fontStyle: 'normal',
  //   fontWeight: 600,
  //   lineHeight: '1.5625rem',
  // };

  // const optionStyle = (isCorrect: boolean): React.CSSProperties => ({
  //   height: '3.3125rem',
  //   flexShrink: 0,
  //   borderRadius: '0.3125rem',

  //   background: isCorrect
  //     ? 'var(--Status-Green-100, #C7E8C5)'
  //     : 'var(--Secondary-colors-Grey-3, #F1F4F8)',
  //   display: 'flex',
  //   alignItems: 'center',
  //   padding: '0 0.5rem',
  //   boxSizing: 'border-box',
  //   justifyContent: 'space-between',
  // });

  const handleChange = (_event: any) => {
    // const { name, value, type, checked } = event.target;
    // setFormData((prevData) => ({
    //   ...prevData,
    //   [name]: type === 'checkbox' ? checked : value,
    // }));
  };

  // const handleSubmit = async (event: any) => {
  //   event.preventDefault();

  //   try {
  //     const response = await axios.post('https://jsonplaceholder.typicode.com/users', formData);
  //     console.log('Response from API:', response.data);
  //   } catch (error) {
  //     console.error('Error submitting form:', error);
  //   }
  // };

  // useEffect(() => {
  //   calculateScore();
  // }, [selectedOptions]);

  // const handleOptionClick = (questionNumber: number, index: number) => {
  //   setSelectedOptions((prev) => ({
  //     ...prev,
  //     [questionNumber]: index,
  //   }));
  // };

  // const calculateScore = () => {
  //   let finalScore = 0;
  //   questionData.questions.forEach((question) => {
  //     const selectedOption = selectedOptions[question.questionNumber];
  //     if (selectedOption !== undefined) {
  //       const isCorrect = question.options[selectedOption]?.correct;
  //       if (isCorrect) finalScore++;
  //     }
  //   });
  //   setScore(finalScore);
  // };

  // const handlescore = () => {
  //   sethandleScoreResult(true);
  // };

  const handleDelete = (type: string, indexToDelete: number) => {
    setQuestions((prevQuestions: any) => {
      let updatedQuestions = [...prevQuestions];
      console.log('updatedQuestion:', updatedQuestions);
      let filteredQuestionsByType = updatedQuestions.filter(
        (q: any) => q !== null && q.questionType === type,
      );
      console.log('filteredDataBefrore: ', filteredQuestionsByType);
      filteredQuestionsByType = filteredQuestionsByType.filter(
        (_q: any, index: number) => index !== indexToDelete,
      );
      console.log('filteredData: ', filteredQuestionsByType);
      updatedQuestions = updatedQuestions
        .filter((q: any) => q === null || q.questionType !== type)
        .concat(filteredQuestionsByType);
      return updatedQuestions;
    });
  };

  const questionTypes = Array.from(
    new Set(savedQuestionnaire?.questions?.map((question: any) => question.questionType)),
  ).map((questionType: any) => getNameFromId(questionType, LookupTypes.TAD_QUESTIONS_TYPE));
  // const handleQuestionnaireSubmit = (values) => {

  //   const questionnarieOption = {
  //     questionType:'',
  //     answerType:'',
  //     question:'',
  //     required:'',
  //     correctAnswer: '',
  //   }

  //   const obj = {
  //     questionnaireName:'',
  //     createQuestionnaireType:'',
  //     questionnaireLevel: '',
  //     questions:'',
  //   }

  // }

  const aiGeneratedQuestionnaire = () => {
    return (
      <>
        {
          //General Questionnaire
          getNameFromId(selectedTab, LookupTypes.TAD_QUESTIONS_TYPE) === 'General' &&
            ((
              savedQuestionnaire?.questions?.length > 0
                ? savedQuestionnaire?.questions.filter(
                    (e: any) =>
                      getNameFromId(
                        e.questionType,
                        LookupTypes.TAD_QUESTIONS_TYPE,
                      )?.toLowerCase() === 'general',
                  ).length > 0
                : question.filter((e: any) => e.questionType === 'general').length > 0
            ) ? (
              <>
                {(savedQuestionnaire
                  ? savedQuestionnaire?.questions?.filter(
                      (e: any) =>
                        getNameFromId(
                          e.questionType,
                          LookupTypes.TAD_QUESTIONS_TYPE,
                        )?.toLowerCase() === 'general',
                    )
                  : question.filter((e: any) => e.questionType === 'general')
                )?.map((e: any, i: any) => {
                  return (
                    <div key={i} className='general-questionnarie-container'>
                      <div style={{ display: 'flex', gap: '0.5rem' }}>
                        <span className='questionnaire-text'>{`Q${i + 1}: `}</span>
                        <span className='questionnaire-text'>{`${e.question}?`}</span>
                      </div>
                      {savedQuestionnaire == null && (
                        <div className='questionnaire-delete-Icon'>
                          <DeleteIcon
                            style={{ color: '#868686', cursor: 'pointer' }}
                            onClick={() => handleDelete('general', i)}
                          />
                        </div>
                      )}
                    </div>
                  );
                })}
              </>
            ) : (
              <div style={{ textAlign: 'center' }}>
                <span>No Records found</span>
              </div>
            ))
        }
        {
          //Technical(mcq's) Questionnaire
          getNameFromId(selectedTab, LookupTypes.TAD_QUESTIONS_TYPE) === 'Technical' &&
            ((
              savedQuestionnaire?.questions?.length > 0
                ? savedQuestionnaire?.questions.filter(
                    (e: any) =>
                      getNameFromId(e.questionType, LookupTypes.TAD_QUESTIONS_TYPE) === 'Technical',
                  ).length > 0
                : question.filter((e: any) => e.questionType === `${'Technical(mcq'}'s)`).length > 0
            ) ? (
              <>
                {(savedQuestionnaire
                  ? savedQuestionnaire?.questions.filter(
                      (e: any) =>
                        getNameFromId(e.questionType, LookupTypes.TAD_QUESTIONS_TYPE) ===
                        'Technical',
                    )
                  : question.filter((e: any) => e.questionType === `${'Technical(mcq'}'s)`)
                )?.map((e: any, index: any) => {
                  return (
                    <div key={index} className='technical-questionnaire-container'>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          marginBottom: '0.5rem',
                          marginTop: '1rem',
                        }}>
                        <div style={{ display: 'flex', gap: '0.5rem' }}>
                          <div className='questionnaire-text'>{`Q${index + 1}: `}</div>
                          <div className='questionnaire-text'>{`${e.question}?`}</div>
                        </div>
                        {savedQuestionnaire == null && (
                          <div className='questionnaire-delete-Icon'>
                            <DeleteIcon
                              style={{ cursor: 'pointer', color: '#868686' }}
                              onClick={() => handleDelete(`${'Technical(mcq'}'s)`, index)}
                            />
                          </div>
                        )}
                      </div>
                      {savedQuestionnaire != null ? (
                        e.options?.map((option: any, index: any) => (
                          <div key={index} className='techincal-questionnaire-option'>
                            <span className='questionnaire-text'>
                              {savedQuestionnaire
                                ? `${option?.label}. ${`${option?.text}`}`
                                : `${option}. ${option.A}`}
                            </span>
                          </div>
                        ))
                      ) : (
                        <>
                          <div className='techincal-questionnaire-option'>
                            <span className='questionnaire-text'>
                              {savedQuestionnaire
                                ? `${e.options[0]?.label}. ${e.options[0].text}`
                                : `${Object.keys(e.options)[0]}. ${e.options.A}`}
                            </span>
                          </div>
                          <div className='techincal-questionnaire-option'>
                            <span className='questionnaire-text'>
                              {savedQuestionnaire
                                ? `${e.options[1]?.label}. ${e.options[1].text}`
                                : `${Object.keys(e.options)[1]}. ${e.options.A}`}
                            </span>
                          </div>
                          <div className='techincal-questionnaire-option'>
                            <span className='questionnaire-text'>
                              {savedQuestionnaire
                                ? `${e.options[2]?.label}. ${e.options[2].text}`
                                : `${Object.keys(e.options)[2]}. ${e.options.A}`}
                            </span>
                          </div>
                          <div className='techincal-questionnaire-option'>
                            <span className='questionnaire-text'>
                              {savedQuestionnaire
                                ? `${e.options[3]?.label}. ${e.options[3].text}`
                                : `${Object.keys(e.options)[3]}. ${e.options.A}`}
                            </span>
                          </div>
                        </>
                      )}
                    </div>
                  );
                })}
              </>
            ) : (
              <div style={{ textAlign: 'center' }}>
                <span>No Records found</span>
              </div>
            ))
        }
        {
          //Technical(contextual) Questionnaire
          getNameFromId(selectedTab, LookupTypes.TAD_QUESTIONS_TYPE) === 'Technical(contextual)' &&
            ((
              savedQuestionnaire?.questions?.length > 0
                ? savedQuestionnaire?.questions.filter(
                    (e: any) =>
                      getNameFromId(e.questionType, LookupTypes.TAD_QUESTIONS_TYPE) ===
                      'Technical(contextual)',
                  ).length > 0
                : question.filter((e: any) => e.questionType === 'Technical(contextual)').length > 0
            ) ? (
              <>
                {(savedQuestionnaire
                  ? savedQuestionnaire?.questions?.filter(
                      (e: any) =>
                        getNameFromId(e.questionType, LookupTypes.TAD_QUESTIONS_TYPE) ===
                        'Technical(contextual)',
                    )
                  : question.filter((e: any) => e.questionType === 'Technical(contextual)')
                )?.map((e: any, i: any) => {
                  return (
                    <div key={i} className='general-questionnarie-container'>
                      <div style={{ display: 'flex', gap: '0.5rem' }}>
                        <span className='questionnaire-text'>{`Q${i + 1}: `}</span>
                        <span className='questionnaire-text'>{`${e.question}?`}</span>
                      </div>
                      {savedQuestionnaire == null && (
                        <div className='questionnaire-delete-Icon'>
                          <DeleteIcon
                            style={{ color: '#868686', cursor: 'pointer' }}
                            onClick={() => handleDelete('Technical(contextual)', i)}
                          />
                        </div>
                      )}
                    </div>
                  );
                })}
              </>
            ) : (
              <div style={{ textAlign: 'center' }}>
                <span>No Records found</span>
              </div>
            ))
        }
        {
          //Behavioral Questionnaire
          getNameFromId(selectedTab, LookupTypes.TAD_QUESTIONS_TYPE) === 'Behavioral' &&
            ((
              savedQuestionnaire?.questions?.length > 0
                ? savedQuestionnaire?.questions.filter(
                    (e: any) =>
                      getNameFromId(e.questionType, LookupTypes.TAD_QUESTIONS_TYPE) ===
                      'Behavioral',
                  ).length > 0
                : question.filter((e: any) => e.questionType === 'Behavioral').length > 0
            ) ? (
              <>
                {(savedQuestionnaire
                  ? savedQuestionnaire?.questions?.filter(
                      (e: any) =>
                        getNameFromId(e.questionType, LookupTypes.TAD_QUESTIONS_TYPE) ===
                        'Behavioral',
                    )
                  : question.filter((e: any) => e.questionType === 'Behavioral')
                )?.map((e: any, i: any) => {
                  return (
                    <div key={i} className='general-questionnarie-container'>
                      <div style={{ display: 'flex', gap: '0.5rem' }}>
                        <div className='questionnaire-text'>{`Q${i + 1}: `}</div>
                        <div className='questionnaire-text'>{`${e.question}?`}</div>
                      </div>
                      {savedQuestionnaire == null && (
                        <div className='questionnaire-delete-Icon'>
                          <DeleteIcon
                            style={{ cursor: 'pointer', color: '#868686' }}
                            onClick={() => handleDelete('Behavioral', i)}
                          />
                        </div>
                      )}
                    </div>
                  );
                })}
              </>
            ) : (
              <div style={{ textAlign: 'center' }}>
                <span>No Records found</span>
              </div>
            ))
        }
      </>
    );
  };

  const ownGeneratedQuestionnaire = () => {
    return (
      <>
        {savedQuestionnaire ? (
          <>
            {
              //General Questionnaire
              getNameFromId(selectedTab, LookupTypes.TAD_QUESTIONS_TYPE) === 'General' &&
                ((
                  savedQuestionnaire?.questions?.length > 0
                    ? savedQuestionnaire?.questions.filter(
                        (e: any) =>
                          getNameFromId(
                            e.questionType,
                            LookupTypes.TAD_QUESTIONS_TYPE,
                          )?.toLowerCase() === 'general',
                      ).length > 0
                    : question.filter((e: any) => e.questionType === 'general').length > 0
                ) ? (
                  <>
                    {(savedQuestionnaire
                      ? savedQuestionnaire?.questions?.filter(
                          (e: any) =>
                            getNameFromId(
                              e.questionType,
                              LookupTypes.TAD_QUESTIONS_TYPE,
                            )?.toLowerCase() === 'general',
                        )
                      : question.filter((e: any) => e.questionType === 'general')
                    )?.map((e: any, i: any) => {
                      return (
                        <div key={i} className='general-questionnarie-container'>
                          <div style={{ display: 'flex', gap: '0.5rem' }}>
                            <span className='questionnaire-text'>{`Q${i + 1}: `}</span>
                            <span className='questionnaire-text'>{`${e.question}?`}</span>
                          </div>
                          {savedQuestionnaire == null && (
                            <div className='questionnaire-delete-Icon'>
                              <DeleteIcon
                                style={{ color: '#868686', cursor: 'pointer' }}
                                onClick={() => handleDelete('general', i)}
                              />
                            </div>
                          )}
                        </div>
                      );
                    })}
                  </>
                ) : (
                  <div style={{ textAlign: 'center' }}>
                    <span>No Records found</span>
                  </div>
                ))
            }
            {
              //Technical(mcq's) Questionnaire
              getNameFromId(selectedTab, LookupTypes.TAD_QUESTIONS_TYPE) === 'Technical' &&
                ((
                  savedQuestionnaire?.questions?.length > 0
                    ? savedQuestionnaire?.questions.filter(
                        (e: any) =>
                          getNameFromId(e.questionType, LookupTypes.TAD_QUESTIONS_TYPE) ===
                          'Technical',
                      ).length > 0
                    : question.filter((e: any) => e.questionType === `${'Technical(mcq'}'s)`)
                        .length > 0
                ) ? (
                  <>
                    {(savedQuestionnaire
                      ? savedQuestionnaire?.questions.filter(
                          (e: any) =>
                            getNameFromId(e.questionType, LookupTypes.TAD_QUESTIONS_TYPE) ===
                            'Technical',
                        )
                      : question.filter((e: any) => e.questionType === `${'Technical(mcq'}'s)`)
                    )?.map((e: any, index: any) => {
                      return (
                        <div key={index} className='technical-questionnaire-container'>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              marginBottom: '0.5rem',
                              marginTop: '1rem',
                            }}>
                            <div style={{ display: 'flex', gap: '0.5rem' }}>
                              <div className='questionnaire-text'>{`Q${index + 1}: `}</div>
                              <div className='questionnaire-text'>{`${e.question}?`}</div>
                            </div>
                            {savedQuestionnaire == null && (
                              <div className='questionnaire-delete-Icon'>
                                <DeleteIcon
                                  style={{ cursor: 'pointer', color: '#868686' }}
                                  onClick={() => handleDelete(`${'Technical(mcq'}'s)`, index)}
                                />
                              </div>
                            )}
                          </div>
                          {e.options?.map((option: any, index: any) => (
                            <div key={index} className='techincal-questionnaire-option'>
                              <span className='questionnaire-text'>
                                {savedQuestionnaire
                                  ? `${option?.label}. ${`${option?.text}`}`
                                  : `${option}. ${option.A}`}
                              </span>
                            </div>
                          ))}
                          {/* <div className='techincal-questionnaire-option'>
                          <span className='questionnaire-text'>
                            {savedQuestionnaire ? `${e.options[0]?.label}. ${e.options[0].text}` :   `${Object.keys(e.options)[0]}. ${e.options.A}`}
                          </span>
                        </div>
                        <div className='techincal-questionnaire-option'>
                          <span className='questionnaire-text'>
                          {savedQuestionnaire ? `${e.options[1]?.label}. ${e.options[1].text}` :   `${Object.keys(e.options)[1]}. ${e.options.A}`}
                          </span>
                        </div>
                        <div className='techincal-questionnaire-option'>
                          <span className='questionnaire-text'>
                          {savedQuestionnaire ? `${e.options[2]?.label}. ${e.options[2].text}` :   `${Object.keys(e.options)[2]}. ${e.options.A}`}
                          </span>
                        </div>
                        <div className='techincal-questionnaire-option'>
                          <span className='questionnaire-text'>
                          {savedQuestionnaire ? `${e.options[3]?.label}. ${e.options[3].text}` :   `${Object.keys(e.options)[3]}. ${e.options.A}`}
                          </span>
                        </div> */}
                        </div>
                      );
                    })}
                  </>
                ) : (
                  <div style={{ textAlign: 'center' }}>
                    <span>No Records found</span>
                  </div>
                ))
            }
            {
              //Technical(contextual) Questionnaire
              getNameFromId(selectedTab, LookupTypes.TAD_QUESTIONS_TYPE) ===
                'Technical(contextual)' &&
                ((
                  savedQuestionnaire?.questions?.length > 0
                    ? savedQuestionnaire?.questions.filter(
                        (e: any) =>
                          getNameFromId(e.questionType, LookupTypes.TAD_QUESTIONS_TYPE) ===
                          'Technical(contextual)',
                      ).length > 0
                    : question.filter((e: any) => e.questionType === 'Technical(contextual)')
                        .length > 0
                ) ? (
                  <>
                    {(savedQuestionnaire
                      ? savedQuestionnaire?.questions?.filter(
                          (e: any) =>
                            getNameFromId(e.questionType, LookupTypes.TAD_QUESTIONS_TYPE) ===
                            'Technical(contextual)',
                        )
                      : question.filter((e: any) => e.questionType === 'Technical(contextual)')
                    )?.map((e: any, i: any) => {
                      return (
                        <div key={i} className='general-questionnarie-container'>
                          <div style={{ display: 'flex', gap: '0.5rem' }}>
                            <span className='questionnaire-text'>{`Q${i + 1}: `}</span>
                            <span className='questionnaire-text'>{`${e.question}?`}</span>
                          </div>
                          {savedQuestionnaire == null && (
                            <div className='questionnaire-delete-Icon'>
                              <DeleteIcon
                                style={{ color: '#868686', cursor: 'pointer' }}
                                onClick={() => handleDelete('Technical(contextual)', i)}
                              />
                            </div>
                          )}
                        </div>
                      );
                    })}
                  </>
                ) : (
                  <div style={{ textAlign: 'center' }}>
                    <span>No Records found</span>
                  </div>
                ))
            }

            {
              //Behavioral Questionnaire
              getNameFromId(selectedTab, LookupTypes.TAD_QUESTIONS_TYPE) === 'Behavioral' &&
                ((
                  savedQuestionnaire?.questions?.length > 0
                    ? savedQuestionnaire?.questions.filter(
                        (e: any) =>
                          getNameFromId(e.questionType, LookupTypes.TAD_QUESTIONS_TYPE) ===
                          'Behavioral',
                      ).length > 0
                    : question.filter((e: any) => e.questionType === 'Behavioral').length > 0
                ) ? (
                  <>
                    {(savedQuestionnaire
                      ? savedQuestionnaire?.questions.filter(
                          (e: any) =>
                            getNameFromId(e.questionType, LookupTypes.TAD_QUESTIONS_TYPE) ===
                            'Behavioral',
                        )
                      : question.filter((e: any) => e.questionType === 'Behavioral')
                    )?.map((e: any, i: any) => {
                      return (
                        <div key={i} className='general-questionnarie-container'>
                          <div style={{ display: 'flex', gap: '0.5rem' }}>
                            <div className='questionnaire-text'>{`Q${i + 1}: `}</div>
                            <div className='questionnaire-text'>{`${e.question}?`}</div>
                          </div>
                          {savedQuestionnaire == null && (
                            <div className='questionnaire-delete-Icon'>
                              <DeleteIcon
                                style={{ cursor: 'pointer', color: '#868686' }}
                                onClick={() => handleDelete('Behavioral', i)}
                              />
                            </div>
                          )}
                        </div>
                      );
                    })}
                  </>
                ) : (
                  <div style={{ textAlign: 'center' }}>
                    <span>No Records found</span>
                  </div>
                ))
            }
          </>
        ) : (
          <div>
            {ownQuestions
              .map((question, originalIndex) => ({ ...question, originalIndex }))
              .filter((e) => e.questionType === selectedTab)
              .map(({ originalIndex, ...value }, index) => (
                <div key={index} className='create-questionnaire-container'>
                  <div className='questions-section'>
                    <div style={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
                      <span>{`${index + 1}.`}</span>
                      <TextField
                        fullWidth
                        className='input-field'
                        sx={{ background: '#fff' }}
                        value={value.question}
                        onChange={(event) =>
                          handleTextFieldChange(originalIndex, event, 'question')
                        }
                      />
                    </div>
                    <div style={{ color: 'red', fontSize: '0.875rem', marginLeft: '25px' }}>
                      {!validateQuestion(value.question) && 'Question cannot be empty'}
                    </div>
                    {value.answerType === 1 && (
                      <>
                        {value.options?.map((e, i) => (
                          <div
                            key={i}
                            style={{ display: 'flex', alignItems: 'center', marginLeft: '1.5rem' }}>
                            <Typography style={{ marginRight: '0.5rem' }}>
                              {`${String.fromCharCode(97 + i).toLocaleUpperCase()}) `}
                            </Typography>
                            <TextField
                              sx={{ background: '#fff' }}
                              className='input-field'
                              fullWidth
                              onChange={(event) =>
                                handleTextFieldChange(originalIndex, event, 'option', i)
                              }
                            />
                            <Tooltip title={'Select as correct option'}>
                              <input
                                style={{
                                  cursor: 'pointer',
                                  marginLeft: '15px',
                                  accentColor: 'var(--Primary-Blue-500, #1976d2)',
                                }}
                                type='radio'
                                checked={e.isCorrect}
                                onChange={() =>
                                  handleTextFieldChange(originalIndex, null, 'correctOption', i)
                                }
                              />
                            </Tooltip>
                            <DeleteIcon
                              style={{ color: '#868686', cursor: 'pointer', marginLeft: '5px' }}
                              onClick={() => removeOption(originalIndex, i)} // Pass the original index here
                            />
                          </div>
                        ))}
                        <div
                          style={{
                            display: 'flex',
                            gap: '0.5rem',
                            marginBottom: '0.5rem',
                            marginTop: '0.5rem',
                            marginLeft: '1.5rem',
                          }}>
                          <IconButton sx={{ padding: 0 }} onClick={() => addOption(originalIndex)}>
                            <AddIcon style={{ color: '#1976D2' }} />
                          </IconButton>
                          <Typography>Add Option</Typography>
                        </div>
                      </>
                    )}
                    {value.answerType === 2 && (
                      <div style={{ marginLeft: '1.5rem' }}>
                        <TextField
                          fullWidth
                          disabled
                          className='input-field'
                          placeholder='Candidate answer'
                          sx={{ marginBottom: '1rem' }}
                        />
                        <TextField
                          fullWidth
                          className='input-field'
                          sx={{ background: '#fff' }}
                          placeholder='Correct answer'
                          onChange={(event) =>
                            handleTextFieldChange(originalIndex, event, 'correctAnswer')
                          }
                        />
                      </div>
                    )}
                    {value.answerType === 3 && (
                      <Rating
                        className='rating'
                        disabled
                        name='half-rating'
                        emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize='inherit' />}
                      />
                    )}
                  </div>
                  <div style={{ display: 'flex' }}>
                    <div className='questions-left-action-section'>
                      <DeleteIcon
                        style={{ color: '#868686', cursor: 'pointer' }}
                        onClick={() => deleteQuestion(originalIndex)} // Pass the original index here
                      />
                    </div>
                    <div className='questions-right-action-section'>
                      <Typography>Required</Typography>
                      <Switch
                        size='small'
                        className='switch'
                        checked={value.required}
                        inputProps={{ 'aria-label': 'controlled' }}
                        onChange={(e) => handleRequiredChange(originalIndex, e.target.checked)}
                        icon={<ToggleOff />}
                        checkedIcon={<ToggleOn />}
                      />
                    </div>
                  </div>
                </div>
              ))}
            <div className='add-question-container'>
              <IconButton
                sx={{ padding: 0 }}
                onClick={() => setShowOptions(showOptions ? false : true)}>
                <AddIcon style={{ color: '#1976D2' }} />
              </IconButton>
              <Typography>
                {ownQuestions.length == 0 ? 'Quick start with' : 'Add new question'}
              </Typography>
            </div>
            {showOptions && (
              <div style={{ display: 'flex', gap: '2rem' }}>
                <div
                  className='question-type'
                  onClick={() => {
                    addNewQuestion(1);
                  }}>
                  <RadionCheckedIcon />
                  <Typography>Choice</Typography>
                </div>
                <div
                  className='question-type'
                  onClick={() => {
                    addNewQuestion(2);
                  }}>
                  <FontIcon />
                  <Typography>Text</Typography>
                </div>
                <div
                  className='question-type'
                  onClick={() => {
                    addNewQuestion(3);
                  }}>
                  <StarHalfIcon />
                  <Typography>Rating</Typography>
                </div>
              </div>
            )}
          </div>
        )}
      </>
    );
  };

  const questionTypeIds = Array.from(
    new Set(savedQuestionnaire?.questions?.map((question: any) => question.questionType)),
  ).map((questionType: any) => questionType);

  return (
    <Paper className='paper'>
      <div className='header-container' style={{ background: 'var(--Secondary-Grey-2, #E7E7E7)' }}>
        <div className='header-content-title'>
          <span>Questionnaire</span>
        </div>
        {/* <div className='header-content-icon'>
          <IconButton>
            <MoreIcon />
          </IconButton>
        </div> */}
      </div>
      <form className='questionnaire-form'>
        <div className='title-section' style={{ marginBottom: '1rem' }}>
          <Typography className='questionnaire-heading' variant='h6' gutterBottom>
            Create a Questionnaire
          </Typography>
          <Typography variant='body2' color='textSecondary' gutterBottom>
            You can create a questionnaire from the existing template or generate new
          </Typography>
        </div>
        {/* <span>
          {JSON.stringify(savedQuestionnaire)}
        </span> */}
        <div className='questionnaire-type-section'>
          {/* <span>
            {JSON.stringify({
              createQuestionnaireType: createQuestionnaireType,
              selectedTab: selectedTab,
              generate: generate,
              formik: formik.values.questionnaireType.map((v: any) =>
                getNameFromId(v, LookupTypes.TAD_QUESTIONS_TYPE),
              ),
              condition: globalObject.lookupvalues[LookupTypes.TAD_QUESTIONS_TYPE].filter(
                (e: any) =>
                  formik.values.questionnaireType
                    .map((v: any) => getNameFromId(v, LookupTypes.TAD_QUESTIONS_TYPE))
                    .includes(e.name),
              ),
            })}
          </span> */}
          <FormControl fullWidth sx={{ marginBottom: '1.5rem' }}>
            <FormLabel className='form-label'>
              Choose how you want to create Questionnaire
            </FormLabel>
            <Select
              fullWidth
              id='demo-simple-select'
              className='input-field'
              name='questionnaireLevel'
              disabled={savedQuestionnaire?.createQuestionnaireType ? true : false}
              value={
                savedQuestionnaire
                  ? savedQuestionnaire?.createQuestionnaireType
                  : createQuestionnaireType
              }
              onChange={(e) => setCreateQuestionnaireType(Number(e.target.value))}
              sx={{
                height: 'var(--Font-size-h2, 2.5rem)',
              }}>
              {globalObject.lookupvalues[LookupTypes.TAD_CREATE_QUESTIONNAIRE_TYPE].map(
                (type: any, index: number) => (
                  <MenuItem key={index} value={type.id}>
                    {type.name}
                  </MenuItem>
                ),
              )}
            </Select>
          </FormControl>
        </div>
        <div className='questionnaire-type-section'>
          <FormControl fullWidth sx={{ marginBottom: '0.5rem' }}>
            <FormLabel className='form-label'>Choose Questionnaire Type</FormLabel>
            <Select
              labelId='demo-multiple-checkbox-label'
              id='demo-multiple-checkbox'
              fullWidth
              disabled={questionTypeIds.length > 0 ? true : false}
              multiple
              className='input-field'
              name='questionnaireType'
              value={questionTypeIds.length > 0 ? questionTypeIds : formik.values.questionnaireType}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              renderValue={() => {
                return (
                  <span>
                    {(questionTypeIds.length > 0
                      ? questionTypeIds
                      : formik.values.questionnaireType
                    )
                      .map((e: any) => getNameFromId(e, LookupTypes.TAD_QUESTIONS_TYPE))
                      .join(', ')}
                  </span>
                );
              }}
              error={Boolean(formik.errors.questionnaireType && formik.touched.questionnaireType)}
              sx={{
                height: 'var(--Font-size-h2, 2.5rem)',
              }}>
              {globalObject.lookupvalues[LookupTypes.TAD_QUESTIONS_TYPE].map(
                (option: any, index: any) => (
                  <MenuItem key={index} value={option.id} sx={{ padding: 0 }}>
                    <Checkbox checked={formik.values.questionnaireType.includes(option.id)} />
                    <span>
                      {option.name === 'Technical'
                        ? 'Technical (MCQ)'
                        : option.name == 'Technical(contextual)'
                        ? 'Technical (Contextual)'
                        : option.name}
                    </span>
                  </MenuItem>
                ),
              )}
            </Select>
            <div className='mt-0 form-err-message'>{getError(formik, 'questionnaireType')}</div>
          </FormControl>
        </div>
        <div className='questionnaire-type-section'>
          <FormControl fullWidth sx={{ marginBottom: '0.5rem' }}>
            <FormLabel className='form-label'>Choose Questionnaire Level</FormLabel>
            <Select
              fullWidth
              className='input-field'
              error={Boolean(formik.errors.questionnaireLevel && formik.touched.questionnaireLevel)}
              name='questionnaireLevel'
              disabled={savedQuestionnaire?.questionnaireLevel ? true : false}
              value={
                savedQuestionnaire
                  ? savedQuestionnaire.questionnaireLevel
                  : formik.values.questionnaireLevel
              }
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              style={{
                height: 'var(--Font-size-h2, 2.5rem)',
              }}>
              {globalObject.lookupvalues[LookupTypes.TAD_QUESTIONNAIRE_LEVEL].map(
                (level: any, index: number) => (
                  <MenuItem key={index} value={level.id}>
                    {level.name}
                  </MenuItem>
                ),
              )}
            </Select>
            <div className='mt-0 form-err-message'>{getError(formik, 'questionnaireLevel')}</div>
          </FormControl>
        </div>
        {savedQuestionnaire == null && createQuestionnaireType === 1 && (
          <div className='questionnaire-type-section'>
            <FormControl fullWidth sx={{ marginBottom: '1.5rem' }}>
              <FormLabel className='form-label'>Choose no. of questions</FormLabel>
              <TextField
                fullWidth
                className='input-field'
                name='noOfQuestions'
                value={formik.values.noOfQuestions}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                variant='outlined'
              />
            </FormControl>
          </div>
        )}

        <div className='questionnaire-buttons-section'>
          <FormControlLabel
            className='form-label'
            control={
              <Checkbox
                disabled={questionTypeIds.length > 0 ? true : false}
                name='saveTemplate'
                // checked={formData.saveTemplate}
                onChange={handleChange}
                style={{ width: '1.5rem', height: '1.5rem' }}
              />
            }
            label='Save in questionnaire template'
          />
          <Button
            sx={{
              backgroundColor:
                questionTypeIds.length > 0
                  ? '#c0c0c0 !important'
                  : 'var(--Primary-Blue-500, #1976d2) !important',
            }}
            className='questionnaire-button'
            variant='contained'
            disabled={questionTypeIds.length > 0 ? true : false}
            color='primary'
            fullWidth
            onClick={() => {
              formik.handleSubmit();
              setSelectedTab(formik.values.questionnaireType);
            }}>
            <AiIcon />
            {createQuestionnaireType === 1 ? 'Generate' : 'Add'}
          </Button>
        </div>
        {(savedQuestionnaire != null || generate) && (
          <>
            {!showOverlay ? (
              <div
                style={{
                  borderTop: '1px solid var(--Secondary-colors-Grey-2, #E6E7E8)',
                  paddingTop: '1rem',
                }}>
                <div>
                  <div>
                    <Typography
                      sx={{
                        fontWeight: 600,
                        marginBottom: '1rem',
                      }}>{`AI Questionnaire for "${jobDescription.name}"`}</Typography>
                    {questionTypes.length != 0 ? (
                      <Tabs
                        className='questionnaire-tabs'
                        value={selectedTab}
                        onChange={(_e, v) => {
                          setSelectedTab(v);
                        }}>
                        {questionTypes.map((header: any, index: any) => (
                          <Tab
                            sx={tabStyles}
                            key={index}
                            value={getIdFromName(header, LookupTypes.TAD_QUESTIONS_TYPE)}
                            label={
                              header === 'Technical'
                                ? 'Technical (MCQ)'
                                : header === 'Technical(contextual)'
                                ? 'Technical (Contextual)'
                                : header
                            }
                          />
                        ))}
                      </Tabs>
                    ) : (
                      <Tabs
                        className='questionnaire-tabs'
                        value={selectedTab}
                        onChange={(_e, v) => {
                          setSelectedTab(v);
                        }}>
                        {globalObject.lookupvalues[LookupTypes.TAD_QUESTIONS_TYPE]
                          .filter((e: any) =>
                            formik.values.questionnaireType
                              .map((v: any) => getNameFromId(v, LookupTypes.TAD_QUESTIONS_TYPE))
                              .includes(e.name),
                          )
                          .map((header: any, index: any) => (
                            <Tab
                              sx={tabStyles}
                              key={index}
                              value={header.id}
                              label={
                                header.name === 'Technical'
                                  ? 'Technical (MCQ)'
                                  : header.name === 'Technical(contextual)'
                                  ? 'Technical (Contextual)'
                                  : header.name
                              }
                            />
                          ))}
                      </Tabs>
                    )}

                    <div>
                      {(savedQuestionnaire
                        ? savedQuestionnaire?.createQuestionnaireType
                        : createQuestionnaireType) === 1 && aiGeneratedQuestionnaire()}
                      {(savedQuestionnaire
                        ? savedQuestionnaire?.createQuestionnaireType
                        : createQuestionnaireType) === 2 && ownGeneratedQuestionnaire()}
                    </div>
                  </div>
                </div>

                {savedQuestionnaire == null && (
                  <div
                    style={{
                      marginTop: '2rem',
                      display: 'flex',
                      justifyContent: 'flex-end',
                    }}>
                    <Button
                      className='questionnaire-button'
                      sx={{
                        backgroundColor:
                          questionTypeIds.length > 0
                            ? '#c0c0c0 !important'
                            : 'var(--Primary-Blue-500, #1976d2) !important',
                        width: '6rem !important',
                      }}
                      onClick={() => {
                        handleSaveQuestionnaire();
                      }}>
                      Save
                    </Button>
                  </div>
                )}
              </div>
            ) : (
              <div>
                <TadLoader />
              </div>
            )}
          </>
        )}
        {/*
        <div className='containerStyle'>
          <div style={{ textAlign: 'left' }}>
            <Tabs className='tab' value={selectedTab} onChange={(_e, v) => setSelectedTab(v)}>
              {tabsHeaders.map((header, index) => (
                <Tab
                  sx={{
                    color: 'var(--Primary-Dark-grey-500, #333)',
                    fontFamily: 'Roboto',
                    fontSize: '0.7625rem',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    lineHeight: '1.5625rem',
                    textTransform: 'none',
                    borderBottom: '2px solid transparent',
                    '&.Mui-selected': {
                      borderBottom: '5px solid var(--Primary-Blue-500, #1976D2)',
                    },
                    '&:hover': {
                      backgroundColor: 'rgba(0, 0, 0, 0.1)',
                      fontWeight: 600,
                    },
                  }}
                  key={index}
                  value={header}
                  label={header}
                />
              ))}
            </Tabs>
          </div>
          <hr style={{ color: '#C1C0C0', margin: 0, marginTop: '-1.54rem' }}></hr>
          <div className='headerStyle'>
            <Typography variant='h6' gutterBottom style={titleStyle}>
              {questionData.jdTitle}
            </Typography>
          </div>

          {handleScoreResult && (
            <div
              style={{
                width: '38.5rem',
                height: '7.4375rem',
                border: (() => {
                  if (score === 1 || score === 2) return '1px solid #DC3857'; // Red for score 1 and 2
                  if (score === 3) return '1px solid #1976D2'; // Blue for score 3
                  return '1px solid #4BB543'; // Green for score 4 and 5
                })(),
                boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                borderRadius: '0.3125rem',
                display: 'flex',
                justifyContent: 'space-between',
                backgroundColor: '#FFF',
              }}>
              <div>
                <div style={{ display: 'flex', marginLeft: '1.38rem', marginTop: '1.25rem' }}>
                  {score === 1 && <CancelIcon />}
                  {score === 2 && <CancelIcon />}
                  {score === 3 && (
                    <img
                      src={require('../../assets/dashboard-assets/info.png')}
                      alt='Average'
                      style={{
                        width: '1.5rem',
                        height: '1.5rem',
                        borderRadius: '0.3125rem',
                        marginRight: '0.5rem',
                      }}
                    />
                  )}
                  {score >= 4 && score <= 5 && (
                    <img
                      src={require('../../assets/dashboard-assets/check_circle.png')}
                      alt='Good or Excellent'
                      style={{
                        width: '1.5rem',
                        height: '1.5rem',
                        borderRadius: '0.3125rem',
                        marginRight: '0.5rem',
                      }}
                    />
                  )}
                  <span
                    style={{
                      color: (() => {
                        if (score === 1 || score === 2) return '#DC3857';
                        if (score === 3) return '#1976D2';
                        return '#4BB543';
                      })(),
                      fontFamily: 'Roboto',
                      fontSize: '1rem',
                      fontWeight: 600,
                      lineHeight: '1.5625rem',
                    }}>
                    {score === 1 && '1: Poor'}
                    {score === 2 && '2: Below Average'}
                    {score === 3 && '3: Average'}
                    {score === 4 && '4: Good'}
                    {score === 5 && '5: Excellent'}
                  </span>
                </div>
                <div style={{ marginLeft: '3.25rem' }}>
                  <Typography
                    style={{
                      width: '29.5rem',
                      height: '2.9375rem',
                      color: (() => {
                        if (score === 1 || score === 2) return '#DC3857';
                        if (score === 3) return '#1976D2';
                        return '#4BB543';
                      })(),
                      fontFamily: 'Roboto',
                      fontSize: '1rem',
                      fontWeight: 400,
                      lineHeight: '1.5625rem',
                    }}>
                    {score === 1 &&
                      'Very limited understanding or proficiency in technical skills.'}
                    {score === 2 &&
                      'Some understanding but lacks key competencies; needs significant improvement.'}
                    {score === 3 &&
                      'Adequate proficiency with room for improvement; basic technical skills are present.'}
                    {score === 4 &&
                      'Strong technical skills with a solid grasp of necessary competencies.'}
                    {score === 5 &&
                      'Exceptional technical skills, showing a high level of expertise.'}
                  </Typography>
                </div>
              </div>

              <div
                style={{
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  marginTop: '1.5rem',
                  marginRight: '1.56rem',
                  marginBottom: '1.5rem',
                }}>
                <Gauge
                  value={(score / questionData.questions.length) * 100}
                  startAngle={-180}
                  endAngle={180}
                  sx={{
                    width: '4.4375rem',
                    height: '4.4375rem',
                    flexShrink: 0,
                    [`& .${gaugeClasses.valueText}`]: {
                      fontSize: '1rem',
                      color: (() => {
                        if (score <= 3) return '#DC3857';
                        if (score <= 6) return '#1976D2';
                        return '#4BB543';
                      })(),
                      fontFamily: 'Roboto',
                      fontWeight: 600,
                      lineHeight: '1.5625rem',
                      fill: (() => {
                        if (score <= 3) return '#DC3857';
                        if (score <= 6) return '#1976D2';
                        if (score <= 10) return '#4BB543';
                        return '#0000ff';
                      })(),
                    },
                  }}
                  text={() => `${score}/${questionData.questions.length}`}
                />
              </div>
            </div>
          )}

          <div style={{ maxHeight: '800px', overflowX: 'hidden', position: 'relative' }}>
            <div
              style={{
                maxHeight: '800px',
                overflowY: 'auto',
                overflowX: 'hidden',
                paddingRight: '15px',
              }}>
              {questionData.questions.map((question) => (
                <div key={question.questionNumber} style={{ marginBottom: '2rem' }}>
                  <Typography
                    variant='body2'
                    color='textSecondary'
                    style={{
                      color: '#333',
                      fontFamily: 'Roboto',
                      fontSize: '1rem',
                      fontWeight: 400,
                      lineHeight: '1.5625rem',
                    }}>
                    Q{question.questionNumber}: {question.question}
                  </Typography>

                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '0.5rem',
                      width: '38.5rem',
                    }}>
                    {question.options.map((option, index) => {
                      const isSelected = selectedOptions[question.questionNumber] === index;
                      const isCorrect = option.correct;

                      return (
                        <div
                          key={index}
                          style={optionStyle(isSelected)}
                          onClick={() => handleOptionClick(question.questionNumber, index)}>
                          <Typography
                            variant='body1'
                            style={{
                              color: '#333',
                              fontFamily: 'Roboto',
                              fontSize: '1rem',
                              fontWeight: 400,
                              lineHeight: '1.5625rem',
                              margin: 0,
                            }}>
                            {String.fromCharCode(65 + index)}. {option.text}
                          </Typography>
                          {isSelected && (
                            <>
                              <img
                                src={require('../../assets/dashboard-assets/' +
                                  (isCorrect ? 'check_circle_outline.png' : 'highlight_off.png'))}
                                alt={isCorrect ? 'Correct' : 'Incorrect'}
                                style={{
                                  width: '1.5rem',
                                  height: '1.5rem',
                                  borderRadius: '0.3125rem',
                                }}
                              />
                            </>
                          )}

                          {isSelected != isCorrect && (
                            <>
                              <img
                                src={require('../../assets/dashboard-assets/' +
                                  (isCorrect ? 'check_circle_outline.png' : 'highlight_off.png'))}
                                alt={isCorrect ? 'Correct' : 'Incorrect'}
                                style={{
                                  width: '1.5rem',
                                  height: '1.5rem',
                                  borderRadius: '0.3125rem',
                                }}
                              />
                            </>
                          )}
                        </div>
                      );
                    })}
                  </div>
                </div>
              ))}
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  width: 'auto',
                  marginRight: '1rem',
                  marginBottom: '1.5rem',
                }}>
                <Button
                  style={{
                    width: '7.5rem',
                    height: 'var(--Font-size-h2, 2.5rem)',
                    flexShrink: 0,
                    borderRadius: '0.3125rem',
                    border: '1px solid #2D7DFE',
                    background: 'var(--Primary-Blue-500, #1976D2)',
                    color: 'white',
                    cursor: 'pointer',
                  }}
                  onClick={handlescore}>
                  Save
                </Button>
              </div>
            </div>
          </div>
        </div> */}
      </form>
      {loading && (
        <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}>
          <CircularProgress color='inherit' />
        </Backdrop>
      )}
      <Notify
        open={showToast}
        severity={toastMsg.severity}
        onClose={() => setShowToast(false)}
        message={toastMsg.msg}></Notify>
    </Paper>
  );
};

export default Questionnaires;
