import {
  IconButton,
  MenuItem,
  Tab,
  Tabs,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Avatar,
  Chip,
  Checkbox,
  Menu,
  ListItemIcon,
  ListItemText,
  Link,
  Backdrop,
  CircularProgress,
  InputAdornment,
  TextField,
  List,
  ListItem,
  Typography,
  // ListItem,
  // Typography,
} from '@mui/material';
// import React from 'react';
// import PageHeader from '../../../components/common/page-header/page-header';
// import { useState } from 'react';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import useNavigate from '../../../common/useNavigate';
import React, { useEffect, useState } from 'react';
// import AddHiringTeam from './../../assets/sjd/Vector.svg';
import Edit from '../../assets/sjd/Edit.svg';
// import Share from './../../assets/sjd/Share.svg';
// import Export from '../../assets/sjd/Export.svg';
// import Duplicate from '../../assets/sjd/Duplicate.svg';
import PutOnHold from '../../assets/sjd/Put on Hold.svg';
// import Publish from '../../assets/sjd/Publish.svg';
// import Archive from '../../assets/sjd/Archive.svg';
import { ReactComponent as DropDownArrow } from '../../assets/sjd/dropdownarrow.svg';
import { ReactComponent as MoreVert } from '../../assets/dashboard-assets/more_vert.svg';
// import { ReactComponent as GroupAdd } from '../../assets/dashboard-assets/group_add.svg';
// import { ReactComponent as SortIcon } from '../../assets/candidate-assets/sort_by_alpha.svg';
import { ReactComponent as SearchIcon } from '../../assets/candidate-assets/search.svg';
import { ReactComponent as AddOutlineIcon } from '../../assets/sjd/Add_OutlineIcon.svg';

import { getAllTadSjd, tadUpdateSjd } from '../../service/tadSjdService';
import globalObject from '../../../common/global-variables';
import { LookupTypes } from '../../../common/lookupTypes';
import { getIdFromName, getNameFromId } from '../../../common/commonUtil';
import { parseLocation } from '../../common/commonUtil';
import TadPageHeader from '../common/page-header/tad-page-header';
import TadTableFilter from '../common/tad-table-filter';
import TadTableSorting from '../common/tad-table-sorting';
import { getTadSkills } from '../../../service/lookupService';
import { SJD_STATUS, TADROLES } from '../../../constants/common';
import { getAllUsers } from '../../../service/userService';

const TadSourceJobDefinition = () => {
  const [selectedTab, setSelectedTab] = useState('All');
  const [navigate] = useNavigate();
  const [selectedJobs, setSelectedJobs] = useState<number[]>([]);
  const [selectedStatusMap, setSelectedStatusMap] = useState<Record<number, number>>({});
  const [rowsToDisplay, setRowsToDisplay] = useState(5);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [loading, setLoading] = useState(false);
  const [filterView] = useState(false);
  const [allSkills, setAllSkills] = useState([] as any[]);
  const [recruiters, setRecruiters] = useState([] as any[]);
  const [locations, setLocations] = useState([] as any[]);
  const [selectedSjd, setSelectedSjd] = useState(0);
  const [searchText, setSearchText] = useState('');

  const handleStatusChange = (jobId: number, newStatus: number) => {
    setSelectedStatusMap((prevState) => ({
      ...prevState,
      [jobId]: newStatus,
    }));
  };

  useEffect(() => {
    getTadSkills().then((res) => setAllSkills(res.data));
  }, []);

  const headers = [
    { id: 1, label: 'Job Definition' },
    { id: 2, label: 'Priority' },
    { id: 3, label: 'Created On' },
    { id: 4, label: 'Hiring Manager' },
    // { id: 5, label: 'Candidates' },
    { id: 5, label: 'Recruiters' },
    { id: 6, label: 'Skills Required' },
    { id: 7, label: 'Location' },
    { id: 8, label: 'No. of Positions' },
    { id: 9, label: 'Status' },
    { id: 10, label: 'Actions' },
  ];

  const [selectedColumns, setSelectedColumns] = useState<number[]>(
    headers.map((header: any) => header.id),
  );

  const statusColorMapping: { [key: string]: string } = {
    'In Progress': '#ADD8E6',
    'On Hold': '#FDDA95',
    Completed: '#C7E8C5',
    Archived: '#D3D3D3',
    Cancelled: '#FFC0CB',
  };

  const priorityColorMapping: { [key: string]: string } = {
    Low: '#ACDDA9',
    Medium: '#FDDA95',
    High: '#EFA3B2',
  };

  const priorityOptions = globalObject.lookupvalues[LookupTypes.TAD_PRIORITY]?.map(
    (status: any) => ({
      ...status,
      color: priorityColorMapping[status.name],
    }),
  );

  const statusOptions = globalObject.lookupvalues[LookupTypes.TAD_SJD_STATUS]?.map(
    (status: any) => ({
      ...status,
      color: statusColorMapping[status.name],
    }),
  );

  const handleSearch = (search?: string) => {
    let c = sjdRows;
    if (search && search.length) {
      c = c.filter((sjd: any) => sjd.name && sjd.name.toLowerCase().includes(search.toLowerCase()));
      setDisplaySjds(c);
    } else {
      setDisplaySjds(c);
    }
  };

  // const handleFiltersSearch = (array: any, search: string) => {
  //   let c = array;
  //   if (search && search.length) {
  //     c = c.filter((sjd: any) => sjd.name && sjd.name.toLowerCase().includes(search.toLowerCase()));
  //   }
  // };

  const formatDate = (timestamp: number): string => {
    const date = new Date(timestamp);
    const options: Intl.DateTimeFormatOptions = {
      day: '2-digit',
      month: 'long',
      year: 'numeric',
    };
    return date.toLocaleDateString('en-GB', options);
  };

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setSelectedJobs(sjdRows.map((job) => job.id));
    } else {
      setSelectedJobs([]);
    }
  };

  const handleSelectJob = (event: React.ChangeEvent<HTMLInputElement>, id: number) => {
    if (event.target.checked) {
      setSelectedJobs((prevSelected) => [...prevSelected, id]);
    } else {
      setSelectedJobs((prevSelected) => prevSelected.filter((selectedId) => selectedId !== id));
    }
  };
  const handleLoadMore = () => {
    setRowsToDisplay((prev) => Math.min(prev + rowsPerPage, sjdRows.length));
  };

  // const handleChangePage = (event: unknown, newPage: number) => {
  //   setPage(newPage);
  // };

  const handleChangeRowsPerPage = (event: SelectChangeEvent<number>) => {
    const newRowsPerPage = parseInt(event.target.value as string, 10);
    setRowsPerPage(newRowsPerPage);
    setRowsToDisplay(newRowsPerPage);
    // console.log(newRowsPerPage, sjdRows);
  };

  const sjdActionPatch = (sjd: any, action: string) => {
    setLoading(true);
    const patchObj: any = {};
    patchObj.id = sjd.id;
    if (action === 'Remove') patchObj.active = false;
    else if (action === 'Publish') patchObj.publish = true;
    else if (action === 'Unpublish') patchObj.publish = false;
    else patchObj.sjdStatusId = getIdFromName(action, LookupTypes.TAD_SJD_STATUS);
    setLoading(true);
    tadUpdateSjd(patchObj).then((_resp) => {
      getAllTadSjd(globalObject.userObject.id).then((res) => {
        setSjdRows(res.data);
        setSjdList(res.data);
        setLoading(false);
      });
    });
  };

  const tabsHeaders = [
    'All',
    'In Progress',
    'On Hold',
    'Completed',
    // 'Published',
    'Archived',
    'Cancelled',
  ];

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const actionsList = [
    // {
    //   name: 'Add Hiring Team',
    //   icon: AddHiringTeam,
    // },
    { name: 'Edit', icon: Edit },
    // { name: 'Share', icon: Share },
    // { name: 'Export', icon: Export },
    // { name: 'Duplicate', icon: Duplicate },
    { name: 'Put On Hold', icon: PutOnHold },
    // { name: 'Publish', icon: Publish },
    // { name: 'Archive', icon: Archive },
  ];

  const showActionsList = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const closeActionsMenu = (jdId: any, action: any) => {
    if (action.name === 'Edit') {
      navigate([
        {
          label: 'New Job Definition',
          link: `/job-definition/${btoa(jdId)}`,
        },
      ]);
    }
    setAnchorEl(null);
  };

  const [sjdList, setSjdList] = useState([] as any[]);
  const [sjdRows, setSjdRows] = useState([] as any[]);
  const [displaySjds, setDisplaySjds] = useState(sjdRows);

  useEffect(() => {
    setLoading(true);
    getAllTadSjd(globalObject.userObject.id).then((res) => {
      setSjdRows(res.data);
      setSjdList(res.data);
      setDisplaySjds(res.data);
      const sjdList: any[] = res.data;
      setLocations(
        Array.from(new Set(sjdList.map((sjd) => parseLocation(sjd.jobLocation)).flat())),
      );
      setLoading(false);
    });
    getAllUsers(getIdFromName(TADROLES.RECRUITER, LookupTypes.TAD_ROLE))
      .then((res) => setRecruiters(res.data))
      .catch(() => {});
  }, []);

  const handleTabs = (v: any) => {
    setSearchText('');
    setSelectedTab(v);
    if (v === 'All') {
      setSjdRows(sjdList);
    } else {
      const filteredSjdList = sjdList.filter(
        (sjdRow) => getNameFromId(sjdRow.sjdStatusId, LookupTypes.TAD_SJD_STATUS) === v,
      );
      setSjdRows(filteredSjdList);
      setDisplaySjds(filteredSjdList);
      setRowsToDisplay(5);
    }
  };

  const paginatedJobs = rowsToDisplay == -1 ? sjdRows : displaySjds.slice(0, rowsToDisplay);

  return (
    <div className='main-div'>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ marginBottom: '1.19rem' }}>
          <TadPageHeader />
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span style={{ fontSize: '1.25rem', marginRight: '1.56rem', fontWeight: 600 }}>
              Job Definitions ({displaySjds.length})
            </span>
            <IconButton
              onClick={() =>
                navigate([
                  {
                    label: 'New Job Requisition',
                    link: '/source-job-definition/create-requisition',
                  },
                ])
              }>
              <AddOutlineIcon />
            </IconButton>
          </div>
          <div style={{ display: 'flex', alignItems: 'baseline', gap: '0.5rem' }}>
            <div>
              {/* <span
                style={{ color: '#1976D2', textDecoration: 'underline', cursor: 'pointer' }}
                onClick={() => setFilterView(!filterView)}>
                Filter By
              </span> */}
            </div>
            <TadTableFilter
              headers={headers}
              selectedHeaders={(value) => setSelectedColumns(value)}
            />
            <TadTableSorting
              data={sjdList}
              sortingData={(value) => {
                setSjdRows(value);
                setSjdList(value);
              }}
            />
          </div>
        </div>
        <div style={{ display: 'flex' }}>
          {filterView && (
            <div
              style={{
                marginRight: '20px',
                marginBottom: '20px',
                width: '18.75rem',
                marginTop: '1rem',
              }}>
              <Paper style={{ width: '100%' }} className='paper'>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginLeft: '1.25rem',
                    marginRight: '1.25rem',
                    paddingTop: '20px',
                  }}>
                  <span className='header-label'>Filter By</span>
                  <span
                    className='header-label'
                    style={{
                      color: 'var(--Hyperlink, var(--Primary-Blue-500, #1976D2))',
                    }}>
                    Clear All
                  </span>
                </div>
                <div>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      marginLeft: '1.25rem',
                      marginRight: '1.25rem',
                      paddingTop: '20px',
                    }}>
                    <span
                      style={{ color: 'var(--Primary-Dark-grey-500, #333)', fontWeight: '600' }}>
                      Hiring Manager
                    </span>
                    <span
                      style={{
                        fontFamily: 'Roboto',
                        color: 'var(--Hyperlink, var(--Primary-Blue-500, #1976D2))',
                        textDecoration: 'underline',
                      }}>
                      Clear All
                    </span>
                  </div>
                  <hr
                    style={{
                      color: '#C1C0C0',
                      marginLeft: '1.25rem',
                      marginRight: '1.25rem',
                    }}></hr>
                  <div>
                    <TextField
                      onChange={(_e) => {
                        // handleFiltersSearch(
                        //   globalObject.lookupvalues[LookupTypes.TAD_HIRING_MANAGER],
                        //   e.target.value,
                        // );
                      }}
                      placeholder='Search'
                      variant='outlined'
                      sx={{
                        width: '16.1875rem',
                        height: '2.1875rem',
                        borderRadius: '0.625rem',
                        border: '1px solid #C1C0C0',
                        background: 'var(--Secondary-colors-White, #FFF)',
                        flexShrink: 0,
                        marginRight: '1.25rem',
                        marginLeft: '1.25rem',
                        padding: 0,
                        '& .MuiOutlinedInput-root': {
                          padding: '0 0.5rem',
                          height: '100%',
                          boxSizing: 'border-box',
                        },
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position='start'>
                            <IconButton>
                              <SearchIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                  <div style={{ height: '10rem', overflowY: 'scroll' }}>
                    <List>
                      {globalObject.lookupvalues[LookupTypes.TAD_HIRING_MANAGER].map((job: any) => (
                        <ListItem key={job.id} sx={{ padding: '0px 0px 0px 16px' }}>
                          <Checkbox
                            checked={selectedJobs.includes(job.id)}
                            // onChange={() => handleSelectJob(job.id)}
                            inputProps={{ 'aria-label': `select job ${job.name}` }}
                          />
                          <Typography variant='body1'>{job.name}</Typography>
                        </ListItem>
                      ))}
                    </List>
                  </div>
                  {/* <span
                    style={{
                      color: 'var(--Hyperlink, var(--Primary-Blue-500, #1976D2))',
                      textDecoration: 'underline',
                      fontWeight: '400',
                      marginLeft: '60px',
                    }}>
                    More...
                  </span> */}
                </div>

                <div>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      marginLeft: '1.25rem',
                      marginRight: '1.25rem',
                      paddingTop: '20px',
                    }}>
                    <span
                      style={{ color: 'var(--Primary-Dark-grey-500, #333)', fontWeight: '600' }}>
                      Priority
                    </span>
                    <span
                      style={{
                        fontFamily: 'Roboto',

                        textDecoration: 'underline',
                      }}>
                      Clear All
                    </span>
                  </div>
                  <hr
                    style={{
                      color: '#C1C0C0',
                      marginLeft: '1.25rem',
                      marginRight: '1.25rem',
                    }}></hr>
                  <div>
                    <TextField
                      // value={searchTeam}
                      // onChange={(e) => {
                      //   setSearchTeam(e.target.value);
                      // }}
                      placeholder='Search'
                      variant='outlined'
                      sx={{
                        width: '16.1875rem',
                        height: '2.1875rem',
                        borderRadius: '0.625rem',
                        border: '1px solid #C1C0C0',
                        background: 'var(--Secondary-colors-White, #FFF)',
                        flexShrink: 0,
                        marginRight: '1.25rem',
                        marginLeft: '1.25rem',
                        padding: 0,
                        '& .MuiOutlinedInput-root': {
                          padding: '0 0.5rem',
                          height: '100%',
                          boxSizing: 'border-box',
                        },
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position='start'>
                            <IconButton>
                              <SearchIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                  <div style={{ height: '10rem', overflowY: 'scroll' }}>
                    <List>
                      {globalObject.lookupvalues[LookupTypes.TAD_PRIORITY].map((job: any) => (
                        <ListItem key={job.id} sx={{ padding: '0px 0px 0px 16px' }}>
                          <Checkbox
                            checked={selectedJobs.includes(job.id)}
                            // onChange={() => handleSelectJob(job.id)}
                            inputProps={{ 'aria-label': `select job ${job.name}` }}
                          />
                          <Typography variant='body1'>{job.name}</Typography>
                        </ListItem>
                      ))}
                    </List>
                    <span className='more'>More...</span>
                  </div>
                </div>

                <div>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      marginLeft: '1.25rem',
                      marginRight: '1.25rem',
                      paddingTop: '20px',
                    }}>
                    <span
                      style={{ color: 'var(--Primary-Dark-grey-500, #333)', fontWeight: '600' }}>
                      Technology
                    </span>
                    <span
                      style={{
                        fontFamily: 'Roboto',

                        textDecoration: 'underline',
                      }}>
                      Clear All
                    </span>
                  </div>
                  <hr
                    style={{
                      color: '#C1C0C0',
                      marginLeft: '1.25rem',
                      marginRight: '1.25rem',
                    }}></hr>
                  <div>
                    <TextField
                      // value={searchTeam}
                      // onChange={(e) => {
                      //   setSearchTeam(e.target.value);
                      // }}
                      placeholder='Search'
                      variant='outlined'
                      sx={{
                        width: '16.1875rem',
                        height: '2.1875rem',
                        borderRadius: '0.625rem',
                        border: '1px solid #C1C0C0',
                        background: 'var(--Secondary-colors-White, #FFF)',
                        flexShrink: 0,
                        marginRight: '1.25rem',
                        marginLeft: '1.25rem',
                        padding: 0,
                        '& .MuiOutlinedInput-root': {
                          padding: '0 0.5rem',
                          height: '100%',
                          boxSizing: 'border-box',
                        },
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position='start'>
                            <IconButton>
                              <SearchIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                  <div style={{ height: '10rem', overflowY: 'scroll' }}>
                    <List>
                      {allSkills?.map((job: any) => (
                        <ListItem key={job.id} sx={{ padding: '0px 0px 0px 16px' }}>
                          <Checkbox
                            checked={selectedJobs.includes(job.id)}
                            // onChange={() => handleSelectJob(job.id)}
                            inputProps={{ 'aria-label': `select job ${job.name}` }}
                          />
                          <Typography variant='body1'>{job.name}</Typography>
                        </ListItem>
                      ))}
                    </List>
                    <span
                      style={{
                        color: 'var(--Hyperlink, var(--Primary-Blue-500, #1976D2))',
                        textDecoration: 'underline',
                        fontWeight: '400',
                        marginLeft: '60px',
                      }}>
                      More...
                    </span>
                  </div>
                </div>
                <div>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      marginLeft: '1.25rem',
                      marginRight: '1.25rem',
                      paddingTop: '20px',
                    }}>
                    <span
                      style={{ color: 'var(--Primary-Dark-grey-500, #333)', fontWeight: '600' }}>
                      Location
                    </span>
                    <span
                      style={{
                        fontFamily: 'Roboto',

                        textDecoration: 'underline',
                      }}>
                      Clear All
                    </span>
                  </div>
                  <hr
                    style={{
                      color: '#C1C0C0',
                      marginLeft: '1.25rem',
                      marginRight: '1.25rem',
                    }}></hr>
                  <div>
                    <TextField
                      // value={searchTeam}
                      // onChange={(e) => {
                      //   setSearchTeam(e.target.value);
                      // }}
                      placeholder='Search'
                      variant='outlined'
                      sx={{
                        width: '16.1875rem',
                        height: '2.1875rem',
                        borderRadius: '0.625rem',
                        border: '1px solid #C1C0C0',
                        background: 'var(--Secondary-colors-White, #FFF)',
                        flexShrink: 0,
                        marginRight: '1.25rem',
                        marginLeft: '1.25rem',
                        padding: 0,
                        '& .MuiOutlinedInput-root': {
                          padding: '0 0.5rem',
                          height: '100%',
                          boxSizing: 'border-box',
                        },
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position='start'>
                            <IconButton>
                              <SearchIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                  <div style={{ height: '10rem', overflowY: 'scroll' }}>
                    <List>
                      {locations.map((job: any) => (
                        <ListItem key={job.id} sx={{ padding: '0px 0px 0px 16px' }}>
                          <Checkbox
                            checked={selectedJobs.includes(job.id)}
                            // onChange={() => handleSelectJob(job.id)}
                            inputProps={{ 'aria-label': `select job ${job.name}` }}
                          />
                          <Typography variant='body1'>{job}</Typography>
                        </ListItem>
                      ))}
                    </List>
                    <span className='more'>More...</span>
                  </div>
                </div>

                <div>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      marginLeft: '1.25rem',
                      marginRight: '1.25rem',
                      paddingTop: '20px',
                    }}>
                    <span
                      style={{ color: 'var(--Primary-Dark-grey-500, #333)', fontWeight: '600' }}>
                      Status
                    </span>
                    <span
                      style={{
                        fontFamily: 'Roboto',

                        textDecoration: 'underline',
                      }}>
                      Clear All
                    </span>
                  </div>
                  <hr
                    style={{
                      color: '#C1C0C0',
                      marginLeft: '1.25rem',
                      marginRight: '1.25rem',
                    }}></hr>
                  <div>
                    <TextField
                      // value={searchTeam}
                      // onChange={(e) => {
                      //   setSearchTeam(e.target.value);
                      // }}
                      placeholder='Search'
                      variant='outlined'
                      sx={{
                        width: '16.1875rem',
                        height: '2.1875rem',
                        borderRadius: '0.625rem',
                        border: '1px solid #C1C0C0',
                        background: 'var(--Secondary-colors-White, #FFF)',
                        flexShrink: 0,
                        marginRight: '1.25rem',
                        marginLeft: '1.25rem',
                        padding: 0,
                        '& .MuiOutlinedInput-root': {
                          padding: '0 0.5rem',
                          height: '100%',
                          boxSizing: 'border-box',
                        },
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position='start'>
                            <IconButton>
                              <SearchIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                  <div style={{ height: '10rem', overflowY: 'scroll' }}>
                    <List>
                      {globalObject.lookupvalues[LookupTypes.SJD_STATUS].map((job: any) => (
                        <ListItem key={job.id} sx={{ padding: '0px 0px 0px 16px' }}>
                          <Checkbox
                            checked={selectedJobs.includes(job.id)}
                            // onChange={() => handleSelectJob(job.id)}
                            inputProps={{ 'aria-label': `select job ${job.name}` }}
                          />
                          <Typography variant='body1'>{job.name}</Typography>
                        </ListItem>
                      ))}
                    </List>
                    <span className='more'>More...</span>
                  </div>
                </div>
                <div>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      marginLeft: '1.25rem',
                      marginRight: '1.25rem',
                      paddingTop: '20px',
                    }}>
                    <span
                      style={{ color: 'var(--Primary-Dark-grey-500, #333)', fontWeight: '600' }}>
                      Client
                    </span>
                    <span
                      style={{
                        fontFamily: 'Roboto',

                        textDecoration: 'underline',
                      }}>
                      Clear All
                    </span>
                  </div>
                  <hr
                    style={{
                      color: '#C1C0C0',
                      marginLeft: '1.25rem',
                      marginRight: '1.25rem',
                    }}></hr>
                  <div>
                    <TextField
                      // value={searchTeam}
                      // onChange={(e) => {
                      //   setSearchTeam(e.target.value);
                      // }}
                      placeholder='Search'
                      variant='outlined'
                      sx={{
                        width: '16.1875rem',
                        height: '2.1875rem',
                        borderRadius: '0.625rem',
                        border: '1px solid #C1C0C0',
                        background: 'var(--Secondary-colors-White, #FFF)',
                        flexShrink: 0,
                        marginRight: '1.25rem',
                        marginLeft: '1.25rem',
                        padding: 0,
                        '& .MuiOutlinedInput-root': {
                          padding: '0 0.5rem',
                          height: '100%',
                          boxSizing: 'border-box',
                        },
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position='start'>
                            <IconButton>
                              <SearchIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                  <div style={{ height: '10rem', overflowY: 'scroll' }}>
                    <List>
                      {globalObject.lookupvalues[LookupTypes.TAD_CLIENT].map((job: any) => (
                        <ListItem key={job.id} sx={{ padding: '0px 0px 0px 16px' }}>
                          <Checkbox
                            checked={selectedJobs.includes(job.id)}
                            // onChange={() => handleSelectJob(job.id)}
                            inputProps={{ 'aria-label': `select job ${job.name}` }}
                          />
                          <Typography variant='body1'>{job.name}</Typography>
                        </ListItem>
                      ))}
                    </List>
                    <span className='more'>More...</span>
                  </div>
                </div>
                <div>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      marginLeft: '1.25rem',
                      marginRight: '1.25rem',
                      paddingTop: '20px',
                    }}>
                    <span
                      style={{ color: 'var(--Primary-Dark-grey-500, #333)', fontWeight: '600' }}>
                      Recruiter
                    </span>
                    <span
                      style={{
                        fontFamily: 'Roboto',

                        textDecoration: 'underline',
                      }}>
                      Clear All
                    </span>
                  </div>
                  <hr
                    style={{
                      color: '#C1C0C0',
                      marginLeft: '1.25rem',
                      marginRight: '1.25rem',
                    }}></hr>
                  <div>
                    <TextField
                      // value={searchTeam}
                      // onChange={(e) => {
                      //   setSearchTeam(e.target.value);
                      // }}
                      placeholder='Search'
                      variant='outlined'
                      sx={{
                        width: '16.1875rem',
                        height: '2.1875rem',
                        borderRadius: '0.625rem',
                        border: '1px solid #C1C0C0',
                        background: 'var(--Secondary-colors-White, #FFF)',
                        flexShrink: 0,
                        marginRight: '1.25rem',
                        marginLeft: '1.25rem',
                        padding: 0,
                        '& .MuiOutlinedInput-root': {
                          padding: '0 0.5rem',
                          height: '100%',
                          boxSizing: 'border-box',
                        },
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position='start'>
                            <IconButton>
                              <SearchIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                  <div style={{ height: '10rem', overflowY: 'scroll' }}>
                    <List>
                      {recruiters.map((job: any) => (
                        <ListItem key={job.id} sx={{ padding: '0px 0px 0px 16px' }}>
                          <Checkbox
                            checked={selectedJobs.includes(job.id)}
                            // onChange={() => handleSelectJob(job.id)}
                            inputProps={{ 'aria-label': `select job ${job.name}` }}
                          />
                          <Typography variant='body1'>{`${job.firstName} ${job.lastName}`}</Typography>
                        </ListItem>
                      ))}
                    </List>
                    <span className='more'>More...</span>
                  </div>
                </div>
              </Paper>
            </div>
          )}
          <div style={{ width: '79%', height: '100%', flexGrow: 1 }}>
            <div
              style={{
                marginBottom: '-7px',
                marginLeft: '-18px',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}>
              <Tabs
                sx={{ width: '90%' }}
                className='tab'
                value={selectedTab}
                onChange={(_e, v) => {
                  handleTabs(v);
                }}>
                {tabsHeaders.map((header, index) => (
                  <Tab
                    className='selected-tab'
                    sx={{
                      color: 'var(--Body-P, var(--Primary-Dark-grey-500, #333))',
                      fontWeight: 400,
                      fontSize: '1rem',
                      textTransform: 'none',
                    }}
                    key={index}
                    value={header}
                    label={header}
                  />
                ))}
              </Tabs>
              <TextField
                value={searchText}
                onChange={(e) => {
                  setSearchText(e.target.value);
                  handleSearch(e.target.value);
                }}
                placeholder='Search'
                variant='outlined'
                sx={{
                  width: '24%',
                  height: '40px',
                  borderRadius: '0.625rem',
                  border: '1px solid #C1C0C0',
                  background: 'var(--Secondary-colors-White, #FFF)',

                  flexShrink: 0,
                  padding: 0,
                  '& .MuiOutlinedInput-root': {
                    padding: '0 0.5rem',
                    height: '100%',
                    boxSizing: 'border-box',
                  },
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <IconButton>
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <hr style={{ color: '#C1C0C0', margin: 0, marginBottom: '1.63rem' }}></hr>
            <div>
              <TableContainer component={Paper} className='paper'>
                <Table className='sjd-table'>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <Checkbox
                          checked={sjdList.length > 0 && selectedJobs.length === sjdList.length}
                          onChange={handleSelectAll}
                          inputProps={{ 'aria-label': 'select all jobs' }}
                        />
                      </TableCell>
                      {headers
                        .filter((column) => selectedColumns.includes(column.id))
                        .map((column) => (
                          <TableCell key={column.id}>{column.label}</TableCell>
                        ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {paginatedJobs.length > 0 ? (
                      (rowsToDisplay == -1 ? sjdRows : paginatedJobs.slice(0, rowsToDisplay)).map(
                        (job, index) => (
                          <TableRow key={index}>
                            <TableCell>
                              <Checkbox
                                checked={selectedJobs.includes(job.id)}
                                onChange={(event) => handleSelectJob(event, job.id)}
                                inputProps={{ 'aria-label': `select job ${job.id}` }}
                              />
                            </TableCell>

                            {selectedColumns.map((columnId) => {
                              switch (columnId) {
                                case 1:
                                  return (
                                    <TableCell key={columnId}>
                                      <div>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                          <span
                                            style={{
                                              color: '#868686',
                                              fontSize: '0.875rem',
                                              display: 'flex',
                                              fontWeight: 400,
                                            }}>
                                            {`TAD${job.id}`}
                                          </span>
                                          {job.totalCandidates && job.totalCandidates == 0 && (
                                            <Chip
                                              label={'NEW'}
                                              style={{
                                                height: '1.5rem',
                                                marginLeft: '0.5rem',
                                                borderRadius: '0.3125rem',
                                                backgroundColor:
                                                  'var(--Status-Yellow-500, #FBAE19)',
                                                color: '#333333',
                                                fontWeight: '400',
                                                textTransform: 'uppercase',
                                              }}
                                            />
                                          )}
                                        </div>
                                        <Link href={`/job-definition-details/${btoa(job.id)}`}>
                                          {job.name}
                                        </Link>
                                      </div>
                                    </TableCell>
                                  );
                                case 2:
                                  return (
                                    <TableCell key={columnId}>
                                      <Chip
                                        label={getNameFromId(
                                          job.priorityId,
                                          LookupTypes.TAD_PRIORITY,
                                        )}
                                        style={{
                                          borderRadius: '0.3125rem',
                                          // backgroundColor: 'var(--Status-Red-200, #EFA3B2)',
                                          backgroundColor: (() => {
                                            const selectedOption = priorityOptions.find(
                                              (status: any) =>
                                                status.id ===
                                                (job.priorityId !== undefined && job.priorityId),
                                            );
                                            return selectedOption
                                              ? selectedOption.color
                                              : 'transparent';
                                          })(),
                                          color: '#333333',
                                          fontWeight: '400',
                                          textTransform: 'uppercase',
                                        }}
                                      />
                                    </TableCell>
                                  );
                                case 3:
                                  return (
                                    <TableCell key={columnId}>
                                      {formatDate(job.createdOn)}
                                    </TableCell>
                                  );
                                case 4:
                                  return (
                                    <TableCell key={columnId} style={{ color: '#1976D2' }}>
                                      {job.hiringManagers
                                        ?.map(
                                          (hiringManager: any) =>
                                            `${hiringManager.firstName} ${hiringManager.lastName}`,
                                        )
                                        .join(', ')}
                                    </TableCell>
                                  );
                                // case 5:
                                //   return (
                                //     <TableCell
                                //       key={columnId}
                                //       style={{ color: '#1976D2', textDecoration: 'underline' }}>
                                //       {job.candidates > 0 ? (
                                //         job.candidates
                                //       ) : (
                                //         <IconButton>
                                //           <GroupAdd />
                                //         </IconButton>
                                //       )}
                                //     </TableCell>
                                //   );
                                case 5:
                                  return (
                                    <TableCell key={columnId}>
                                      <Avatar
                                        alt='Remy Sharp'
                                        sx={{ width: '3.125rem', height: '3.09619rem' }}
                                        src={require('../../assets/dashboard-assets/profileImage.png')}
                                      />
                                    </TableCell>
                                  );
                                case 6:
                                  return (
                                    <TableCell key={columnId}>
                                      {job.primarySkills
                                        ?.map((primarySkill: any) => primarySkill.skill.name)
                                        .join(', ')}
                                    </TableCell>
                                  );
                                case 7:
                                  return (
                                    <TableCell key={columnId}>
                                      {parseLocation(job.jobLocation).join(', ')}
                                    </TableCell>
                                  );
                                case 8:
                                  return (
                                    <TableCell
                                      key={columnId}
                                      // style={{
                                      //   color: '#1976D2',
                                      //   textDecoration: 'underline',
                                      // }}
                                    >
                                      {job.openPositions}
                                    </TableCell>
                                  );
                                case 9:
                                  return (
                                    <TableCell key={columnId}>
                                      <Select
                                        className='status-dropdown'
                                        sx={{
                                          height: '1.5rem',
                                          minWidth: '120px',
                                          marginRight: '-10px',
                                          backgroundColor: (() => {
                                            const selectedOption = statusOptions.find(
                                              (status: any) =>
                                                status.id ===
                                                (selectedStatusMap[job.id] !== undefined
                                                  ? selectedStatusMap[job.id]
                                                  : job.sjdStatusId),
                                            );
                                            return selectedOption
                                              ? selectedOption.color
                                              : 'transparent';
                                          })(),
                                          '& fieldset': {
                                            border: 'none',
                                          },
                                          alignContent: 'center',
                                        }}
                                        value={
                                          selectedStatusMap[job.id] !== undefined
                                            ? selectedStatusMap[job.id]
                                            : job.sjdStatusId
                                        }
                                        IconComponent={() => (
                                          <DropDownArrow
                                            style={{
                                              marginRight: '5px',
                                              width: '0.9375rem',
                                              height: '0.9375rem',
                                            }}
                                          />
                                        )}
                                        onChange={(e) => {
                                          const selectedValue = parseInt(
                                            e.target.value as string,
                                            10,
                                          );
                                          handleStatusChange(job.id, selectedValue);
                                        }}
                                        renderValue={(selected: any) => {
                                          const option = statusOptions.find(
                                            (status: any) => status.id === selected,
                                          );
                                          if (!option) return null;
                                          return <span>{option.name.toUpperCase()}</span>;
                                        }}>
                                        {statusOptions.map((item: any) => (
                                          <MenuItem
                                            key={item.id}
                                            value={item.id}
                                            onClick={() => sjdActionPatch(job, item.name)}>
                                            <span
                                              style={{
                                                display: 'inline-block',
                                                width: '10px',
                                                height: '10px',
                                                backgroundColor: item.color,
                                                marginRight: '8px',
                                                textTransform: 'none',
                                              }}></span>
                                            {item.name}
                                          </MenuItem>
                                        ))}
                                      </Select>
                                    </TableCell>
                                  );
                                case 10:
                                  return (
                                    <TableCell key={columnId}>
                                      <div>
                                        <IconButton
                                          onClick={(e) => {
                                            setSelectedSjd(job.id);
                                            showActionsList(e);
                                          }}>
                                          <MoreVert />
                                        </IconButton>
                                        <Menu
                                          anchorEl={anchorEl}
                                          open={Boolean(anchorEl)}
                                          onClose={closeActionsMenu}>
                                          {actionsList.map((action, index) => (
                                            <MenuItem
                                              key={index}
                                              onClick={() => {
                                                closeActionsMenu(selectedSjd, action);
                                                if (action.name === 'Put On Hold') {
                                                  sjdActionPatch(selectedSjd, SJD_STATUS.ON_HOLD);
                                                }
                                              }}>
                                              <ListItemIcon>
                                                <img
                                                  src={action.icon}
                                                  alt={action.name}
                                                  style={{ width: '20px', height: '20px' }}
                                                />
                                              </ListItemIcon>
                                              <ListItemText primary={action.name} />
                                            </MenuItem>
                                          ))}
                                        </Menu>
                                      </div>
                                    </TableCell>
                                  );
                              }
                            })}
                          </TableRow>
                        ),
                      )
                    ) : (
                      <TableRow>
                        <TableCell colSpan={headers.length} sx={{ textAlign: 'center' }}>
                          No Records To Display
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginTop: '1%',
              }}>
              <div style={{ textAlign: 'end', width: '50%' }}>
                {rowsToDisplay < sjdRows.length && rowsPerPage !== -1 && (
                  <span
                    onClick={handleLoadMore}
                    style={{ color: '#1976D2', cursor: 'pointer', textDecoration: 'underline' }}>
                    Load more...
                  </span>
                )}
              </div>
              <div style={{ width: '45%', display: 'flex', alignItems: 'center' }}>
                <label htmlFor='rows-per-page' style={{ paddingLeft: '100%' }}>
                  Rows
                </label>
                <Select
                  sx={{
                    alignItems: 'center',
                    '& .MuiSelect-icon': {
                      top: 'calc(50% - 0.3em)',
                    },
                  }}
                  value={rowsPerPage}
                  onChange={handleChangeRowsPerPage}
                  IconComponent={DropDownArrow}
                  style={{ marginLeft: '10px', height: '1.5rem' }}>
                  <MenuItem value={5}>5</MenuItem>
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={25}>25</MenuItem>
                  <MenuItem value={-1}>All</MenuItem>
                </Select>
              </div>
            </div>
          </div>
          {loading && (
            <Backdrop
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={true}>
              <CircularProgress color='inherit' />
            </Backdrop>
          )}
        </div>
      </div>
    </div>
  );
};
export default TadSourceJobDefinition;
