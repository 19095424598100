import { Table, TableBody, TableCell, TableHead, TableRow, Paper } from '@mui/material';
// import { ReactComponent as MoreVertIcon } from '../../../assets/dashboard-assets/more_vert.svg';
import React, { useEffect, useState } from 'react';
import { TadCandidateEducations } from '../../../models/tad-candidate-form-model';
import dayjs from 'dayjs';

interface TadEducationEducationProps {
  education: TadCandidateEducations[];
}

const CandidateEducationTable: React.FC<TadEducationEducationProps> = ({ education }) => {
  const [selectedUsers, setSelectedUsers] = useState<TadCandidateEducations[]>([]);

  useEffect(() => {
    console.log('Received education data:', education);

    if (Array.isArray(education) && education.length > 0) {
      setSelectedUsers(education);
    } else {
      console.warn('No education data available or it is empty.');
      setSelectedUsers([]);
    }
  }, [education]);

  return (
    <Paper style={{ height: '26.125rem', width: '100%' }}>
      <div
        style={{
          height: '61px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          backgroundColor: 'var(--Secondary-Grey-2, #E7E7E7)',
        }}>
        <div style={{ display: 'flex' }}>
          <span
            className='heading'
            style={{ height: '24px', fontSize: '18px', marginLeft: '1.81rem' }}>
            Education
          </span>
        </div>
        {/* <div style={{ display: 'flex', alignItems: 'center' }}>
          <MoreVertIcon style={{ marginRight: '10px' }} />
        </div> */}
      </div>

      <div className='qc-table' style={{ marginLeft: '2.02rem', marginRight: '1.25rem' }}>
        <Table>
          <TableHead style={{ borderBottom: '0.125rem solid #C1C0C0' }}>
            <TableRow>
              <TableCell>Institution & Degree</TableCell>
              <TableCell>Specialization</TableCell>
              <TableCell>Start Date</TableCell>
              <TableCell>End Date</TableCell>
              <TableCell>Grade</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {selectedUsers.map((item, index) => (
              <TableRow key={index} style={{ backgroundColor: 'transparent' }}>
                <TableCell>
                  <span style={{ color: '#868686', fontSize: '0.875rem', fontWeight: '400' }}>
                    {item.institution || 'N/A'}
                  </span>
                  <br />
                  <span
                    style={{
                      textDecoration: 'underline',
                      fontSize: '1rem',
                      fontWeight: 600,
                      color: '#1976D2',
                    }}>
                    {item.degreeReceived || 'N/A'}
                  </span>
                </TableCell>
                <TableCell>
                  <span style={{ color: '#868686', fontSize: '0.875rem', fontWeight: '400' }}>
                    {item.specialization !== undefined ? item.specialization : 'N/A'}
                  </span>
                </TableCell>
                <TableCell>
                  <span style={{ color: '#868686', fontSize: '0.875rem', fontWeight: '400' }}>
                    {item.startDate ? dayjs(item.startDate).format('DD/MM/YYYY hh:mm A') : 'N/A'}
                  </span>
                </TableCell>
                <TableCell>
                  <span style={{ color: '#868686', fontSize: '0.875rem', fontWeight: '400' }}>
                    {item.endDate ? dayjs(item.endDate).format('DD/MM/YYYY hh:mm A') : 'N/A'}
                  </span>
                </TableCell>
                <TableCell>
                  <span style={{ color: '#868686', fontSize: '0.875rem', fontWeight: '400' }}>
                    {item.grade || 'N/A'}
                  </span>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </Paper>
  );
};

export default CandidateEducationTable;
