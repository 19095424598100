import React, { useEffect, useState } from 'react';
import SjdCandidateListTable from './sjd-candidate-list-table';
import {
  IconButton,
  InputAdornment,
  TextField,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  // Select,
} from '@mui/material';
import { ReactComponent as SearchIcon } from '../../../assets/candidate-assets/search.svg';
import { ReactComponent as SortIcon } from '../../../assets/candidate-assets/sort_by_alpha.svg';
import { ReactComponent as ExportIcon } from '../../../assets/candidate-assets/save_alt.svg';
import { ReactComponent as RemoveIcon } from '../../../assets/candidate-assets/Group.svg';
import { ReactComponent as WidgetsIcon } from '../../../assets/candidate-assets/widgets.svg';
import { ReactComponent as FormatListIcon } from '../../../assets/candidate-assets/format_list_bulleted.svg';
import TadTableFilter from '../../common/tad-table-filter';
// import { ReactComponent as MoreVertIcon } from '../../../assets/dashboard-assets/more_vert.svg';
import { tadGetCandidatesForSjd } from '../../../service/tadCandidateService';
import { LookupTypes } from '../../../../common/lookupTypes';
import { getIdFromName } from '../../../../common/commonUtil';

interface Props {
  sjd: any;
}

const SjdCandidateTable = ({ sjd }: Props) => {
  const [searchTeam, setSearchTeam] = useState('');
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [tabIconChange, setTabIconChange] = useState(false);
  const [tabListIconChange, setTabListIconChange] = useState(true);
  const [candidates, setCandidates] = useState([] as any[]);

  const profileRated = candidates.filter(
    (candidate: any) =>
      candidate?.sjdCandidateInfo[0]?.candidateStatusId ===
      getIdFromName('Profile Uploaded', LookupTypes.CANDIDATE_STATUS),
  );

  const profiling = candidates.filter((candidate: any) => {
    const candidateStatusId = candidate?.sjdCandidateInfo[0]?.candidateStatusId;
    return (
      candidateStatusId === getIdFromName('QC Rated', LookupTypes.CANDIDATE_STATUS) ||
      candidateStatusId === getIdFromName('Profiler Rated', LookupTypes.CANDIDATE_STATUS)
    );
  });
  const interviews = candidates.filter((candidate: any) => {
    const candidateStatusId = candidate?.sjdCandidateInfo[0]?.candidateStatusId;
    return (
      candidateStatusId === getIdFromName('Online Test Scheduled', LookupTypes.CANDIDATE_STATUS) ||
      candidateStatusId === getIdFromName('Online Test cleared', LookupTypes.CANDIDATE_STATUS) ||
      candidateStatusId ===
        getIdFromName('Tech Interview-1 Scheduled', LookupTypes.CANDIDATE_STATUS) ||
      candidateStatusId ===
        getIdFromName('Tech Interview-1 cleared', LookupTypes.CANDIDATE_STATUS) ||
      candidateStatusId ===
        getIdFromName('Tech Interview-2 Scheduled', LookupTypes.CANDIDATE_STATUS) ||
      candidateStatusId ===
        getIdFromName('Tech Interview-2 cleared', LookupTypes.CANDIDATE_STATUS) ||
      candidateStatusId ===
        getIdFromName('Tech Interview-3 Scheduled', LookupTypes.CANDIDATE_STATUS) ||
      candidateStatusId ===
        getIdFromName('Tech Interview-3 cleared', LookupTypes.CANDIDATE_STATUS) ||
      candidateStatusId ===
        getIdFromName('Tech Interview-4 Scheduled', LookupTypes.CANDIDATE_STATUS) ||
      candidateStatusId ===
        getIdFromName('Tech Interview-4 cleared', LookupTypes.CANDIDATE_STATUS) ||
      candidateStatusId ===
        getIdFromName('Tech Interview-5 Scheduled', LookupTypes.CANDIDATE_STATUS) ||
      candidateStatusId ===
        getIdFromName('Tech Interview-5 cleared', LookupTypes.CANDIDATE_STATUS) ||
      candidateStatusId ===
        getIdFromName('Client Interview Scheduled', LookupTypes.CANDIDATE_STATUS) ||
      candidateStatusId === getIdFromName('Client Interview Cleared', LookupTypes.CANDIDATE_STATUS)
    );
  });
  const hrDiscussion = candidates.filter((candidate: any) => {
    const candidateStatusId = candidate?.sjdCandidateInfo[0]?.candidateStatusId;
    return (
      candidateStatusId === getIdFromName('HR Interview Scheduled', LookupTypes.CANDIDATE_STATUS) ||
      candidateStatusId === getIdFromName('HR Interview Cleared', LookupTypes.CANDIDATE_STATUS)
    );
  });
  const documentVerification = candidates.filter((candidate: any) => {
    const candidateStatusId = candidate?.sjdCandidateInfo[0]?.candidateStatusId;
    return (
      candidateStatusId ===
        getIdFromName('Document Verification Started', LookupTypes.CANDIDATE_STATUS) ||
      candidateStatusId ===
        getIdFromName('Document Verification Completed', LookupTypes.CANDIDATE_STATUS)
    );
  });
  const offer = candidates.filter((candidate: any) => {
    const candidateStatusId = candidate?.sjdCandidateInfo[0]?.candidateStatusId;
    return (
      candidateStatusId === getIdFromName('Offer approved', LookupTypes.CANDIDATE_STATUS) ||
      candidateStatusId === getIdFromName('Offer released', LookupTypes.CANDIDATE_STATUS) ||
      candidateStatusId === getIdFromName('Offer accepted', LookupTypes.CANDIDATE_STATUS)
    );
  });
  const joining = candidates.filter((candidate: any) => {
    const candidateStatusId = candidate?.sjdCandidateInfo[0]?.candidateStatusId;
    return candidateStatusId === getIdFromName('Joined', LookupTypes.CANDIDATE_STATUS);
  });

  const widgetHeaders = [
    { id: 1, label: `Profile Uploaded (${profileRated.length})` },
    { id: 2, label: `Profiling (${profiling.length})` },
    { id: 3, label: `Interviews Scheduled (${interviews.length})` },
    { id: 4, label: `HR Discussion (${hrDiscussion.length})` },
    { id: 5, label: `Document Verification (${documentVerification.length})` },
    { id: 6, label: `Offer (${offer.length})` },
    { id: 7, label: `Joining (${joining.length})` },
  ];
  const listHeaders = [
    { id: 1, label: 'Candidate' },
    { id: 2, label: 'Location & Mobile' },
    { id: 3, label: 'PR Rating & Score' },
    { id: 4, label: 'Experience & Notice' },
    { id: 5, label: 'Skills & Salary' },
    { id: 6, label: 'Status' },
    { id: 7, label: 'Actions' },
  ];

  const [selectedColumns, setSelectedColumns] = useState<number[]>(
    (tabListIconChange ? widgetHeaders : listHeaders).map((header: any) => header.id),
  );
  const actionList = [
    { name: 'Sort By', icon: SortIcon },
    { name: 'Export to Excel', icon: ExportIcon },
    { name: 'Remove all Candidates', icon: RemoveIcon },
  ];

  useEffect(() => {
    tadGetCandidatesForSjd(sjd)
      .then((res) => setCandidates(res.data))
      .catch((_err) => {});
  }, []);

  console.log(candidates);

  useEffect(() => {
    tadGetCandidatesForSjd(sjd)
      .then((res) => setCandidates(res.data))
      .catch((_err) => {});
  }, []);

  console.log(candidates);

  // const showActionsList = (event: React.MouseEvent<HTMLElement>) => {
  //   setAnchorEl(event.currentTarget);
  // };
  const closeActionsMenu = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <hr style={{ color: '#C1C0C0', margin: 0, marginBottom: '1.63rem' }}></hr>
      <div style={{ display: 'flex', alignItems: 'baseline', justifyContent: 'space-between' }}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
          }}>
          <div
            style={{
              borderRadius: '0.125rem',
              display: 'flex',
              width: ' var(--Font-size-h3, 2.0625rem)',
              height: '1.875rem',
              background: !tabIconChange ? 'var(--Primary-Blue-500, #1976D2)' : '',
              marginRight: '0.5rem',
              justifyContent: 'center',
            }}
            onClick={() => {
              setTabIconChange(false);
              setTabListIconChange(true);
            }}>
            <IconButton>
              <WidgetsIcon style={{ color: !tabIconChange ? 'white' : 'black' }} />
            </IconButton>
          </div>
          <div
            style={{
              justifyContent: 'center',
              display: 'flex',
              borderRadius: '0.125rem',
              width: ' var(--Font-size-h3, 2.0625rem)',
              height: '1.875rem',
              background: !tabListIconChange ? 'var(--Primary-Blue-500, #1976D2)' : '',
            }}
            onClick={() => {
              setTabListIconChange(false);
              setTabIconChange(true);
            }}>
            <IconButton>
              <FormatListIcon style={{ color: !tabListIconChange ? 'white' : 'black' }} />
            </IconButton>
          </div>
        </div>
        <div style={{ display: 'flex', marginBottom: '30px' }}>
          <TextField
            value={searchTeam}
            onChange={(e) => {
              setSearchTeam(e.target.value);
            }}
            placeholder='Search'
            variant='outlined'
            sx={{
              width: '16.1875rem',
              height: '2.1875rem',
              borderRadius: '0.625rem',
              border: '1px solid #C1C0C0',
              background: 'var(--Secondary-colors-White, #FFF)',
              flexShrink: 0,
              marginRight: '1.25rem',
              marginLeft: '1.25rem',
              padding: 0,
              '& .MuiOutlinedInput-root': {
                padding: '0 0.5rem',
                height: '100%',
                boxSizing: 'border-box',
              },
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <IconButton>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          {/* <div style={{ display: 'flex' }}>
            <Select
              className='inputFeilds'
              sx={{
                width: '7.9375rem',
                height: 'var(--Font-size-h2, 2.5rem)',
                marginRight: '0.81rem',
                marginLeft: '26.61px',
              }}
              size='small'
              fullWidth
              labelId='domain'
              name='domain'
              id='demo-simple-select'>
              <MenuItem key={0}>Filter By</MenuItem>
              <MenuItem>Select</MenuItem>
              <MenuItem>Select</MenuItem>
            </Select>
          </div> */}
          <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
            <TadTableFilter
              headers={tabListIconChange ? widgetHeaders : listHeaders}
              selectedHeaders={(value) => setSelectedColumns(value)}
            />
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {/* <Select
              className='inputFeilds'
              sx={{
                width: '7.9375rem',
                height: 'var(--Font-size-h2, 2.5rem)',
                marginRight: '0.81rem',
                marginLeft: '26.61px',
              }}
              size='small'
              fullWidth
              labelId='domain'
              name='domain'
              id='demo-simple-select'>
              <MenuItem key={0}>Sort By</MenuItem>
              <MenuItem>Select</MenuItem>
              <MenuItem>Select</MenuItem>
            </Select> */}
            {/* <TadTableSorting
              data={sjdcandidateData}
              sortingData={(value) => {
                setsjdCandidateData(value);
              }}
            /> */}
            {/* <IconButton onClick={showActionsList} style={{ marginLeft: '10px' }}>
              <MoreVertIcon />
            </IconButton> */}
            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={closeActionsMenu}>
              {actionList.map((action, index) => (
                <MenuItem key={index} onClick={() => closeActionsMenu()}>
                  <ListItemIcon>
                    <action.icon style={{ width: '20px', height: '20px' }} />
                  </ListItemIcon>
                  <ListItemText primary={action.name} />
                </MenuItem>
              ))}
            </Menu>
          </div>
        </div>
      </div>

      <div>
        <SjdCandidateListTable
          tabIconChange={tabIconChange}
          tabListIconChange={tabListIconChange}
          headers={tabListIconChange ? widgetHeaders : listHeaders}
          selectedColumns={selectedColumns}
          candidates={candidates}
        />
      </div>
    </div>
  );
};

export default SjdCandidateTable;
