import React, { useEffect, useState } from 'react';
import {
  Paper,
  Typography,
  TextField,
  Button,
  IconButton,
  Autocomplete,
  MenuItem,
  Select,
  Radio,
  RadioGroup,
  FormControlLabel,
  Chip,
} from '@mui/material';
import Avatar from '@mui/material/Avatar';
import { FormikProps, useFormik } from 'formik';
import * as Yup from 'yup';
// import { getTadSkills } from '../../../../service/lookupService';
import { LookupTypes } from '../../../../common/lookupTypes';
import { ReactComponent as MoreIcon } from '../../../assets/dashboard-assets/more_vert.svg';
import countries from 'i18n-iso-countries';
import enLocale from 'i18n-iso-countries/langs/en.json';
import { getNameFromId } from '../../../../common/commonUtil';

import '../../source-job-definition/job-definition-details.scss';

import { TadCandidateDetailsModel } from '../../../models/tad-candidate-form-model';

import { getAllUsers } from '../../../../service/userService';
import CountryCodes from '../../common/country-codes';
import globalObject from '../../../../common/global-variables';
import { getLabel } from '../../../common/commonUtil';
import { DatePicker } from '@mui/x-date-pickers';
import dateFormat from 'dateformat';
import dayjs from 'dayjs';
import { ReactComponent as ContentCopy } from '../../../assets/candidate-assets/content_copy.svg';
import { getTadSkills } from '../../../../service/lookupService';
import { ReactComponent as AddIcon } from '../../../assets/sjd/add.svg';
import { ReactComponent as DoneIcon } from '../../../assets/sjd/done.svg';

countries.registerLocale(enLocale);

const countryObj = countries.getNames('en', { select: 'official' });

interface CandidateDetailsProps {
  values: TadCandidateDetailsModel;
  previous?: () => void;
  next: (value: TadCandidateDetailsModel) => void;
  mode?: 'edit-page' | 'details-page';
}

interface OptionType {
  id: number;
  name: string;
}

const CandidateDetails: React.FC<CandidateDetailsProps> = ({
  previous = () => {},
  mode = 'edit-page',
  next,
  values,
}: CandidateDetailsProps) => {
  const [submitted, setSubmitted] = useState(false);
  const [edit, setEdit] = useState(mode === 'edit-page');
  const [selectedFullTimeFlag, setSelectedFullTimeFlag] = useState('IN');
  const [referres, setReferrers] = useState([] as any[]);
  const [skills, setSkills] = useState([] as any[]);

  const requisitionSchema = Yup.object().shape({
    name: Yup.string()
      .max(1000, 'Name should not exceed 1000 characters')
      .required('Name is required'),
    email: Yup.string()
      .max(1000, 'Email should not exceed 1000 characters')
      .required('Email is required'),
    phoneNumber: Yup.string()
      .max(1000, 'Phone number should not exceed 1000 characters')
      .required('Phone number is required'),
    gender: Yup.number()
      .integer('The value must be a number')
      .typeError('The value must be a number')
      .min(0, 'Value must be positive')
      .required('Gender is required'),
    domain: Yup.number()
      .integer('The value must be a number')
      .typeError('The value must be a number')
      .min(0, 'Value must be positive')
      .required('Domain is required'),
    skills: Yup.array().of(Yup.object()).min(1, 'Primary skills are required').required(),
    linkedInUrl: Yup.string()
      .max(1000, 'LinkedIn URL should not exceed 1000 characters')
      .required('LinkedIn URL is required'),
  });

  const formik = useFormik({
    initialValues: {
      id: values?.id,
      name: values?.name,
      email: values?.email,
      phoneNumber: values?.phoneNumber,
      gender: values?.gender,
      nationality: values?.nationality,
      location: values?.location,
      domain: values?.domain,
      skills: values?.skills,
      currentRole: values?.currentRole,
      referredBy: values?.referredBy,
      linkedInUrl: values?.linkedInUrl,
      currentCtc: values?.currentCtc,
      currentCtcFrequency: values?.currentCtcFrequency,
      expectedCtc: values?.expectedCtc,
      sponsorshipRequired: values?.sponsorshipRequired,
      expectedCtcFrequency: values?.expectedCtcFrequency,
      visaType: values?.visaType,
      visaStatus: values?.visaStatus,
      visaValidity: values?.visaValidity,
    },
    onSubmit: (values) => {
      next(values);
      setSubmitted(false);
    },
    validationSchema: requisitionSchema,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  useEffect(() => {
    getAllUsers().then((res) => setReferrers(res.data));
    getTadSkills().then((res) => setSkills(res.data));
  }, []);

  function getError(formik: FormikProps<any>, fieldName: string) {
    if ((submitted || formik.touched[fieldName]) && formik.errors[fieldName]) {
      return formik.errors[fieldName]?.toString();
    }
    return null;
  }

  const countryOptions = Object.entries(countryObj).map(([code, name]) => ({
    value: code,
    label: name,
  }));

  const handleSkillToggle = (type: string, skill: any) => {
    const currentSkills: any = formik.values.skills;
    const isSelected = currentSkills.some((s: any) => s.id === skill.id);
    if (isSelected) {
      formik.setFieldValue(
        type,
        currentSkills.filter((s: any) => s.id !== skill.id),
      );
    } else {
      formik.setFieldValue(type, [...currentSkills, skill]);
    }
  };

  return (
    <div style={{ height: '100%', width: '100%' }}>
      <Paper className='paper' sx={{ width: '100%' }}>
        {mode === 'details-page' && (
          <div className='header-container'>
            <div className='header-content-title'>
              <span>Candidate Details</span>
            </div>
            <div className='header-content-icon'>
              <IconButton onClick={() => (edit == false ? setEdit(true) : setEdit(false))}>
                <MoreIcon />
              </IconButton>
            </div>
          </div>
        )}

        <div style={{ padding: '2.69rem 3rem 2.19rem 3rem' }}>
          <div className='field-container'>
            {mode === 'details-page' && (
              <div style={{ width: '35%' }}>
                <Typography sx={{ fontWeight: 600 }}>ID</Typography>
              </div>
            )}
            {mode === 'details-page' && (
              <span>
                {`TAD${formik.values.id}`}
                <ContentCopy
                  onClick={() => navigator.clipboard.writeText(String(`TAD${formik.values.id}`))}
                  style={{
                    cursor: 'pointer',
                    marginLeft: '12px',
                    height: '20px',
                    width: '20px',
                    color: 'grey',
                  }}
                />
              </span>
            )}
          </div>

          <div className='field-container'>
            {mode === 'edit-page' ? (
              <div style={{ width: '50%' }}>
                <Typography sx={{ fontWeight: 600 }}>Candidate Full Name *</Typography>
                <span className='feildHeader'>Provide the candidates full legal name</span>
              </div>
            ) : (
              <div style={{ width: '35%' }}>
                <Typography sx={{ fontWeight: 600 }}>Candidate Full Name</Typography>
              </div>
            )}

            {edit ? (
              <div style={{ width: '50%' }}>
                <TextField
                  className='input-field'
                  fullWidth
                  value={formik.values.name}
                  onChange={(e) => formik.setFieldValue('name', e.target.value)}
                  error={Boolean(formik.errors.name && formik.touched.name)}
                />
                <div className='mt-0 form-err-message'>{getError(formik, 'name')}</div>
              </div>
            ) : (
              <span>{formik.values.name}</span>
            )}
          </div>
          <div className='field-container'>
            {mode === 'edit-page' ? (
              <div style={{ width: '50%' }}>
                <Typography sx={{ fontWeight: 600 }}>Candidate Email *</Typography>
                <span className='feildHeader'>Provide the candidates email address</span>
              </div>
            ) : (
              <div style={{ width: '35%' }}>
                <Typography sx={{ fontWeight: 600 }}>Candidate Email</Typography>
              </div>
            )}

            {edit ? (
              <div style={{ width: '50%' }}>
                <TextField
                  className='input-field'
                  fullWidth
                  value={formik.values.email}
                  onChange={(e) => formik.setFieldValue('email', e.target.value)}
                  error={Boolean(formik.errors.email && formik.touched.email)}
                />
                <div className='mt-0 form-err-message'>{getError(formik, 'email')}</div>
              </div>
            ) : (
              <span
                style={{
                  color: 'var(--Hyperlink, var(--Primary-Blue-500, #1976D2))',
                  textDecoration: 'underline',
                }}>
                {formik.values.email}
              </span>
            )}
          </div>
          <div className='field-container'>
            {mode === 'edit-page' ? (
              <div style={{ width: '50%' }}>
                <Typography sx={{ fontWeight: 600 }}>Candidate Phone *</Typography>
                <span className='feildHeader'>Provide the candidates primary phone number</span>
              </div>
            ) : (
              <div style={{ width: '35%' }}>
                <Typography sx={{ fontWeight: 600 }}>Candidate Phone: </Typography>
              </div>
            )}

            <div style={{ width: '50%' }}>
              <div style={{ display: 'flex', width: '100%' }}>
                {edit ? (
                  <CountryCodes
                    selectedCountry={selectedFullTimeFlag}
                    setSelectedCountry={(value) => setSelectedFullTimeFlag(value)}
                  />
                ) : (
                  <span
                    style={{
                      color: 'var(--Hyperlink, var(--Primary-Blue-500, #1976D2))',
                      textDecoration: 'underline',
                    }}>
                    {selectedFullTimeFlag}
                  </span>
                )}

                {edit ? (
                  <TextField
                    className='salaryType'
                    style={{ borderRadius: '0px 0px 0px 0px' }}
                    value={formik.values.phoneNumber?.replace(/\D/g, '') || ''}
                    onChange={(e) =>
                      formik.setFieldValue(
                        'phoneNumber',
                        `${selectedFullTimeFlag} ${e.target.value}`,
                      )
                    }
                    error={Boolean(formik.errors.phoneNumber && formik.touched.phoneNumber)}
                    fullWidth
                    id='currentSalary'
                    size='small'
                    name='payFullTimeRate'
                    placeholder='8547995556'
                  />
                ) : (
                  <span
                    style={{
                      color: 'var(--Hyperlink, var(--Primary-Blue-500, #1976D2))',
                      textDecoration: 'underline',
                    }}>
                    {formik.values.phoneNumber}
                  </span>
                )}
              </div>
              <div className='mt-0 form-err-message'>{getError(formik, 'phoneNumber')}</div>
            </div>
          </div>
          <div className='field-container'>
            {mode === 'edit-page' ? (
              <div style={{ width: '50%' }}>
                <Typography sx={{ fontWeight: 600 }}>Gender *</Typography>
              </div>
            ) : (
              <div style={{ width: '35%' }}>
                <Typography sx={{ fontWeight: 600 }}>Gender: </Typography>
              </div>
            )}
            {edit ? (
              <div style={{ width: '50%' }}>
                <Autocomplete
                  fullWidth
                  className='visaType'
                  id='combo-box-demo'
                  options={globalObject.lookupvalues[LookupTypes.TAD_GENDER] as OptionType[]}
                  value={
                    formik.values.gender
                      ? globalObject.lookupvalues[LookupTypes.TAD_GENDER].find(
                          (option: OptionType) => option.id === formik.values.gender,
                        )
                      : null
                  }
                  size='small'
                  sx={{
                    background: 'var(--Secondary-colors-White, #FFF)',
                    border: '1px solid var(--Secondary-colors-Grey, #868686)',
                    borderRadius: '0.3125rem',
                  }}
                  limitTags={1}
                  getOptionLabel={(option: any) => option.name}
                  filterSelectedOptions
                  onBlur={() => formik.setFieldTouched('gender', true, true)}
                  renderInput={(params) => (
                    <TextField
                      placeholder='Choose...'
                      {...params}
                      error={Boolean(formik.errors.gender && formik.touched.gender)}
                      label=''
                    />
                  )}
                  onChange={(_e, val) => {
                    formik.setFieldValue('gender', val ? val.id : undefined);
                  }}
                />

                <div className='mt-0 form-err-message'>{getError(formik, 'gender')}</div>
              </div>
            ) : (
              <span>{getNameFromId(formik.values.gender, LookupTypes.TAD_GENDER)}</span>
            )}
          </div>
          <div className='field-container'>
            {mode === 'edit-page' ? (
              <div style={{ width: '50%' }}>
                <Typography sx={{ fontWeight: 600 }}>Nationality</Typography>
              </div>
            ) : (
              <div style={{ width: '35%' }}>
                <Typography sx={{ fontWeight: 600 }}>Nationality: </Typography>
              </div>
            )}
            {edit ? (
              <div style={{ width: '50%' }}>
                <Autocomplete
                  fullWidth
                  className='input-field'
                  id='combo-box-demo'
                  options={countryOptions}
                  value={
                    formik.values.nationality
                      ? countryOptions.find(
                          (country) => country.value === formik.values.nationality,
                        ) || null
                      : null
                  }
                  size='small'
                  limitTags={1}
                  getOptionLabel={(option: any) => option.label}
                  filterSelectedOptions
                  onBlur={() => formik.setFieldTouched('nationality', true, true)}
                  renderInput={(params) => (
                    <TextField
                      placeholder='Choose...'
                      {...params}
                      error={Boolean(formik.errors.nationality)}
                      label=''
                    />
                  )}
                  onChange={(e, val) => {
                    console.log(val, e);
                    formik.setFieldValue('nationality', val ? val.value : undefined);
                  }}
                />
                <div className='mt-0 form-err-message'>{getError(formik, 'nationality')}</div>
              </div>
            ) : (
              <span>
                {
                  countryOptions.find((country) => country.value === formik.values.nationality)
                    ?.value
                }
              </span>
            )}
          </div>
          <div className='field-container'>
            {mode === 'edit-page' ? (
              <div style={{ width: '50%' }}>
                <Typography sx={{ fontWeight: 600 }}>Address</Typography>
                <span className='feildHeader'>Provide the candidates address</span>
              </div>
            ) : (
              <div style={{ width: '35%' }}>
                <Typography sx={{ fontWeight: 600 }}>Address</Typography>
              </div>
            )}

            {edit ? (
              <div style={{ width: '50%' }}>
                <TextField
                  className='input-field'
                  fullWidth
                  value={formik.values.location || ''}
                  onChange={(e) => formik.setFieldValue('location', e.target.value)}
                  error={Boolean(formik.errors.location && formik.touched.location)}></TextField>
                <div className='mt-0 form-err-message'>{getError(formik, 'location')}</div>
              </div>
            ) : (
              <span>{getLabel(formik.values.location)}</span>
            )}
          </div>
          <div style={{ marginBottom: '1.87rem' }}>
            <hr style={{ background: '#868686' }} />
          </div>
          <div className='field-container'>
            {mode === 'edit-page' ? (
              <div style={{ width: '50%' }}>
                <Typography sx={{ fontWeight: 600 }}>Domain *</Typography>
              </div>
            ) : (
              <div style={{ width: '35%' }}>
                <Typography sx={{ fontWeight: 600 }}>Domain: </Typography>
              </div>
            )}

            {edit ? (
              <div style={{ width: '50%' }}>
                <Autocomplete
                  fullWidth
                  className='visaType'
                  id='combo-box-demo'
                  options={globalObject.lookupvalues[LookupTypes.TAD_DOMAIN] as OptionType[]}
                  value={
                    formik.values.domain
                      ? globalObject.lookupvalues[LookupTypes.TAD_DOMAIN].find(
                          (option: OptionType) => option.id === formik.values.domain,
                        )
                      : null
                  }
                  size='small'
                  sx={{
                    background: 'var(--Secondary-colors-White, #FFF)',
                    border: '1px solid var(--Secondary-colors-Grey, #868686)',
                    borderRadius: '0.3125rem',
                  }}
                  limitTags={1}
                  getOptionLabel={(option: any) => option.name}
                  filterSelectedOptions
                  onBlur={() => formik.setFieldTouched('domain', true, true)}
                  renderInput={(params) => (
                    <TextField
                      placeholder='Choose...'
                      {...params}
                      error={Boolean(formik.errors.domain && formik.touched.domain)}
                      label=''
                    />
                  )}
                  onChange={(_e, val) => {
                    formik.setFieldValue('domain', val ? val.id : undefined);
                  }}
                />
                <div className='mt-0 form-err-message'>{getError(formik, 'domain')}</div>
              </div>
            ) : (
              <span>{getNameFromId(formik.values.domain, LookupTypes.TAD_DOMAIN)}</span>
            )}
          </div>
          <div
            className='field-container'
            style={{ justifyContent: mode == 'details-page' ? 'flex-start' : 'space-between' }}>
            {mode === 'edit-page' ? (
              <div style={{ width: '50%' }}>
                <Typography sx={{ fontWeight: 600 }}>Primary Skills *</Typography>
                <span className='feildHeader'>Add candidate primary skills</span>
              </div>
            ) : (
              <div style={{ width: '35%' }}>
                <Typography sx={{ fontWeight: 600 }}>Primary Skills: </Typography>
              </div>
            )}
            {edit ? (
              <div style={{ width: '50%' }}>
                <div className='input-field' style={{ marginBottom: '0.62rem' }}>
                  <Autocomplete
                    id='combo-box-demo'
                    fullWidth
                    options={skills}
                    value={formik.values.skills || []}
                    size='small'
                    limitTags={1}
                    getOptionLabel={(option: any) => option.name}
                    filterSelectedOptions
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    renderInput={(params) => (
                      <TextField
                        placeholder='Enter a skill'
                        {...params}
                        error={Boolean(formik.errors.skills && formik.touched.skills)}
                        label=''
                      />
                    )}
                    onChange={(_e, val) => {
                      formik.setFieldValue('skills', val ? val : undefined);
                    }}
                    multiple
                  />
                </div>
                <div className='mt-0 form-err-message'>{getError(formik, 'skills')}</div>
                <div style={{ width: '100%' }}>
                  <Typography sx={{ fontWeight: 600 }}>Skill Suggestions</Typography>
                  {skills.slice(0, 7).map((skill) => (
                    <Chip
                      key={skill.id}
                      label={skill.name}
                      style={{
                        backgroundColor: '#D9D9D9',
                        borderRadius: '0.63rem',
                        marginRight: '0.63rem',
                        marginBottom: '0.63rem',
                      }}
                      avatar={
                        formik.values?.skills?.some((s: any) => s.id === skill.id) ? (
                          <DoneIcon />
                        ) : (
                          <AddIcon />
                        )
                      }
                      onClick={() => handleSkillToggle('skills', skill)}
                    />
                  ))}
                </div>
              </div>
            ) : (
              <span>{formik.values.skills?.map((e) => e.name).join(', ')}</span>
            )}
          </div>

          <div className='field-container'>
            {mode === 'edit-page' ? (
              <div style={{ width: '50%' }}>
                <Typography sx={{ fontWeight: 600 }}>Current Role</Typography>
                <span className='feildHeader'>Provide the candidates current role</span>
              </div>
            ) : (
              <div style={{ width: '35%' }}>
                <Typography sx={{ fontWeight: 600 }}>Current Role</Typography>
              </div>
            )}

            {edit ? (
              <div style={{ width: '50%' }}>
                <TextField
                  className='input-field'
                  fullWidth
                  value={formik.values.currentRole || ''}
                  onChange={(e) => formik.setFieldValue('currentRole', e.target.value)}
                  error={Boolean(formik.errors.currentRole && formik.touched.currentRole)}
                />
                <div className='mt-0 form-err-message'>{getError(formik, 'currentRole')}</div>
              </div>
            ) : (
              <span>{getLabel(formik.values.currentRole)}</span>
            )}
          </div>
          <>
            <div className='field-container'>
              {mode === 'edit-page' ? (
                <div style={{ width: '50%' }}>
                  <Typography sx={{ fontWeight: 600 }}>Referred By</Typography>
                  <span className='feildHeader'>Provide the referral employee details</span>
                </div>
              ) : (
                <div style={{ width: '35%' }}>
                  <Typography sx={{ fontWeight: 600 }}>Referred By</Typography>
                </div>
              )}

              <div style={{ width: '50%' }}>
                {edit ? (
                  <Autocomplete
                    fullWidth
                    className='visaType'
                    id='combo-box-demo'
                    options={referres}
                    value={formik.values.referredBy}
                    size='small'
                    sx={{
                      background: 'var(--Secondary-colors-White, #FFF)',
                      border: '1px solid var(--Secondary-colors-Grey, #868686)',
                      borderRadius: '0.3125rem',
                    }}
                    limitTags={1}
                    getOptionLabel={(option) =>
                      option.email || `${option.firstName} ${option.lastName}`
                    } // Show name or email
                    filterSelectedOptions
                    onBlur={() => formik.setFieldTouched('referredBy', true, true)}
                    renderInput={(params) => (
                      <TextField
                        placeholder='Employee Email'
                        {...params}
                        error={Boolean(formik.errors.referredBy && formik.touched.referredBy)}
                        label=''
                      />
                    )}
                    onChange={(_e, val) => {
                      formik.setFieldValue('referredBy', val ? val : undefined);
                    }}
                  />
                ) : (
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Avatar alt='Candidate Photo' style={{ marginRight: 8 }} />
                    <span
                      style={{
                        color: 'var(--Hyperlink, var(--Primary-Blue-500, #1976D2))',
                        textDecoration: 'underline',
                      }}>
                      {typeof formik.values.referredBy === 'object' &&
                      formik.values.referredBy !== null
                        ? `${formik.values.referredBy.firstName} ${formik.values.referredBy.lastName}`
                        : 'No referral'}
                    </span>
                  </div>
                )}
                <div className='mt-0 form-err-message'>{getError(formik, 'referredBy')}</div>
              </div>
            </div>
          </>

          <div className='field-container'>
            {mode === 'edit-page' ? (
              <div style={{ width: '50%' }}>
                <Typography sx={{ fontWeight: 600 }}>LinkedIn URL *</Typography>
              </div>
            ) : (
              <div style={{ width: '35%' }}>
                <Typography sx={{ fontWeight: 600 }}>LinkedIn URL</Typography>
              </div>
            )}

            {edit ? (
              <div style={{ width: '50%' }}>
                <TextField
                  className='input-field'
                  fullWidth
                  value={formik.values.linkedInUrl || ''}
                  onChange={(e) => formik.setFieldValue('linkedInUrl', e.target.value)}
                  error={Boolean(formik.errors.linkedInUrl && formik.touched.linkedInUrl)}
                  placeholder='Paste here...'
                />
                <div className='mt-0 form-err-message'>{getError(formik, 'linkedInUrl')}</div>
              </div>
            ) : (
              <span
                style={{
                  color: 'var(--Hyperlink, var(--Primary-Blue-500, #1976D2))',
                  textDecoration: 'underline',
                }}>
                {getLabel(formik.values.linkedInUrl)}
              </span>
            )}
          </div>
          <div style={{ marginBottom: '1.87rem' }}>
            <hr style={{ background: '#868686' }} />
          </div>
          <div className='field-container'>
            {mode === 'edit-page' ? (
              <div style={{ width: '50%' }}>
                <Typography sx={{ fontWeight: 600 }}>
                  Current Salary (Full-time or Contract)
                </Typography>
                <span className='feildHeader'>Choose how much salary candidate gets currently</span>
              </div>
            ) : (
              <div style={{ width: '35%' }}>
                <Typography sx={{ fontWeight: 600 }}>
                  Current Salary (Full-time or Contract):
                </Typography>
              </div>
            )}

            <div
              style={{
                width: '50%',
                display: 'flex',
                alignItems: 'center',
              }}>
              <div style={{ display: 'flex', width: '100%' }}>
                {edit ? (
                  <CountryCodes
                    selectedCountry={selectedFullTimeFlag}
                    setSelectedCountry={(value) => setSelectedFullTimeFlag(value)}
                  />
                ) : (
                  <span>{selectedFullTimeFlag}</span>
                )}

                {edit ? (
                  <TextField
                    className='salaryType'
                    style={{ borderRadius: '0px' }}
                    value={formik.values.currentCtc?.replace(/\D/g, '') || ''}
                    onChange={(e) =>
                      formik.setFieldValue(
                        'currentCtc',
                        `${selectedFullTimeFlag} ${e.target.value}`,
                      )
                    }
                    fullWidth
                    id='currentSalary'
                    size='small'
                    name='payFullTimeRate'
                    placeholder='Enter salary'
                  />
                ) : (
                  <span>{formik.values.currentCtc}</span>
                )}

                {edit ? (
                  <Select
                    className='input-field'
                    sx={{
                      width: '10.625rem',
                      height: 'var(--Font-size-h2, 2.5rem)',
                      marginRight: '10px',
                    }}
                    size='small'
                    value={formik.values.currentCtcFrequency || 2}
                    onChange={(e) => formik.setFieldValue('currentCtcFrequency', e.target.value)}
                    error={Boolean(
                      formik.errors.currentCtcFrequency && formik.touched.currentCtcFrequency,
                    )}>
                    <MenuItem value='' disabled>
                      Choose...
                    </MenuItem>
                    {globalObject.lookupvalues[LookupTypes.TAD_PAY_TYPE].map(
                      (payType: any, index: any) => (
                        <MenuItem key={index} value={payType.id}>
                          {payType.name}
                        </MenuItem>
                      ),
                    )}
                  </Select>
                ) : (
                  <span>{formik.values.currentCtcFrequency}</span>
                )}
              </div>
            </div>

            <div className='mt-0 form-err-message'>{getError(formik, 'currentCtc')}</div>
          </div>
          <div className='field-container'>
            {mode === 'edit-page' ? (
              <div style={{ width: '50%' }}>
                <Typography sx={{ fontWeight: 600 }}>
                  Expected Salary (Full-time or Contract)
                </Typography>
                <span className='feildHeader'>Choose how much salary candidate expects</span>
              </div>
            ) : (
              <div style={{ width: '35%' }}>
                <Typography sx={{ fontWeight: 600 }}>
                  Expected Salary (Full-time or Contract):
                </Typography>
              </div>
            )}

            <div style={{ width: '50%', display: 'flex', alignItems: 'center' }}>
              <div style={{ display: 'flex', width: '100%' }}>
                {edit ? (
                  // Country Code selection for salary
                  <CountryCodes
                    selectedCountry={selectedFullTimeFlag}
                    setSelectedCountry={(value) => setSelectedFullTimeFlag(value)}
                  />
                ) : (
                  <span>{selectedFullTimeFlag}</span>
                )}

                {edit ? (
                  // Text field for Expected Salary input
                  <TextField
                    className='salaryType'
                    style={{ borderRadius: '0px 0px 0px 0px' }}
                    value={formik.values.expectedCtc?.replace(/\D/g, '') || ''}
                    onChange={(e) =>
                      formik.setFieldValue(
                        'expectedCtc',
                        `${selectedFullTimeFlag} ${e.target.value}`,
                      )
                    }
                    fullWidth
                    id='currentSalary'
                    size='small'
                    name='payFullTimeRate'
                    placeholder='Enter salary'
                    error={Boolean(formik.errors.expectedCtc && formik.touched.expectedCtc)}
                  />
                ) : (
                  <span>{formik.values.expectedCtc}</span>
                )}

                {edit ? (
                  // Select dropdown for Expected Salary Frequency
                  <Select
                    className='input-field'
                    sx={{
                      width: '10.625rem',
                      height: 'var(--Font-size-h2, 2.5rem)',
                      marginRight: '10px',
                    }}
                    size='small'
                    value={formik.values.expectedCtcFrequency || 2}
                    defaultValue={2}
                    onChange={(e) => formik.setFieldValue('expectedCtcFrequency', e.target.value)}
                    error={Boolean(
                      formik.errors.expectedCtcFrequency && formik.touched.expectedCtcFrequency,
                    )}>
                    <MenuItem value='' disabled>
                      Choose...
                    </MenuItem>
                    {globalObject.lookupvalues[LookupTypes.TAD_PAY_TYPE].map(
                      (payType: any, index: number) => (
                        <MenuItem key={index} value={payType.id}>
                          {payType.name}
                        </MenuItem>
                      ),
                    )}
                  </Select>
                ) : (
                  <span>{getLabel(formik.values.expectedCtcFrequency)}</span>
                )}
              </div>
            </div>

            {/* Error message display for Expected Salary Frequency */}
            <div className='mt-0 form-err-message'>{getError(formik, 'expectedCtcFrequency')}</div>
          </div>
          <div
            className='field-container'
            style={{ justifyContent: mode === 'details-page' ? 'flex-start' : 'space-between' }}>
            {mode === 'edit-page' ? (
              <div style={{ width: '319px' }}>
                <Typography sx={{ fontWeight: 600 }}>Sponsorship Required?</Typography>
              </div>
            ) : (
              <div style={{ width: '319px' }}>
                <Typography sx={{ fontWeight: 600 }}>Sponsorship Required: </Typography>
              </div>
            )}

            {edit ? (
              <div style={{ height: 'var(--Font-size-h2, 2.5rem)', width: '50%' }}>
                <RadioGroup
                  row
                  aria-labelledby='demo-controlled-radio-buttons-group'
                  name='controlled-radio-buttons-group'
                  value={formik.values.sponsorshipRequired || false}
                  onChange={(e) => formik.setFieldValue('sponsorshipRequired', e.target.value)}>
                  <FormControlLabel value={false} control={<Radio />} label='No' />
                  <FormControlLabel value={true} control={<Radio />} label='Yes' />
                </RadioGroup>
                <div className='mt-0 form-err-message'>
                  {getError(formik, 'sponsorshipRequired')}
                </div>
              </div>
            ) : (
              <span style={{ width: '50%' }}>
                {formik.values.sponsorshipRequired ? 'Yes' : 'No'}
              </span>
            )}
          </div>
          <div
            className='field-container'
            style={{ justifyContent: mode === 'details-page' ? 'flex-start' : 'space-between' }}>
            {mode === 'edit-page' ? (
              <div style={{ width: '50%' }}>
                <Typography sx={{ fontWeight: 600 }}>Visa Type</Typography>
                <span className='feildHeader'>Check for Right to work with specific visa type</span>
              </div>
            ) : (
              <div style={{ width: '35%' }}>
                <Typography sx={{ fontWeight: 600 }}>Visa Type: </Typography>
              </div>
            )}

            {edit ? (
              <div style={{ width: '50%' }}>
                <Autocomplete
                  fullWidth
                  className='visaType'
                  id='combo-box-demo'
                  options={globalObject.lookupvalues[LookupTypes.TAD_VISA_TYPE] as OptionType[]}
                  value={
                    formik.values.visaType !== undefined
                      ? globalObject.lookupvalues[LookupTypes.TAD_VISA_TYPE].find(
                          (option: OptionType) => option.id === formik.values.visaType,
                        )
                      : null
                  }
                  size='small'
                  sx={{
                    background: 'var(--Secondary-colors-White, #FFF)',
                    border: '1px solid var(--Secondary-colors-Grey, #868686)',
                    borderRadius: '0.3125rem',
                  }}
                  limitTags={1}
                  getOptionLabel={(option: any) => option.name}
                  filterSelectedOptions
                  onBlur={() => formik.setFieldTouched('visaType', true, true)}
                  renderInput={(params) => (
                    <TextField
                      placeholder='Choose...'
                      {...params}
                      error={Boolean(formik.errors.visaType && formik.touched.visaType)}
                      label=''
                    />
                  )}
                  onChange={(_e, val) => {
                    formik.setFieldValue('visaType', val ? Number(val.id) : undefined);
                  }}
                />
                <div className='mt-0 form-err-message'>{getError(formik, 'visaType')}</div>
              </div>
            ) : (
              <span style={{ overflowWrap: 'anywhere' }}>
                {typeof formik.values.visaType === 'number'
                  ? getNameFromId(formik.values.visaType, LookupTypes.TAD_VISA_TYPE)
                  : 'Unknown Visa Type'}
              </span>
            )}
          </div>
          <div
            className='field-container'
            style={{ justifyContent: mode === 'details-page' ? 'flex-start' : 'space-between' }}>
            {mode === 'edit-page' ? (
              <div style={{ width: '50%' }}>
                <Typography sx={{ fontWeight: 600 }}>Visa Status</Typography>
              </div>
            ) : (
              <div style={{ width: '35%' }}>
                <Typography sx={{ fontWeight: 600 }}>Visa Status: </Typography>
              </div>
            )}

            {edit ? (
              <div style={{ width: '50%' }}>
                <Autocomplete
                  fullWidth
                  className='visaType'
                  id='combo-box-demo'
                  options={[
                    { id: true, name: 'Active' },
                    { id: false, name: 'Not-Active' },
                  ]}
                  value={
                    typeof formik.values.visaStatus === 'boolean'
                      ? {
                          id: formik.values.visaStatus,
                          name: formik.values.visaStatus ? 'Active' : 'Not-Active',
                        }
                      : null
                  }
                  size='small'
                  sx={{
                    background: 'var(--Secondary-colors-White, #FFF)',
                    border: '1px solid var(--Secondary-colors-Grey, #868686)',
                    borderRadius: '0.3125rem',
                  }}
                  limitTags={1}
                  getOptionLabel={(option: any) => option.name}
                  filterSelectedOptions
                  onBlur={() => formik.setFieldTouched('visaStatus', true, true)}
                  renderInput={(params) => (
                    <TextField
                      placeholder='Choose...'
                      {...params}
                      error={Boolean(formik.errors.visaStatus && formik.touched.visaStatus)}
                      label=''
                    />
                  )}
                  onChange={(_e, val) => {
                    formik.setFieldValue('visaStatus', val ? val?.id : undefined);
                  }}
                />

                <div className='mt-0 form-err-message'>{getError(formik, 'visaStatus')}</div>
              </div>
            ) : (
              <span>{formik.values.visaStatus ? 'Active' : 'Not-Active'}</span>
            )}
          </div>
          <div
            className='field-container'
            style={{ justifyContent: mode === 'details-page' ? 'flex-start' : 'space-between' }}>
            {mode === 'edit-page' ? (
              <div style={{ width: '50%' }}>
                <Typography sx={{ fontWeight: 600 }}>Visa Validity</Typography>
              </div>
            ) : (
              <div style={{ width: '35%' }}>
                <Typography sx={{ fontWeight: 600 }}>Visa Validity:</Typography>
              </div>
            )}

            <div style={{ width: '50%' }}>
              {edit ? (
                <div>
                  <DatePicker
                    className='date-picker'
                    sx={{ width: '100%' }}
                    format='DD/MM/YYYY'
                    disablePast
                    views={['year', 'month', 'day']}
                    value={formik.values.visaValidity ? dayjs(formik.values.visaValidity) : null}
                    onChange={(value) => {
                      formik.setFieldValue(
                        'visaValidity',
                        dateFormat(value?.toString(), 'isoDateTime'),
                        true,
                      );
                    }}
                  />
                  <div className='mt-0 form-err-message'>{getError(formik, 'visaValidity')}</div>
                </div>
              ) : (
                <span style={{ overflowWrap: 'anywhere' }}>
                  {formik.values.visaValidity &&
                    dateFormat(new Date(formik.values.visaValidity), 'dd/mm/yyyy')}
                </span>
              )}
            </div>
          </div>
        </div>

        {mode === 'edit-page' && (
          <div style={{ padding: '1.87rem 1.88rem', borderTop: '1px solid #868686' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Button
                className='button'
                sx={{
                  width: '7.375rem',
                  height: '2.5rem',
                  borderRadius: '5px',
                  border: '1px solid #2D7DFE',
                  backgroundColor: '#FFF',
                  color: 'black',
                  textTransform: 'none',
                  fontSize: '1rem',
                  fontWeight: 400,
                }}>
                Cancel
              </Button>

              <div style={{ display: 'flex', gap: '15px' }}>
                <Button
                  className='button'
                  onClick={() => previous()}
                  sx={{
                    width: '7.375rem',
                    height: '2.5rem',
                    borderRadius: '5px',
                    border: '1px solid #2D7DFE',
                    backgroundColor: '#FFF',
                    color: 'black',
                    textTransform: 'none',
                    fontSize: '1rem',
                    fontWeight: 400,
                  }}>
                  Previous
                </Button>

                <Button
                  className='button'
                  onClick={() => {
                    setSubmitted(true);
                    formik.handleSubmit();
                  }}
                  sx={{
                    textTransform: 'none',
                    fontWeight: 400,
                    fontSize: '1rem',
                    width: '7.375rem',
                    height: '2.5rem',
                    color: 'white !important',
                    background: '#2D7DFE !important',
                  }}>
                  Next
                </Button>
              </div>
            </div>
          </div>
        )}
      </Paper>
    </div>
  );
};

export default CandidateDetails;
