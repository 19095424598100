import React, { useEffect, useState, useMemo } from 'react';
import {
  Box,
  Chip,
  Divider,
  Drawer,
  Grid,
  IconButton,
  InputBase,
  List,
  ListItem,
  Paper,
  Typography,
  Button,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import { Search } from '@mui/icons-material';
import CloseIcon from '../../../assets/sjd/close.svg';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { SkillModel } from '../../../models/tad-candidate-form-model';
import { getTadSkills } from '../../../../service/lookupService';
import { updateCandidatePrimarySkills } from '../../../service/tadCandidateService';

interface CandidatePrimarySkillsProps {
  primaryskills: SkillModel[];
}

const CandidatePrimarySkills: React.FC<CandidatePrimarySkillsProps> = ({ primaryskills }) => {
  const [selectedSkills, setSelectedSkills] = useState<SkillModel[]>([]);
  const [menuOpen, setMenuOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [skillsToAdd, setSkillsToAdd] = useState<SkillModel[]>([]);
  const [allSkills, setAllSkills] = useState<SkillModel[]>([]);

  // Fetch initial selected skills and all available skills
  useEffect(() => {
    if (primaryskills) {
      setSelectedSkills(primaryskills);
    }
  }, [primaryskills]);

  useEffect(() => {
    const fetchSkills = async () => {
      try {
        const response = await getTadSkills();
        setAllSkills(response.data);
      } catch (error) {
        console.error('Error fetching skills:', error);
      }
    };

    fetchSkills();
  }, []);

  // Handle deletion of a selected skill
  const handleDelete = async (skillToDelete: SkillModel) => {
    setSelectedSkills((prevSkills) => {
      const updatedSkills = prevSkills.filter((skill) => skill.id !== skillToDelete.id);
      updateSkillsAPI(updatedSkills); // Update the backend
      return updatedSkills;
    });
  };

  // Handle adding skills to the list
  const handleAddClick = () => {
    setMenuOpen(true);
  };

  // Close the drawer
  const handleDrawerClose = () => {
    setMenuOpen(false);
  };

  // Handle individual skill selection (Add/Remove)
  const handleSkillClick = (skill: SkillModel) => {
    setSkillsToAdd((prevSkills) => {
      const updatedSkills = prevSkills.some((s) => s.id === skill.id)
        ? prevSkills.filter((s) => s.id !== skill.id)
        : [...prevSkills, skill];
      return updatedSkills;
    });
  };

  // Add all selected skills to the candidate's primary skills
  const handleAddAllSkills = async () => {
    setSelectedSkills((prevSkills) => {
      const newSkills = skillsToAdd.filter((skill) => !prevSkills.some((s) => s.id === skill.id));
      const updatedSkills = [...prevSkills, ...newSkills];
      updateSkillsAPI(updatedSkills); // Update the backend
      return updatedSkills;
    });
    setSkillsToAdd([]); // Clear selected skills for adding
    setMenuOpen(false);
  };

  // Search functionality to filter skills
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const filteredSkills = useMemo(
    () => allSkills.filter((skill) => skill.name.toLowerCase().includes(searchTerm.toLowerCase())),
    [searchTerm, allSkills],
  );

  const updateSkillsAPI = async (skills: SkillModel[]) => {
    try {
      console.log('Updating primary skills with the following data:', skills);
      const response = await updateCandidatePrimarySkills({ skills });
      console.log('API response:', response);
    } catch (error) {
      console.error('Error updating skills:', error);
    }
  };

  const DrawerList = (
    <Box role='presentation' sx={{ width: '43.75rem', height: '64rem' }}>
      <List sx={{ padding: '3.19rem 1.88rem 0rem 1.69rem' }}>
        <ListItem
          disablePadding
          sx={{ marginBottom: '1.19rem', cursor: 'pointer', fontWeight: 400 }}>
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              justifyContent: 'space-between',
              gap: '18.12rem',
            }}>
            <Typography sx={{ fontWeight: 600, fontSize: '1.25rem' }}>Add Skills</Typography>
            <IconButton onClick={handleDrawerClose}>
              <img src={CloseIcon} alt='Close' style={{ width: '24px' }} />
            </IconButton>
          </Box>
        </ListItem>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            marginBottom: '0.8rem',
            padding: '0 1.06rem',
          }}>
          <Search />
          <InputBase
            sx={{ ml: '10px', width: '100%' }}
            placeholder='Search for a skill'
            inputProps={{ 'aria-label': 'Search a skill' }}
            value={searchTerm}
            onChange={handleSearchChange}
          />
        </Box>
        <Divider />
        <Typography sx={{ padding: '1rem 1.06rem', fontWeight: 600 }}>
          Recently added skills
        </Typography>
        <List>
          {filteredSkills.map((skill) => (
            <ListItem
              key={skill.id}
              sx={{
                height: '2.68rem',
                marginBottom: '0.5rem',
                background: '#F1F4F8',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '0 1rem',
              }}>
              <Typography sx={{ fontSize: '1rem', color: '#333' }}>{skill.name}</Typography>
              <IconButton
                onClick={() => handleSkillClick(skill)}
                sx={{
                  color: skillsToAdd.some((s) => s.id === skill.id) ? '#1976D2' : '#868686',
                }}>
                {skillsToAdd.some((s) => s.id === skill.id) ? <CheckIcon /> : <AddIcon />}
              </IconButton>
            </ListItem>
          ))}
        </List>
        <Box display='flex' justifyContent='flex-end' padding='1rem' gap='1rem'>
          <Button
            onClick={handleDrawerClose}
            sx={{
              width: '7.375rem',
              height: '2.5rem',
              borderRadius: '5px',
              border: '1px solid #2D7DFE',
              backgroundColor: '#FFF',
              color: 'black',
              textTransform: 'none',
              fontSize: '1rem',
              fontWeight: 400,
            }}>
            Cancel
          </Button>
          <Button
            variant='contained'
            color='primary'
            sx={{
              textTransform: 'none',
              fontWeight: 400,
              fontSize: '1rem',
              width: '7.375rem',
              height: '2.5rem',
              color: 'white !important',
              background: '#2D7DFE !important',
            }}
            onClick={handleAddAllSkills}>
            Add
          </Button>
        </Box>
      </List>
    </Box>
  );

  return (
    <Paper sx={{ height: '14.5rem', width: '100%' }}>
      <Box
        sx={{
          height: '61px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          backgroundColor: '#E7E7E7',
          paddingLeft: '1.81rem',
        }}>
        <Typography sx={{ fontSize: '18px', fontWeight: 'bold' }}>Primary Skills</Typography>
        <Box>
          <IconButton onClick={handleAddClick}>
            <AddIcon />
          </IconButton>
          <IconButton>
            <MoreVertIcon />
          </IconButton>
        </Box>
      </Box>
      <Grid container spacing={1} sx={{ marginTop: '8px', marginLeft: '1.25rem' }}>
        {selectedSkills.map((skill) => (
          <Grid item key={skill.id}>
            <Chip
              label={skill.name}
              onDelete={() => handleDelete(skill)}
              variant='outlined'
              sx={{
                fontSize: '0.875rem',
                backgroundColor: '#E1E1E1',
                color: 'black',
                borderRadius: '10px',
              }}
            />
          </Grid>
        ))}
      </Grid>
      <Drawer anchor='right' open={menuOpen} onClose={handleDrawerClose} sx={{ zIndex: 9999 }}>
        {DrawerList}
      </Drawer>
    </Paper>
  );
};

export default CandidatePrimarySkills;
