import { Done, Add } from '@mui/icons-material';
import { Box, List, ListItem, Typography, InputBase, Divider, IconButton } from '@mui/material';
// import { ReactComponent as CloseIcon } from '../../assets/sjd/close.svg';
import React, { useEffect, useState } from 'react';
import { ReactComponent as Search } from '../../assets/candidate-assets/search.svg';
// import { tagCandidateToSjd } from '../../service/tadSjdService';
// import globalObject from '../../../common/global-variables';
import { tadGetAllCandidate } from '../../service/tadCandidateService';
import { tagCandidateToSjd } from '../../service/tadSjdService';
import { useParams } from 'react-router-dom';
// import { tagCandidateToSjd } from '../../service/tadSjdService';
type Candidate = {
  id: number;
  firstName: string;
  lastName: string;
};

function AddCandidateDrawer() {
  const [search, setSearch] = useState('');
  const [data, setData] = useState([] as any[]);
  let { sjdId } = useParams();
  if (sjdId) sjdId = atob(sjdId);

  const [selectedUsers, setSelectedUsers] = useState<Candidate[]>([]);

  useEffect(() => {
    tadGetAllCandidate()
      .then((res: any) => {
        setData(res.data);
      })
      .catch((error) => console.error('Error fetching candidates:', error));
  }, []);

  const handleTagCandidate = (candidate: any) => {
    const request = {
      sjdId: sjdId,
      candidateId: candidate.id,
      resumeId: candidate.resumeId,
    };
    console.log(request);

    tagCandidateToSjd(request)
      .then((response) => {
        console.log(response.data.message);
        window.location.reload();
      })
      .catch((error) => {
        console.error('Error tagging candidate:', error);
      });
  };

  const filteredData = data.filter((candidate) =>
    candidate.name.toLowerCase().includes(search.toLowerCase()),
  );

  const handleAddUser = (candidate: Candidate) => {
    if (!selectedUsers.some((user) => user.id === candidate.id)) {
      setSelectedUsers([...selectedUsers, candidate]);
    }
    handleTagCandidate(candidate);
  };

  // const handleSelectCandidate = (candidate: Candidate) => {
  //   setSelectedUsers((prevSelectedUsers) => {
  //     if (prevSelectedUsers.some((user) => user.id === candidate.id)) {
  //       // If candidate is already selected, remove them
  //       return prevSelectedUsers.filter((user) => user.id !== candidate.id);
  //     } else {
  //       // If candidate is not selected, add them
  //       return [...prevSelectedUsers, candidate];
  //     }
  //   });
  //   handleTagCandidate(candidate); // Call only after candidate selection
  // };

  const DrawerList = (
    <Box role='presentation' sx={{ width: '43.75rem', height: '64rem' }}>
      <List sx={{ padding: '3.19rem 1.88rem 0rem 1.69rem' }}>
        <ListItem
          disablePadding
          sx={{ marginBottom: '1.19rem', cursor: 'pointer', fontWeight: 400 }}>
          <div
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'space-between',
              gap: '18.12rem',
            }}>
            <div>
              <Typography sx={{ fontWeight: 600, fontSize: '1.25rem' }}>Add Candidates</Typography>
            </div>
            {/* <div>
              <CloseIcon />
            </div> */}
          </div>
        </ListItem>
        <div
          style={{
            gap: '24.56rem',
            alignItems: 'center',
            marginBottom: '0.8rem',
          }}>
          <hr style={{ background: '#868686' }} />
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between', gap: '5%', width: '100%' }}>
          <div style={{ width: '100%' }}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: '0.8rem',
                marginLeft: '1.06rem',
              }}>
              <Search />
              <InputBase
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                sx={{ ml: '10px', width: '100%' }}
                placeholder='Search'
                inputProps={{ 'aria-label': 'Search a keyword' }}
              />
            </div>
            <div>
              <Divider sx={{ display: 'flex' }} />
            </div>
          </div>
        </div>

        <div style={{ marginTop: '2.12rem', marginBottom: '1.25rem' }}>
          <Typography sx={{ fontWeight: 600, fontSize: '1.25rem', color: '#000000' }}>
            Recently Created Candidates
          </Typography>
        </div>
        {filteredData.map((candidate) => (
          <div
            key={candidate.id}
            style={{
              marginBottom: '0.63rem',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              backgroundColor: '#F1F4F8',
              height: '5.4375rem',
            }}>
            <div style={{ display: 'flex', gap: '10px', marginLeft: '1.25rem' }}>
              <div
                style={{
                  backgroundColor: 'rgb(25, 118, 210)',
                  color: 'white',
                  padding: '0.5rem',
                  borderRadius: '50%',
                }}>
                {candidate.name.charAt(0)}
              </div>
              <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                <span style={{ color: '#1976D2', textDecoration: 'underline' }}>
                  {candidate.name}
                </span>
              </div>
            </div>
            <div style={{ marginRight: '1.25rem' }}>
              <IconButton onClick={() => handleAddUser(candidate)}>
                {selectedUsers.some((user) => user.id === candidate.id) ? <Done /> : <Add />}
              </IconButton>
            </div>
          </div>
        ))}
      </List>
    </Box>
  );

  return <div>{DrawerList}</div>;
}

export default AddCandidateDrawer;
