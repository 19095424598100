import { Paper } from '@mui/material';
import React from 'react';
import TadTimeLine from '../../common/timeline/tad-timeline';

// const dummyEvents = [
//   {
//     eventTypeId: 1,
//     description: 'Resume updated',
//     createdBy: 'Recruiter B',
//     createdOn: '2024-10-12T14:45:00Z',
//   },
//   {
//     eventTypeId: 2,
//     description: 'Candidate status changed to Interview',
//     createdBy: 'Recruiter C',
//     createdOn: '2024-10-13T16:00:00Z',
//   },
//   {
//     eventTypeId: 3,
//     description: 'Feedback added: Strong skills',
//     createdBy: 'Interviewer A',
//     createdOn: '2024-10-14T11:00:00Z',
//   },
//   {
//     eventTypeId: 4,
//     description: 'Feedback added: Strong skills',
//     createdBy: 'Interviewer A',
//     createdOn: '2024-10-14T11:00:00Z',
//   },
//   {
//     eventTypeId: 5,
//     description: 'Feedback added: Strong skills',
//     createdBy: 'Interviewer B',
//     createdOn: '2024-10-20T11:00:00Z',
//   },
// ];

interface Props {
  _values: any;
}

const CandidateHistory = ({ _values }: Props) => {
  return (
    <>
      <Paper
        className='paper'
        sx={{
          maxHeight: '50rem',
        }}>
        <div className='header-container'>
          <div className='header-content-title'>
            <span>History</span>
          </div>
        </div>
        {/* <span>{JSON.stringify(_values)}</span> */}
        <TadTimeLine events={_values} parent='candidate' />
      </Paper>
    </>
  );
};

export default CandidateHistory;
