import {
  // Grid,
  // List,
  // ListItem,
  // ListItemButton,
  // ListItemIcon,
  // ListItemText,
  Paper,
} from '@mui/material';
import { useEffect, useState } from 'react';
// import { isPermissions } from '../../../common/commonUtil';
// import { MODULE, SubModuleTypes } from '../../../constants/common';
import General from './general';
import Users from './users-settings';
import NewProfile from './new-profile';
import Notifications from './notifications';
import { ReactComponent as GeneralIcon } from '../../assets/user-settings/apps.svg';
import { ReactComponent as ProfileIcon } from '../../assets/user-settings/account_circle.svg';
import { ReactComponent as UsersIcon } from '../../assets/user-settings/Group.svg';
import { ReactComponent as NotificationsIcon } from '../../assets/user-settings/notifications_active.svg';
import { ReactComponent as ApprovalsIcon } from '../../assets/user-settings/done_all.svg';
import { ReactComponent as TemplatesIcon } from '../../assets/user-settings/email.svg';
import { ReactComponent as IntegrationsIcon } from '../../assets/user-settings/Group (1).svg';
import Templates from './templates';
import TadPageHeader from '../common/page-header/tad-page-header';

const SETTING_TABS = {
  GENERAL: 'General',
  PROFILE: 'Profile',
  USERS: 'Users',
  NOTIFICATIONS: 'Notifications',
  APPROVALS: 'Approvals',
  TEMPLATES: 'Templates',
  INTEGRATIONS: 'Integrations',
};

const settings = [
  { label: 'General', icon: GeneralIcon },
  { label: 'Profile', icon: ProfileIcon },
  { label: 'Users', icon: UsersIcon },
  { label: 'Notifications', icon: NotificationsIcon },
  { label: 'Approvals', icon: ApprovalsIcon },
  { label: 'Templates', icon: TemplatesIcon },
  { label: 'Integrations', icon: IntegrationsIcon },
];

const TadUserSettings = () => {
  const [seletedTab, setSelectedTab] = useState('');
  const handleClick = (tab: string) => {
    setSelectedTab(tab);
  };
  useEffect(() => setSelectedTab(SETTING_TABS.PROFILE), []);

  return (
    <div className='main-div'>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ marginTop: '1.69rem', marginBottom: '1.19rem' }}>
          <TadPageHeader></TadPageHeader>
        </div>
        <div style={{ gap: '1.94rem', display: 'flex', flexWrap: 'nowrap' }}>
          <Paper sx={{ height: '24.0625rem', width: '18.5625rem' }}>
            <div
              className='paper'
              style={{
                width: '18.5625rem',
                height: '24.0625rem',
              }}>
              <div
                style={{
                  height: '3.5rem',
                  width: '17.25rem',
                  marginLeft: '1.25rem',
                  display: 'flex',
                  alignItems: 'center',
                }}>
                <span style={{ fontSize: 'var(--Font-size-h6, 1.1875rem)', fontWeight: 600 }}>
                  Settings
                </span>
              </div>
              <hr
                style={{ color: '#C1C0C0', width: '18.5625rem', margin: '0rem 0rem 0.5rem 0rem' }}
              />
              {settings.map((item, index) => (
                <div
                  style={{
                    marginLeft: '0.62rem',
                    width: '17.25rem',
                    height: ' 2.625rem',
                    flexShrink: 0,
                    display: 'flex',
                    alignItems: 'center',
                    background: item.label === seletedTab ? 'rgba(140, 214, 255, 0.50)' : '',
                    cursor: 'pointer',
                    borderRadius: '0.625rem',
                  }}
                  onClick={() => {
                    handleClick(item.label);
                    setSelectedTab(item.label);
                  }}
                  key={index}>
                  {<item.icon style={{ marginLeft: '0.62rem', marginRight: '1.5rem' }} />}
                  <span
                    style={{
                      fontSize: '1rem',
                      fontWeight: 400,
                    }}>
                    {item.label}
                  </span>
                </div>
              ))}
            </div>
          </Paper>
          {seletedTab == SETTING_TABS.GENERAL && <General />}
          {seletedTab == SETTING_TABS.USERS && <Users />}
          {seletedTab == SETTING_TABS.PROFILE && <NewProfile />}
          {seletedTab == SETTING_TABS.NOTIFICATIONS && <Notifications />}
          {seletedTab == SETTING_TABS.TEMPLATES && <Templates />}
        </div>
      </div>
    </div>
  );
};
export default TadUserSettings;
