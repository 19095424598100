import {
  // MenuItem,
  // Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';
// import { ReactComponent as MoreVertIcon } from '../../../assets/dashboard-assets/more_vert.svg';
import { TadCandidateEmploymentDetails } from '../../../models/tad-candidate-form-model';
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';

interface CandidateEmployementDetailsProps {
  employmentdetails: TadCandidateEmploymentDetails[] | undefined;
}

const CandidateEmploymentDetails: React.FC<CandidateEmployementDetailsProps> = ({
  // eslint-disable-next-line react/prop-types
  employmentdetails,
}) => {
  const [selectedUsers, setSelectedUsers] = useState<TadCandidateEmploymentDetails[]>([]); // Update type

  useEffect(() => {
    if (employmentdetails) {
      setSelectedUsers(employmentdetails);
    }
  }, [employmentdetails]);

  return (
    <>
      <Paper style={{ overflowY: 'scroll', height: '26.125rem', width: '100%' }}>
        <div
          style={{
            height: '61px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            backgroundColor: 'var(--Secondary-Grey-2, #E7E7E7)',
          }}>
          <div style={{ display: 'flex' }}>
            <span
              className='heading'
              style={{ height: '24px', fontSize: '18px', marginLeft: '1.81rem' }}>
              Employment Details
            </span>
          </div>
          {/* <div style={{ display: 'flex', alignItems: 'center' }}>
            <MoreVertIcon style={{ marginRight: '10px' }} />
          </div> */}
        </div>

        {/* <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: '20px',
          }}>
          <div>
            <Select
              sx={{
                display: 'flex',
                width: '11.4375rem',
                height: '30px',
                marginLeft: '26.61px',
              }}
              size='small'
              fullWidth
              labelId='domain'
              name='domain'
              value={0}
              id='demo-simple-select'>
              <MenuItem key={0} value={0}>
                All Recruiters
              </MenuItem>
              <MenuItem>Select</MenuItem>
              <MenuItem>Select</MenuItem>
            </Select>
          </div>
        </div> */}

        <div className='qc-table' style={{ marginLeft: '2.02rem', marginRight: '1.25rem' }}>
          <Table>
            <TableHead style={{ borderBottom: '0.125rem solid #C1C0C0' }}>
              <TableRow>
                <TableCell>Company Name & Role</TableCell>
                <TableCell>Start Date</TableCell>
                <TableCell>End Date</TableCell>
                <TableCell>Location</TableCell>
                <TableCell>Current Employer</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {selectedUsers.map((item, index) => (
                <TableRow
                  key={index}
                  style={{
                    backgroundColor: item.currentEmployer
                      ? 'var(--Secondary-colors-Grey-3, #F1F4F8)'
                      : 'transparent',
                  }}>
                  <TableCell>
                    <span style={{ color: '#868686', fontSize: '0.875rem', fontWeight: '400' }}>
                      {item.companyName}
                    </span>
                    <br />
                    <span
                      style={{
                        textDecoration: 'underline',
                        fontSize: '1rem',
                        fontWeight: 600,
                        color: '#1976D2',
                      }}>
                      {item.designation}
                    </span>
                  </TableCell>
                  <TableCell> {dayjs(item.startDate).format('DD/MM/YYYY ')}</TableCell>
                  <TableCell> {dayjs(item.endDate).format('DD/MM/YYYY ')}</TableCell>
                  <TableCell>{item.location}</TableCell>
                  <TableCell>
                    {item.currentEmployer ? (
                      <div
                        style={{
                          width: '1.25rem',
                          height: '1.25rem',
                          flexShrink: 0,
                          opacity: 0.8,
                          background: '#1976D2',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          borderRadius: '50%',
                        }}>
                        ✓
                      </div>
                    ) : (
                      <div
                        style={{
                          width: '1.25rem',
                          height: '1.25rem',
                          flexShrink: 0,
                          opacity: 0.2,
                          background: '#1976D2',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          borderRadius: '50%',
                        }}>
                        ✓
                      </div>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </Paper>
    </>
  );
};

export default CandidateEmploymentDetails;
