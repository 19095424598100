import axiosClient from '../../common/axiosClient';

export async function tadAddSjd(request: any) {
  return axiosClient.post('/tad-sjd', request);
}

export async function tadUpdateSjd(request: any) {
  return axiosClient.patch('/tad-sjd', request);
}

export async function getAllTadSjd(userId: any) {
  return axiosClient.get('/tad-sjd', { params: { userId } });
}

export async function getTadSjd(id: any) {
  return axiosClient.get(`/tad-sjd/${id}`);
}

export async function generateQuestionnaire(request: any) {
  return axiosClient.post('/sjd/generate-questionnaire', request);
}

export async function generateJobDescription(request: any) {
  return axiosClient.post('/sjd/job-description', request);
}

export async function getClientsByProjectTypeId(projectId?: any) {
  console.log('projectId', projectId);
  return axiosClient.get('/tad-sjd/projectId', { params: { projectId } });
}

export async function getUserDetailsByProjectIdAndClientId(projectId?: any, clientId?: any) {
  console.log('user-details', projectId, clientId);
  return axiosClient.get('/tad-sjd/user-details', { params: { projectId, clientId } });
}

export async function tagCandidateToSjd(request: any) {
  return axiosClient.post('/tad-sjd/candidate', request);
}

export async function tadUntagCandidateApi(request: any) {
  return axiosClient.delete('/tad-sjd/candidate', { data: request });
}
