import React, { useEffect, useState } from 'react';
import './../source-job-definition/interview-process.scss';
import {
  Button,
  IconButton,
  InputLabel,
  List,
  MenuItem,
  Paper,
  Select,
  TextField,
} from '@mui/material';
import CloseIcon from './../../assets/sjd/close.svg';
import { ReactComponent as ArrowIcons } from './../../assets/sjd/chevron_right.svg';
import { ReactComponent as Add } from './../../assets/sjd/add.svg';
import { ReactComponent as DeleteIcon } from './../../assets/sjd/delete_outline.svg';
import { ReactComponent as DragIcon } from './../../assets/sjd/drag_indicator.svg';
import { ReactComponent as LockIcon } from './../../assets/sjd/lock_close.svg';
import globalObject from '../../../common/global-variables';
import { LookupTypes } from '../../../common/lookupTypes';
import { TadInterviewProcessDetails } from '../../models/tad-sjd-form-model';
import { getNameFromId } from '../../common/commonUtil';
import { tadGetAllInterviewSubStages } from '../../service/tadCandidateService';
// import { TadInterviewProcessDetails } from '../../models/tad-sjd-form-model';

// const initialStages: Stage[] = [
//   { stageName: 'Applied', position: 1, isLocked: true, color: '#82B1FF', items: [] },
//   // {
//   //   stageName: 'Screening',
//   //   position: 2,
//   //   isLocked: false,
//   //   color: '#FBAE19',
//   //   items: [
//   //     { name: 'Online Test', type: 'default', note: 'hello', position: 1 },
//   //     { name: 'Phone Interview', type: 'default', note: 'hello', position: 2 },
//   //   ],
//   // },
//   // {
//   //   stageName: 'Interviews',
//   //   position: 3,
//   //   isLocked: false,
//   //   color: '#4CAF50',
//   //   items: [
//   //     { name: 'Level 1', type: 'default', note: 'hello', position: 1 },
//   //     { name: 'Level 2', type: 'default', note: 'Hi', position: 2 },
//   //     { name: 'Client Round', type: 'default', note: 'world', position: 3 },
//   //   ],
//   // },
//   // { stageName: 'System Test', position: 4, isLocked: false, color: '#868686', items: [] },
//   { stageName: 'Hire', position: 5, isLocked: true, color: '#1976D2', items: [] },
// ];

interface InterViewProcessProps {
  previous?: () => void;
  interviewProcessDetails: TadInterviewProcessDetails | undefined;
  // mode?: 'edit-page' | 'details-page';
  save?: (values: TadInterviewProcessDetails) => Promise<boolean>;
}

type Item = {
  id?: number;
  name: number;
  type: string;
  note: string;
  position: number;
};

// interface OptionType {
//   id: number;
//   name: string;
// }

export interface Stage {
  id?: number;
  stageName: number;
  isLocked: boolean;
  position: number;
  item?: Item[];
}

interface DropdownListProps {
  stageName: number;
  initialItems: Item[];
  // parentColor: string;
  onItemsChange: (items: Item[]) => void;
}

const InterViewProcess: React.FC<InterViewProcessProps> = ({
  previous = () => {},
  interviewProcessDetails,
  save,
}) => {
  const [stages, setStages] = useState<Stage[]>([]);
  const [addNew, setAddNew] = useState(false);
  const [newStageName, setNewStageName] = useState(0);
  const [expandedStage, setExpandedStage] = useState<number | null>(null);
  const [subStages, setSubStages] = useState([] as any[]);

  useEffect(() => {
    if (interviewProcessDetails?.interviewProcess) {
      setStages(interviewProcessDetails.interviewProcess);
    }
  }, [interviewProcessDetails?.interviewProcess]);

  const addStage = () => {
    const insertIndex = stages.length;

    // Initially add a stage with a placeholder name like "New Stage"
    const newStage: Stage = {
      stageName: 0,
      position: 1,
      isLocked: false,
      // color: '#000000',
      item: [],
    };
    console.log('insertIndex', insertIndex, stages);
    const updatedStages = [...stages.slice(0, insertIndex), newStage, ...stages.slice(insertIndex)];

    setStages(updatedStages);
    setExpandedStage(insertIndex); // Expand the new stage if needed
    setAddNew(true); // Set addNew to true to show the input field
    setNewStageName(0); // Reset the name field
  };

  const updateStageName = (index: number, newStageName: number) => {
    setNewStageName(newStageName);
    const updatedStages = [...stages];
    updatedStages[index].stageName = newStageName; // Update the name of the selected stage
    setStages(updatedStages);
    setAddNew(false); // After the name is updated, hide the input field
  };

  const toggleExpand = (index: number, stageName: number) => {
    tadGetAllInterviewSubStages(stageName)
      .then((resp) => setSubStages(resp.data))
      .catch((_err) => {});
    setExpandedStage(expandedStage === index ? null : index);
  };

  const handleItemsChange = (index: number, updatedItems: Item[]) => {
    const updatedStages = [...stages];
    updatedStages[index].item = updatedItems;
    setStages(updatedStages);
  };

  const moveStageUp = (index: number) => {
    if (index === 0 || stages[index].isLocked || stages[index - 1].isLocked) return;

    const newStages = [...stages];
    [newStages[index], newStages[index - 1]] = [newStages[index - 1], newStages[index]]; // Swap
    setStages(newStages);
  };

  const moveStageDown = (index: number) => {
    if (index === stages.length - 1 || stages[index].isLocked || stages[index + 1].isLocked) return;

    const newStages = [...stages];
    [newStages[index], newStages[index + 1]] = [newStages[index + 1], newStages[index]]; // Swap
    setStages(newStages);
  };

  const removeStage = (index: number) => {
    if (stages[index].isLocked) return; // Prevent removal of locked stages

    const newStages = stages.filter((_, i) => i !== index); // Remove the stage at index
    setStages(newStages);
  };

  const DropdownList: React.FC<DropdownListProps> = ({
    stageName,
    initialItems,
    onItemsChange,
    // parentColor,
  }) => {
    const [items, setItems] = useState<Item[]>(initialItems);
    const [editedItems, setEditedItems] = useState<Item[]>([...initialItems]);
    const [newItem, setNewItem] = useState<Item>({ name: 0, type: '', note: '', position: 1 });
    console.log(stageName);
    // useEffect(() => {
    //   if (stageName) {
    //     tadGetAllInterviewSubStages(stageName)
    //       .then((resp) => setSubStages(resp.data))
    //       .catch((_err) => {});
    //   }
    // }, [stageName]);

    const addItem = () => {
      if (newItem) {
        const updatedItems = [...items, { ...newItem }];
        setItems(updatedItems);
        setEditedItems(updatedItems); // sync the edited items state
        onItemsChange(updatedItems); // Notify parent about the change
        setNewItem({ name: 0, type: '', note: '', position: 1 });
      }
    };

    const removeItem = (index: number) => {
      const updatedItems = items.filter((_, i) => i !== index);
      setItems(updatedItems);
      setEditedItems(updatedItems); // sync the edited items state
      onItemsChange(updatedItems); // Notify parent about the change
    };

    const moveItemUp = (index: number) => {
      if (index === 0) return;
      const updatedItems = [...items];
      [updatedItems[index - 1], updatedItems[index]] = [
        updatedItems[index],
        updatedItems[index - 1],
      ];
      setItems(updatedItems);
      setEditedItems(updatedItems); // sync the edited items state
      onItemsChange(updatedItems); // Notify parent about the change
    };

    const moveItemDown = (index: number) => {
      if (index === items.length - 1) return;
      const updatedItems = [...items];
      [updatedItems[index], updatedItems[index + 1]] = [
        updatedItems[index + 1],
        updatedItems[index],
      ];
      setItems(updatedItems);
      setEditedItems(updatedItems); // sync the edited items state
      onItemsChange(updatedItems); // Notify parent about the change
    };

    const handleInputChange = (index: number, field: keyof Item, value: any) => {
      const updatedItems = [...editedItems];
      updatedItems[index] = { ...updatedItems[index], [field]: value };
      setEditedItems(updatedItems); // update the edited items temporarily
    };

    const handleSave = (index: number) => {
      const updatedItems = [...items];
      updatedItems[index] = { ...editedItems[index] }; // save the edited item
      setItems(updatedItems);
      onItemsChange(updatedItems); // trigger the parent callback
    };

    return (
      <div className='dropdown-list'>
        <div className='dropdown-list-container'>
          {editedItems.map((item, index) => (
            <List key={index} className='dropdown-list-item'>
              <div className='item-actions'>
                <div style={{ display: 'flex', gap: '1rem', alignItems: 'flex-start' }}>
                  <DragIcon className='drag' />
                  <span
                    className='stage-content-rectangle'
                    style={{
                      // background: parentColor,
                      margin: '2.5px 2.5px 2.5px 0px',
                    }}></span>
                </div>
                <div>
                  <IconButton
                    disabled={index === items.length - 1}
                    onClick={() => moveItemDown(index)}>
                    <ArrowIcons
                      className={`${index === items.length - 1 ? 'locked' : 'unlocked'} arrowLeft`}
                    />
                  </IconButton>
                  <IconButton disabled={index === 0} onClick={() => moveItemUp(index)}>
                    <ArrowIcons className={`${index === 0 ? 'locked' : 'unlocked'} arrowRight`} />
                  </IconButton>
                  <IconButton onClick={() => removeItem(index)}>
                    <img
                      style={{ width: '1.5rem', height: '1.5rem' }}
                      src={CloseIcon}
                      alt='close'
                    />
                  </IconButton>
                </div>
              </div>
              <div className='list-field'>
                <div style={{ width: '100%' }}>
                  <InputLabel>Stage Name</InputLabel>
                  <Select
                    fullWidth
                    className='inputFeilds'
                    sx={{ height: 'var(--Font-size-h2, 2.5rem)' }}
                    value={item.name}
                    onChange={(e) => handleInputChange(index, 'name', e.target.value)}
                    size='small'
                    labelId='type'
                    name='type'
                    id='type'>
                    <MenuItem value='default' disabled>
                      Choose...
                    </MenuItem>
                    {subStages.map((type: any, index: number) => (
                      <MenuItem key={index} value={type.id}>
                        {type.name}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
                <div>
                  <InputLabel id='type' sx={{ width: '10.4375rem' }}>
                    Type
                  </InputLabel>
                  <Select
                    className='inputFeilds'
                    sx={{ width: '10.4375rem', height: 'var(--Font-size-h2, 2.5rem)' }}
                    value={item.type ? item.type : 'default'}
                    onChange={(e) => handleInputChange(index, 'type', e.target.value as string)}
                    size='small'
                    labelId='type'
                    name='type'
                    id='type'>
                    <MenuItem value='default' disabled>
                      Choose...
                    </MenuItem>
                    {globalObject.lookupvalues[LookupTypes.TAD_INTERVIEW_PROCESS_TYPE].map(
                      (type: any, index: number) => (
                        <MenuItem key={index} value={type.id}>
                          {type.name}
                        </MenuItem>
                      ),
                    )}
                  </Select>
                </div>
              </div>
              <div className='list-field'>
                <div style={{ width: '100%' }}>
                  <InputLabel>Note / URL</InputLabel>
                  <TextField
                    fullWidth
                    size='small'
                    value={item.note}
                    onChange={(e) => handleInputChange(index, 'note', e.target.value)}
                  />
                </div>
              </div>
              <div>
                <Button>Cancel</Button>
                <Button onClick={() => handleSave(index)}>Save</Button>
              </div>
            </List>
          ))}
          <div className='add-item'>
            <Button onClick={addItem}>
              <Add className='add-icon' />
              <span>Add Secondary Stage</span>
            </Button>
          </div>
        </div>
      </div>
    );
  };

  const getInterviewHeader = (name: number, items: Item[]) => {
    const itemsString = items
      .map((value) => getNameFromId(value.name, LookupTypes.TAD_INTERVIEW_MAIN_SUBSTAGE))
      .join(', ');
    return `${getNameFromId(name, LookupTypes.TAD_INTERVIEW_MAIN_STAGE)} - ${itemsString}`;
  };

  return (
    <div style={{ width: '100%', display: 'flex', gap: '1.63rem', flexDirection: 'column' }}>
      <div className='paper' style={{ height: '8.8125rem', width: '100%' }}>
        <Paper className='new-job-requisition' sx={{ width: '100%', height: '100%' }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginLeft: '1.88rem',
              gap: '7px',
            }}>
            <span style={{ fontSize: '1.25rem', fontWeight: 600 }}>Interview Process</span>
            <span
              style={{
                fontSize: '0.875rem',
                fontWeight: 400,
                color: 'var(--Secondary-Grey-1, var(--Secondary-colors-Grey, #868686))',
              }}>
              Set up hiring stages in the interview process to manage candidates for this job.
            </span>
          </div>
        </Paper>
      </div>
      <Paper sx={{ width: '100%' }}>
        <div className='workflow-container'>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '7px',
            }}>
            <span style={{ fontSize: '1.25rem', fontWeight: 600 }}>Workflow</span>
            <span
              style={{
                fontSize: '0.875rem',
                fontWeight: 400,
                color: 'var(--Secondary-Grey-1, var(--Secondary-colors-Grey, #868686))',
              }}>
              Set up hiring stages in the interview process to manage candidates for this job.
            </span>
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '1rem',
              marginTop: '20px',
              marginBottom: '20px',
            }}>
            <Select
              className='inputFeilds'
              sx={{ width: '20.9375rem', height: 'var(--Font-size-h2, 2.5rem)' }}
              size='small'
            />
            <IconButton>
              <DeleteIcon style={{ width: '1.5rem', height: '1.5rem' }} />
            </IconButton>
          </div>
          {stages.map((stage, index) => (
            <React.Fragment key={index}>
              <List className='stage-item'>
                <div className='stage-content'>
                  <DragIcon className='drag' />
                  <span
                    className='stage-content-rectangle'
                    // style={{ background: stage.color }}
                  ></span>
                  {addNew && index === stages.length - 1 ? ( // Show input for the newly added stage
                    <div style={{ display: 'flex' }}>
                      <Select
                        fullWidth
                        sx={{
                          minWidth: '200px',
                          height: 'var(--Font-size-h2, 2.5rem)',
                          marginLeft: '0.5rem',
                        }}
                        value={newStageName}
                        onChange={(e: any) => {
                          updateStageName(index, e.target.value);
                        }}
                        size='small'>
                        <MenuItem value='' disabled>
                          Enter stage name
                        </MenuItem>
                        {globalObject.lookupvalues[LookupTypes.TAD_INTERVIEW_MAIN_STAGE].map(
                          (stage: any, index: number) => (
                            <MenuItem key={index} value={stage.id}>
                              {stage.name}
                            </MenuItem>
                          ),
                        )}
                      </Select>
                    </div>
                  ) : (
                    <>
                      <span className='stage-content-text'>
                        {getInterviewHeader(stage.stageName, stage.item ? stage.item : [])}
                      </span>
                      <IconButton
                        onClick={() => {
                          toggleExpand(index, stage.stageName);
                        }}
                        disabled={stage.isLocked}>
                        <ArrowIcons
                          className={`${stage.isLocked ? 'locked' : 'unlocked'} ${
                            expandedStage === index ? 'arrowRight' : 'arrowLeft'
                          }`}
                        />
                      </IconButton>
                    </>
                  )}
                </div>
                <div className='stage-actions'>
                  {stage.isLocked ? (
                    <IconButton disabled={true}>
                      <LockIcon className='lockIcon' />
                    </IconButton>
                  ) : (
                    <>
                      <IconButton
                        onClick={() => {
                          moveStageDown(index);
                          setExpandedStage(null);
                        }}
                        disabled={
                          index === stages.length - 1 ||
                          stage.isLocked ||
                          stages[index + 1]?.isLocked
                        }>
                        <ArrowIcons
                          className={`${
                            index === stages.length - 1 ||
                            stage.isLocked ||
                            stages[index + 1]?.isLocked
                              ? 'locked'
                              : 'unlocked'
                          } arrowLeft`}
                        />
                      </IconButton>
                      <IconButton
                        onClick={() => {
                          moveStageUp(index);
                          setExpandedStage(null);
                        }}
                        disabled={index === 0 || stage.isLocked || stages[index - 1]?.isLocked}>
                        <ArrowIcons
                          className={`${
                            index === 0 || stage.isLocked || stages[index - 1]?.isLocked
                              ? 'locked'
                              : 'unlocked'
                          } arrowRight`}
                        />
                      </IconButton>
                      <IconButton
                        onClick={() => {
                          removeStage(index);
                          setAddNew(false);
                          setExpandedStage(null);
                        }}>
                        <img
                          style={{ width: '1.5rem', height: '1.5rem' }}
                          src={CloseIcon}
                          alt='close'
                        />
                      </IconButton>
                    </>
                  )}
                </div>
              </List>
              {expandedStage === index && (
                <DropdownList
                  stageName={stage.stageName}
                  initialItems={stage.item ? stage.item : []}
                  // parentColor={stage.color}
                  onItemsChange={(updatedItems) => handleItemsChange(index, updatedItems)}
                />
              )}
            </React.Fragment>
          ))}
          <div className='add-item'>
            <Button
              onClick={() => {
                addStage();
                setAddNew(true);
              }}>
              <Add className='add-icon' />
              <span>Add Stage</span>
            </Button>
          </div>
        </div>
        <div style={{ padding: '1.87rem 1.88rem', borderTop: '1px solid #868686' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Button
              className='button'
              sx={{
                width: '7.375rem',
                height: '2.5rem',
                borderRadius: '5px',
                border: '1px solid #2D7DFE',
                backgroundColor: '#FFF',
                color: 'black',
                textTransform: 'none',
                fontSize: '1rem',
                fontWeight: 400,
              }}>
              Cancel
            </Button>
            <div style={{ display: 'flex', gap: '15px' }}>
              <Button
                className='button'
                onClick={() => {
                  previous();
                }}
                sx={{
                  width: '7.375rem',
                  height: '2.5rem',
                  borderRadius: '5px',
                  border: '1px solid #2D7DFE',
                  backgroundColor: '#FFF',
                  color: 'black',
                  textTransform: 'none',
                  fontSize: '1rem',
                  fontWeight: 400,
                }}>
                Previous
              </Button>
              <Button
                className='button'
                onClick={() => {
                  save !== undefined &&
                    stages.length > 0 &&
                    save({
                      interviewProcess: stages,
                    });
                }}
                sx={{
                  textTransform: 'none',
                  fontWeight: 400,
                  fontSize: '1rem',
                  width: '7.375rem',
                  height: '2.5rem',
                  color: 'white !important',
                  background: '#2D7DFE !important',
                }}>
                Save
              </Button>
            </div>
          </div>
        </div>
      </Paper>
    </div>
  );
};

export default InterViewProcess;
