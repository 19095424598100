import { Checkbox, ListItemText, MenuItem, Select } from '@mui/material';
import { useState } from 'react';

interface Props {
  headers: any;
  selectedHeaders: (value: number[]) => void;
}

const TadTableFilter = ({ headers, selectedHeaders }: Props) => {
  const [selectedColumns, setSelectedColumns] = useState<number[]>(
    headers.map((header: any) => header.id),
  );
  return (
    <div>
      <Select
        className='inputFeilds'
        sx={{ width: '9.375rem', height: '2.5rem' }}
        labelId='demo-mutiple-checkbox-label'
        id='demo-mutiple-checkbox'
        multiple
        value={selectedColumns}
        onChange={(e) => {
          const value = e.target.value as number[];
          if (value.includes(0)) {
            selectedHeaders(
              selectedColumns.length === headers.length
                ? []
                : headers.map((header: any) => header.id),
            );
            setSelectedColumns(
              selectedColumns.length === headers.length
                ? []
                : headers.map((header: any) => header.id),
            );
          } else {
            const newSelectedColumns = value.map(Number);
            selectedHeaders(newSelectedColumns.sort((a, b) => a - b));
            setSelectedColumns(newSelectedColumns.sort((a, b) => a - b));
          }
        }}
        renderValue={() => {
          return (
            <p>
              {selectedColumns.length == 0 ? (
                <span className='status-box'>Scheduled By</span>
              ) : selectedColumns.length == headers.length ? (
                <span className='status-box'>All Columns</span>
              ) : selectedColumns.length == 1 ? (
                <span className='status-box'>{selectedColumns[0]}</span>
              ) : (
                <span className='status-box'>Multiple Columns</span>
              )}
            </p>
          );
        }}>
        <MenuItem value={0} sx={{ padding: 0 }}>
          <Checkbox checked={selectedColumns.length === headers.length} />
          <ListItemText primary='All Columns' />
        </MenuItem>
        {headers.map((column: any) => (
          <MenuItem key={column.id} value={column.id} sx={{ padding: 0 }}>
            <Checkbox checked={selectedColumns.includes(column.id)} />
            <ListItemText primary={column.label} />
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};

export default TadTableFilter;
