import {
  Paper,
  Stepper,
  Step,
  StepLabel,
  Backdrop,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Link,
  Grid,
} from '@mui/material';
import PageHeader from './../common/page-header/page-header';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import React, { useState, useEffect } from 'react';
import { ColorlibConnector, ColorlibStepIcon } from '../common/horizontal-stepper';
import UploadResumeForm from './forms/upload-resume';
import CandidateDetailsForm from './forms/candidate-details-form';
import { CandidateDetailsModel } from './../../models/candidate-form.model';
import CandidateEmploymentForm from './forms/candidate-employment-form';
import CandidateEducationForm from './forms/candidate-education-form';
import { addCandidate, checkDuplicate, parseResume } from '../../service/candidateService';
import { useParams, useSearchParams } from 'react-router-dom';
import { getSkills } from '../../service/lookupService';
import { BreadCrumbConfig, mapSkills } from '../../common/commonUtil';
import useNavigate from './../../common/useNavigate';
import Notify from '../common/notify';
import { tagCandidateToSjd } from '../../service/sjdService';
import { LookupTypes } from '../../common/lookupTypes';
import { getNameFromId } from './../../common/commonUtil';
import * as pdfjs from 'pdfjs-dist';
import CandidateAdditionalInfoForm from './forms/candidate-additional-info-form';
import globalObject from '../../common/global-variables';
import { CANDIDATE_SOURCE } from '../../constants/common';
import { saveApplicant } from '../../service/applicantService';
import mammoth from 'mammoth';

const AddCandidate = () => {
  const params = useParams();
  const sjdId: string = params['sjdId'] ? atob(params['sjdId']) : '';
  const [navigate] = useNavigate();
  const [activeStep, setActiveStep] = useState(0);
  const [queryParams] = useSearchParams();
  const accessSource = atob(queryParams.get('source') || btoa(''));
  const title = atob(queryParams.get('title') || btoa(''));

  const steps = [
    'Upload Resume',
    'Candidate Details',
    'Employment',
    'Education & Certification',
    'Additional Info',
  ];
  const [candidateDetails, setCandidateDetails] = useState({
    operations: globalObject.userObject.operations,
    currentCtcFrequency: globalObject.lookupvalues[LookupTypes.PAY_TYPE].filter(
      (p: any) => p.name == 'Annually',
    )[0].id,
    expectedCtcFrequency: globalObject.lookupvalues[LookupTypes.PAY_TYPE].filter(
      (p: any) => p.name == 'Annually',
    )[0].id,
  } as CandidateDetailsModel);
  const [skills, setSkills] = useState([] as any[]);
  type AlertColor = 'success' | 'info' | 'warning' | 'error';
  const [showOverlay, setShowOverlay] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [file, setFile] = useState();
  const [docContent, setDocContent] = useState<string | null>(null);
  const [showAlert, setShowAlert] = useState(false);
  const [showFeedbackAlert, setShowFeedbackAlert] = useState(false);
  const workerSrcUrl = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;
  const [finalData, setFinalData] = useState({});
  const [details, setDetails] = useState<any[]>([]);
  const [toastMsg, setToastMsg] = useState({
    severity: 'success',
    msg: '',
  } as {
    severity: AlertColor;
    msg: string;
  });

  useEffect(() => {
    getSkills()
      .then((resp) => setSkills(resp.data))
      .catch((_err) => setSkills([]));
  }, []);

  const updatedCandidateDetails = (value: CandidateDetailsModel) => {
    setCandidateDetails({
      ...candidateDetails,
      ...value,
    });
  };

  const save = (value: CandidateDetailsModel) => {
    setFinalData(value);
    if (
      globalObject.properties.show_autopopulate_feedback == 'true' &&
      accessSource !== CANDIDATE_SOURCE.REFERRAL
    ) {
      setShowFeedbackAlert(true);
    } else {
      saveCandidate();
    }
  };

  const saveCandidate = (aiCheckbox?: boolean) => {
    const newObj = {
      ...candidateDetails,
      ...finalData,
      aiCheckbox,
    };
    setCandidateDetails(newObj);
    setShowOverlay(true);
    (accessSource === CANDIDATE_SOURCE.REFERRAL
      ? saveApplicant(file, newObj, sjdId)
      : addCandidate(file, newObj, sjdId)
    )
      .then((_resp) => {
        setToastMsg({
          severity: 'success',
          msg: 'Candidate added successfully',
        });
        setShowToast(true);
        setTimeout(() => {
          setShowOverlay(false);
          const existingConfig = localStorage.getItem('breadcrumb-config');
          let existing: BreadCrumbConfig[] = [];
          if (existingConfig) {
            existing = JSON.parse(existingConfig);
            existing.splice(existing.length - 1, 1);
            navigate(existing, true);
          } else {
            navigate(
              [
                {
                  label: 'Source Job Definition',
                  link: '/source-job-definition',
                },
              ],
              true,
            );
          }
        }, 3000);
      })
      .catch((_err) => {
        setShowOverlay(false);
        setToastMsg({
          severity: 'error',
          msg: 'Something went wrong. Please try later!',
        });
        setShowToast(true);
      });
  };

  const handleToastClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setShowToast(false);
  };

  function checkCandidateDetails(values: any) {
    if (
      getNameFromId(globalObject.userObject.operations, LookupTypes.OPERATION).startsWith(
        'Indian',
      ) &&
      accessSource !== CANDIDATE_SOURCE.REFERRAL
    ) {
      setShowOverlay(true);
      checkDuplicate(values)
        .then((res) => {
          const Data: any[] = res.data;
          setDetails(Data);
          if (Data && Data.length > 0) {
            setShowAlert(true);
          } else {
            setActiveStep(activeStep + 1);
          }
        })
        .catch((_err) => setActiveStep(activeStep + 1))
        .finally(() => setShowOverlay(false));
    } else {
      setActiveStep(activeStep + 1);
    }
  }

  const handleResumeUpload = async (file: any) => {
    console.log('url:', URL.createObjectURL(file));
    setFile(file);

    const fileType = file.name.split('.').pop().toLowerCase();

    if (fileType === 'pdf') {
      // PDF handling
      setDocContent(null); // Clear docContent for PDF rendering
    } else if (fileType === 'docx' || fileType === 'doc') {
      // DOC or DOCX handling
      const arrayBuffer = await file.arrayBuffer();
      const result = await mammoth.convertToHtml({ arrayBuffer });
      setDocContent(result.value);
    }
    setShowOverlay(true);
    parseResume(file)
      .then((resp) => {
        setShowOverlay(false);
        const parsedData: CandidateDetailsModel = resp.data;
        parsedData.skills = mapSkills(parsedData.resumeSkills, skills);
        setCandidateDetails({
          ...candidateDetails,
          ...parsedData,
        });
        if (parsedData.message && parsedData.message !== '') {
          setToastMsg({
            severity: 'error',
            msg: parsedData.message,
          });
          setShowToast(true);
        }
        if (parsedData.existing && parsedData.existing.length > 0) {
          setShowAlert(true);
        } else {
          setActiveStep(activeStep + 1);
        }
      })
      .catch((_err) => {
        setShowOverlay(false);
        setToastMsg({
          severity: 'error',
          msg: 'Something went wrong. Please try later!',
        });
        setShowToast(true);
      });
  };

  const tagCandidate = () => {
    setShowOverlay(true);
    if (details && details.length > 0) {
      tagCandidateToSjd({
        sjdId,
        candidateId: details[0].id,
      })
        .then(() => {
          navigate(
            [
              {
                label: 'SJD Names',
                link: `/source-job-definition/${btoa(sjdId)}`,
              },
            ],
            true,
          );
        })
        .catch(() => {
          setShowOverlay(false);
          setToastMsg({
            severity: 'error',
            msg: 'Something went wrong. Please try later!',
          });
          setShowToast(true);
        });
    }
  };

  const redirectToCandidatePage = (sjdData: any, candidateName: any) => {
    const config: BreadCrumbConfig[] = [
      {
        label: 'Job Definitions',
        link: '/source-job-definition',
      },
      {
        label: sjdData.sjdName,
        link: `/source-job-definition/${btoa(sjdData.sjdId)}`,
      },
      {
        label: candidateName,
        link: `/source-job-definition/candidate/${btoa(sjdData.sjdId)}/${btoa(
          sjdData.candidateId,
        )}`,
      },
    ];
    navigate(config, true);
  };

  return (
    <>
      {accessSource !== CANDIDATE_SOURCE.REFERRAL ? (
        <PageHeader title='Add Candidate'></PageHeader>
      ) : (
        <h3>{title}</h3>
      )}
      <Paper className='stepper-block'>
        <Stepper
          className='steps'
          alternativeLabel
          activeStep={activeStep}
          connector={<ColorlibConnector />}>
          {steps.map((label, index) => (
            <Step key={label}>
              <StepLabel StepIconComponent={ColorlibStepIcon}>
                <span className={index === activeStep ? 'step-label-selected' : 'step-label'}>
                  {label}
                </span>
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </Paper>
      {activeStep === 0 && <UploadResumeForm handleResumeUpload={handleResumeUpload} />}
      {activeStep === 1 && (
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <CandidateDetailsForm
              source={accessSource}
              values={candidateDetails}
              skills={skills}
              candidateCheck={checkCandidateDetails}
              previous={() => setActiveStep(activeStep - 1)}
              next={(val) => {
                updatedCandidateDetails(val);
                return new Promise((resolve, _reject) => resolve(true));
              }}
            />
          </Grid>

          <Grid item xs={4} className='view-pdf'>
            {docContent ? (
              <Paper>
                <div
                  style={{ padding: '20px 20px', maxHeight: '500px', overflow: 'auto' }}
                  dangerouslySetInnerHTML={{ __html: docContent }}
                />
              </Paper>
            ) : (
              <Worker workerUrl={workerSrcUrl}>
                <Viewer fileUrl={URL.createObjectURL(file ? file : new Blob()) as any} />
              </Worker>
            )}
          </Grid>
        </Grid>
      )}
      {activeStep === 2 && (
        <Grid container spacing={2}>
          <Grid item xs={7}>
            <CandidateEmploymentForm
              values={candidateDetails}
              sjdId={sjdId}
              previous={() => setActiveStep(activeStep - 1)}
              next={(val) => {
                updatedCandidateDetails(val);
                setActiveStep(activeStep + 1);
              }}
            />
          </Grid>
          <Grid item xs={5} className='view-pdf'>
            {docContent ? (
              <Paper>
                <div
                  style={{ padding: '20px 20px', maxHeight: '500px', overflow: 'auto' }}
                  dangerouslySetInnerHTML={{ __html: docContent }}
                />
              </Paper>
            ) : (
              <Worker workerUrl={workerSrcUrl}>
                <Viewer fileUrl={URL.createObjectURL(file ? file : new Blob()) as any} />
              </Worker>
            )}
          </Grid>
        </Grid>
      )}
      {activeStep === 3 && (
        <Grid container spacing={2}>
          <Grid item xs={7}>
            <CandidateEducationForm
              values={candidateDetails}
              sjdId={sjdId}
              previous={() => setActiveStep(activeStep - 1)}
              next={(val) => {
                updatedCandidateDetails(val);
                setActiveStep(activeStep + 1);
              }}
            />
          </Grid>
          <Grid item xs={5} className='view-pdf'>
            {docContent ? (
              <Paper>
                <div
                  style={{ padding: '20px 20px', maxHeight: '500px', overflow: 'auto' }}
                  dangerouslySetInnerHTML={{ __html: docContent }}
                />
              </Paper>
            ) : (
              <Worker workerUrl={workerSrcUrl}>
                <Viewer fileUrl={URL.createObjectURL(file ? file : new Blob()) as any} />
              </Worker>
            )}
          </Grid>
        </Grid>
      )}
      {activeStep === 4 && (
        <Grid container spacing={2}>
          <Grid item xs={7}>
            <CandidateAdditionalInfoForm
              values={candidateDetails}
              previous={() => setActiveStep(activeStep - 1)}
              next={(val) => {
                save(val);
              }}
            />
          </Grid>
          <Grid item xs={5} className='view-pdf'>
            {docContent ? (
              <Paper>
                <div
                  style={{ padding: '20px 20px', maxHeight: '500px', overflow: 'auto' }}
                  dangerouslySetInnerHTML={{ __html: docContent }}
                />
              </Paper>
            ) : (
              <Worker workerUrl={workerSrcUrl}>
                <Viewer fileUrl={URL.createObjectURL(file ? file : new Blob()) as any} />
              </Worker>
            )}
          </Grid>
        </Grid>
      )}
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={showOverlay}>
        <CircularProgress color='inherit' />
      </Backdrop>
      <Notify open={showToast} severity={toastMsg.severity} onClose={handleToastClose}>
        <span>{toastMsg.msg}</span>
      </Notify>
      {showFeedbackAlert && (
        <Dialog open={showFeedbackAlert} fullWidth={true} maxWidth='md'>
          <DialogTitle>Feedback</DialogTitle>
          <DialogContent>
            <DialogContentText>Is all data autopopulated correctly?</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              className='default-btn'
              onClick={() => {
                saveCandidate(true);
                setShowFeedbackAlert(false);
              }}>
              Yes
            </Button>
            <Button
              className='default-btn bg-btn'
              onClick={() => {
                saveCandidate(false);
                setShowFeedbackAlert(false);
              }}
              autoFocus>
              No
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {showAlert && (
        <Dialog
          open={showAlert}
          fullWidth={true}
          maxWidth='xl'
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'>
          <DialogTitle id='alert-dialog-title'>Confirm</DialogTitle>
          <DialogContent>
            <DialogContentText id='alert-dialog-description'>
              Candidate with the same email or phone number exists in our system. Please find the
              details below.
              <br />
              If you still want to add the existing candidate with this job definition, click
              <strong> Add to Job Definition </strong>button
            </DialogContentText>

            <Table aria-label='simple table'>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Phone</TableCell>
                  <TableCell>Job Definition</TableCell>
                  <TableCell>Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {details && (
                  <React.Fragment>
                    {(!details[0].sjdCandidateInfo || !details[0].sjdCandidateInfo.length) && (
                      <TableRow>
                        <TableCell>{details[0].name}</TableCell>
                        <TableCell>{details[0].email}</TableCell>
                        <TableCell>{details[0].phoneNumber}</TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    )}
                    {details[0].sjdCandidateInfo && details[0].sjdCandidateInfo.length > 0 && (
                      <TableRow>
                        <TableCell>{details[0].name}</TableCell>
                        <TableCell>{details[0].email}</TableCell>
                        <TableCell>{details[0].phoneNumber}</TableCell>
                        <TableCell>
                          <Link
                            sx={{ cursor: 'pointer' }}
                            onClick={() =>
                              redirectToCandidatePage(
                                details[0].sjdCandidateInfo[0],
                                details[0].name,
                              )
                            }>
                            {details[0].sjdCandidateInfo[0].sjdName}
                          </Link>
                        </TableCell>
                        <TableCell>
                          {getNameFromId(
                            details[0].sjdCandidateInfo[0].candidateStatusId,
                            LookupTypes.CANDIDATE_STATUS,
                          )}
                        </TableCell>
                      </TableRow>
                    )}
                  </React.Fragment>
                )}
              </TableBody>
            </Table>
          </DialogContent>
          <DialogActions>
            <Button
              className='default-btn'
              onClick={() => {
                setShowAlert(false);
              }}>
              Cancel
            </Button>
            <Button
              className='default-btn bg-btn'
              onClick={() => {
                setShowAlert(false);
                tagCandidate();
              }}
              autoFocus>
              Add to Job Definition
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default AddCandidate;
