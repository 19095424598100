import { Link, Menu, Typography } from '@mui/material';
import { getInitials } from '../../../common/commonUtil';
import { getNameFromId, handleCopy } from '../../common/commonUtil';
import { ReactComponent as EmailIcon } from '../../assets/common-assets/email.svg';
import { ReactComponent as MobileIcon } from '../../assets/common-assets/call.svg';
import { ReactComponent as LocationIcon } from '../../assets/common-assets/location_on.svg';
import { ReactComponent as CompanyIcon } from '../../assets/common-assets/account_balance.svg';
import { ReactComponent as OperationsIcon } from '../../assets/common-assets/public.svg';
import { ReactComponent as TimeIcon } from '../../assets/common-assets/access_time.svg';
import { ReactComponent as CopyIcon } from '../../assets/common-assets/content_copy.svg';
import { LookupTypes } from '../../../common/lookupTypes';

interface Props {
  contact: any;
  anchorEl: HTMLElement | null;
  onClose: () => void;
}

const ContactCard = ({ contact, anchorEl, onClose }: Props) => {
  const open = Boolean(anchorEl);

  return (
    <Menu
      //   id='menu-appbar'
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={open}
      onClose={onClose}
      PaperProps={{
        elevation: 0,
        sx: {
          top: '240px !important',
          height: '32.1875rem',
          width: '43.75rem',
          filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
          overflow: 'hidden',
        },
      }}
      disableAutoFocusItem>
      <div
        style={{
          //   width: '43.75rem',
          //   height: '32.1875rem',
          padding: '2.13rem 1.12rem 4.16rem 1.5rem',
        }}>
        <div style={{ display: 'flex', gap: '10px', marginBottom: '1.75rem' }}>
          <div
            className='user-icon'
            style={{
              backgroundColor: 'rgb(25, 118, 210)',
              color: 'white',
              height: '5rem',
              width: '5rem',
            }}>
            {contact && getInitials(contact)}
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
            <span
              className='menuItem'
              style={{
                fontSize: 'var(--Font-size-h4, 1.75rem)',
                fontWeight: 400,
                color: 'var(--Primary-Dark-grey-500, #333)',
              }}>
              {contact?.firstName} {contact?.lastName}
            </span>
            <span
              style={{
                fontSize: 'var(--Font-size-h6, 1.1875rem)',
                fontWeight: 400,
                color: 'var(--Primary-Dark-grey-500, #333)',
              }}>
              {contact && contact?.roles?.map((role: any) => role.name)}
            </span>
            <span
              style={{
                fontSize: 'var(--Font-size-P, 1rem)',
                fontWeight: 400,
                color: 'var(--Primary-Dark-grey-500, #333)',
              }}>
              {`Work Hours: ${'09:00'} - ${'18:00'}`}
            </span>
          </div>
        </div>
        <div style={{ marginBottom: '2.44rem' }}>
          <Typography
            variant='h6'
            sx={{ fontWeight: '600', fontSize: 'var(--Font-size-h6, 1.1875rem)' }}>
            Contact information
          </Typography>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div>
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1.88rem' }}>
              <EmailIcon style={{ marginRight: '1.12rem' }} />
              <div>
                <Typography className='feildHeader' sx={{ fontSize: '0.875rem' }}>
                  Email
                </Typography>
                <div
                  className='copy-container'
                  style={{ display: 'flex', alignItems: 'center', marginRight: '10px' }}>
                  <Link className='copy-label' href={'srinivas.desai@peopletech.com'}>
                    {contact && contact?.email}
                  </Link>
                  <CopyIcon
                    onClick={() => handleCopy(contact.email)}
                    className='copy'
                    style={{ cursor: 'pointer' }}
                  />
                </div>
              </div>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1.88rem' }}>
              <LocationIcon style={{ marginRight: '1.12rem' }} />
              <div>
                <Typography className='feildHeader' sx={{ fontSize: '0.875rem' }}>
                  Location
                </Typography>
                <span>Hyderabad</span>
              </div>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1.88rem' }}>
              <OperationsIcon style={{ marginRight: '1.12rem' }} />
              <div>
                <Typography className='feildHeader' sx={{ fontSize: '0.875rem' }}>
                  Operations
                </Typography>
                <span>
                  {contact && getNameFromId(contact?.operations, LookupTypes.TAD_OPERATION)}
                </span>
              </div>
            </div>
          </div>
          <div>
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1.88rem' }}>
              <MobileIcon style={{ marginRight: '1.12rem' }} />
              <div>
                <Typography className='feildHeader' sx={{ fontSize: '0.875rem' }}>
                  Mobile
                </Typography>
                <div
                  className='copy-container'
                  style={{ display: 'flex', alignItems: 'center', marginRight: '10px' }}>
                  <Link className='copy-label'>{contact && contact?.phoneNumber}</Link>
                  <CopyIcon
                    onClick={() => handleCopy(contact && contact?.phoneNumber)}
                    className='copy'
                    style={{ cursor: 'pointer' }}
                  />
                </div>
              </div>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1.88rem' }}>
              <CompanyIcon style={{ marginRight: '1.12rem' }} />

              <div>
                <Typography className='feildHeader' sx={{ fontSize: '0.875rem' }}>
                  Company
                </Typography>
                <span>{contact && getNameFromId(contact.company, LookupTypes.COMPANY)}</span>
              </div>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1.88rem' }}>
              <TimeIcon style={{ marginRight: '1.12rem' }} />

              <div>
                <Typography className='feildHeader' sx={{ fontSize: '0.875rem' }}>
                  Timezone
                </Typography>
                <span>19:37 - Same time zone as you</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Menu>
  );
};

export default ContactCard;
