import axiosClient from '../../common/axiosClient';
import { TadCandidateDetailsModel } from '../models/tad-candidate-form-model';

export async function getCandidate(sjdId?: any, candidateId?: any) {
  return axiosClient.get(`/tad-candidate/${candidateId}`, { params: { sjdId } });
}

export async function tadGetCandidatesForSjd(sjdId: any) {
  return axiosClient.get(`/tad-candidate/sjd/${sjdId}`);
}

export async function tadGetAllCandidate() {
  return axiosClient.get('/tad-candidate');
}

export async function tadGetAllInterviewSubStages(interviewStageId : any) {
  return axiosClient.get('/tad-candidate/interviewSubStages', { params : { interviewStageId } });
}

export async function tadAddCandidate(resume: any, request: TadCandidateDetailsModel) {
  const formData = new FormData();
  delete request.resumeSkills;
  formData.append('resume', resume);
  formData.append('details', JSON.stringify(request));
  return axiosClient.post('/tad-candidate', formData);
}

export async function tadScheduleInterview(request: any, accessToken: string) {
  return axiosClient.post('/tad-candidate/interview', request, {
    headers: {
      accessToken,
    },
  });
}


export async function updateCandidatePrimarySkills(request: any) {
  return axiosClient.patch('/candidate/primary-skills', {
    ...request,
  });
}
