import { Paper } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { ReactComponent as Share } from '../../../assets/candidate-assets/share.svg';
import { ReactComponent as Download } from '../../../assets/candidate-assets/download.svg';
import { getResume } from '../../../../service/candidateService';

interface TadCandidateDetailsModelProps {
  resumeId: any;
  isNewIndex?: boolean;
}

const CandidateResume: React.FC<TadCandidateDetailsModelProps> = ({
  resumeId,
  isNewIndex = false,
}) => {
  const [resumeURL, setResumeURL] = useState<string | null>(null);
  const [fileType, setFileType] = useState<string | null>(null);

  useEffect(() => {
    if (resumeId) {
      getResume(resumeId, !isNewIndex)
        .then((resp) => {
          const base64Content = resp.data;
          const fileName = resp.data.fileName || 'resume.pdf';

          if (!base64Content) {
            console.error('No file content received from API.');
            return;
          }

          const fileExt = fileName.split('.').pop()?.toLowerCase();

          let mimeType = 'application/octet-stream';
          if (fileExt === 'pdf') {
            mimeType = 'application/pdf';
          } else if (fileExt === 'doc') mimeType = 'application/msword';
          else if (fileExt === 'docx')
            mimeType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';

          try {
            const byteArray = Uint8Array.from(atob(base64Content), (char) => char.charCodeAt(0));
            const blob = new Blob([byteArray], { type: mimeType });
            const fileURL = URL.createObjectURL(blob);

            setResumeURL(fileURL);
            setFileType(fileExt);
          } catch (error) {
            console.error('Error decoding base64 content:', error);
          }
        })
        .catch((error: any) => {
          console.error('Error fetching resume:', error);
        });
    }

    return () => {
      if (resumeURL) URL.revokeObjectURL(resumeURL);
    };
  }, [resumeId, isNewIndex]);

  return (
    <Paper style={{ height: '50rem', width: '100%' }}>
      <div>
        <div
          style={{
            height: '61px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            backgroundColor: 'var(--Secondary-Grey-2, #E7E7E7)',
          }}>
          <div style={{ display: 'flex' }}>
            <span
              className='heading'
              style={{ height: '24px', fontSize: '18px', marginLeft: '1.81rem' }}>
              Candidate Resume
            </span>
          </div>
          <div style={{ display: 'flex', alignItems: 'center', gap: '2rem', marginRight: '1rem' }}>
            <Share />
            {resumeURL && (
              <Download
                style={{ color: 'black', cursor: 'pointer' }}
                onClick={() => window.open(resumeURL)}
              />
            )}
          </div>
        </div>
        <div style={{ textAlign: 'center', marginTop: '1rem' }}>
          {resumeURL ? (
            fileType === 'pdf' ? (
              <iframe
                src={resumeURL}
                title='Resume Preview'
                width='100%'
                height='700px'
                style={{ border: 'none' }}></iframe>
            ) : (
              <a href={resumeURL} download='resume'>
                Download Resume
              </a>
            )
          ) : (
            <p>No resume available to display.</p>
          )}
        </div>
      </div>
    </Paper>
  );
};

export default CandidateResume;
