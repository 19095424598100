import React, { useEffect, useState } from 'react';
import './tad-candidate-details-page.scss';
import {
  Backdrop,
  Chip,
  CircularProgress,
  Divider,
  Drawer,
  IconButton,
  Link,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Typography,
} from '@mui/material';
import { getNameFromId, handleCandidateInitials } from '../../common/commonUtil';
import CandidateHistory from './add-candidate/candidate-history';
import CandidateJobDefinition from './add-candidate/candidate-job-definition';
import CandidateMatchingJob from './add-candidate/candidate-matchingJob';
import CandidatesAttachments from './add-candidate/attachments';
import CandidateEmployementDetails from './add-candidate/candidate-employmentdetails';
import CandidateInterviewes from './add-candidate/candidate-interviews';
import InitialScreeningRatings from './initial-screening-ratings';
import TadInterviewFeedback from './tad-interview-feedback';
import TadPageHeader from '../common/page-header/tad-page-header';
import { ReactComponent as LocationIcon } from '../../assets/candidate-assets/location_on.svg';
import { ReactComponent as EmailIcon } from '../../assets/candidate-assets/email.svg';
import { ReactComponent as PhoneNumberIcon } from '../../assets/candidate-assets/call.svg';
import { ReactComponent as TrendingUp } from '../../assets/candidate-assets/trending_up.svg';
import { ReactComponent as TrendingDown } from '../../assets/candidate-assets/trending_up (1).svg';
import { ReactComponent as DotsIcon } from '../../assets/dashboard-assets/more_vert.svg';

import { ReactComponent as AddIcon } from '../../assets/sjd/add (1).svg';
import { ReactComponent as DownloadIcon } from '../../assets/candidate-assets/download.svg';
import { ReactComponent as CopyIcon } from '../../assets/common-assets/content_copy.svg';
import { ReactComponent as EmployeesIcon } from '../../assets/dashboard-assets/account_box.svg';
import { ReactComponent as ShareIcon } from '../../assets/candidate-assets/share (1).svg';
import { ReactComponent as EditIcon } from '../../assets/sjd/Edit.svg';
import { ReactComponent as ArchiveIcon } from '../../assets/sjd/Archive.svg';

import SliderComponent from '../common/slider/tad-slider';

import { getCandidate } from '../../service/tadCandidateService';
import { useParams } from 'react-router-dom';
import CandidateCertificateTable from './add-candidate/candidate-certificate-table';
import { LookupTypes } from '../../../common/lookupTypes';
import { getInitials } from '../../../common/commonUtil';
import AdditionalDetails from './add-candidate/additional-details';
import CandidateEducationTable from './add-candidate/candidate-education-table';
import CandidatePrimarySkills from './add-candidate/primary-skills';
import CandidateDetails from './add-candidate/candidate-details';
import { TadCandidateDetailsModel } from '../../models/tad-candidate-form-model';
import CandidateResume from './add-candidate/candidate-resume';
import AddCandidateToJobDrawer from './add-candidate/AddCandidateToJobDrawer';
import ContactCard from '../common/contact-card';
// import CandidateEducationTable from './add-candidate/candidate-education-table';

const CandidateDetailsPage = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  let { candidateId } = useParams();
  if (candidateId) candidateId = atob(candidateId);
  const [candidate, setCandidate] = useState({} as any);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [loading, setLoading] = useState(false);
  const [contactAnchor, setContactAnchor] = useState<null | HTMLElement>(null);
  const [contact, setContact] = useState({} as any);

  // const details = {
  //   id: 'CAN345',
  //   name: 'Alexa Woods',
  //   jobId: 'JD2828',
  //   jobTitle: 'Product Manager',
  //   location: 'Hyderabad, India',
  //   mobile: '+919705567295',
  //   prRating: '3.5/5',
  //   ss: '85.9',
  //   cg: '85.9',
  //   experience: '6 years',
  //   currCompany: 'Wipro India Pvt. Ltd.',
  //   notice: '30 Days',
  //   currentSalary: '$85,000',
  //   expectedSalary: '$100,000',
  //   uploadedOn: '2/5/2022',
  //   uploadedBy: 'arain jack',
  //   skills: 'Java, SQL, Spring 2.0',
  //   status: 'PROFILING',
  // };

  //   const params = useParams();
  //   const candidateId: string = params['candidateId'] ? atob(params['candidateId']) : '';
  useEffect(() => {
    setLoading(true);
    getCandidate(null, candidateId).then((res) => {
      setCandidate(res.data);
      setLoading(false);
    });
  }, []);

  const actionsList = [
    {
      name: 'Add to Job Definition',
      icon: AddIcon,
    },
    { name: 'Download Resume', icon: DownloadIcon },
    { name: 'Check for Duplicate', icon: CopyIcon },
    { name: 'Mark as an employee', icon: EmployeesIcon },
    { name: 'Share', icon: ShareIcon },
    { name: 'Edit', icon: EditIcon },
    { name: 'Archive', icon: ArchiveIcon },
  ];

  const sampleData = {
    noOfSteps: 7.5,
    selectedIndex: 5,
    currentStatus: 'Offer Accepted',
    sjd: {
      profileUpload: { status: 'Completed', date: '2024-10-01' },
      profiling: { status: 'Completed', date: '2024-10-03' },
      interviewScheduled: [
        { round: 'L1', status: 'Cleared', date: '2024-10-05' },
        { round: 'L2', status: 'Cleared', date: '2024-10-08' },
        { round: 'Client', status: 'Cleared', date: '2024-10-10' },
      ],
      hrDiscussion: { status: 'Cleared', date: '2024-10-12' },
      documentVerification: { status: 'Cleared', date: '2024-10-15' },
      offer: { status: 'Accepted', date: '2024-10-20' },
      joiningStatus: { status: 'Joined', date: '2024-10-25' },
      employee: { status: 'Active', date: '2024-11-01' },
    },
  };

  return (
    <div className='main-div'>
      <div style={{ marginTop: '1.69rem', marginBottom: '1.19rem' }}>
        <TadPageHeader title={candidate.name} />
      </div>
      <div
      // className='candidate-container'
      >
        <div className='header'>
          <Paper
            sx={{
              height: '19.9375rem',
              flexDirection: 'column',
              display: 'flex',
              marginBottom: '1.88rem',
            }}>
            <div style={{ height: '13.44rem', width: '100%' }}>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div
                  style={{
                    display: 'flex',
                    gap: '10px',
                    padding: '1.69rem 0rem 0rem 1.19rem',
                  }}>
                  <div
                    style={{
                      backgroundColor: '#DC3857',
                      color: 'white',
                      padding: '0.5rem',
                      borderRadius: '50%',
                      width: '3.75rem',
                      height: '3.75rem',
                      fontSize: '1.625rem',
                      fontWeight: '400',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-around',
                    }}>
                    {handleCandidateInitials(candidate?.name)}
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div className='overview' style={{ gap: '0.5rem' }}>
                      <span style={{ fontSize: '1.625rem' }}>{candidate.name}</span>
                      <img
                        src={require('../../assets/candidate-assets/linkedin.png')}
                        style={{
                          borderRadius: '0.3125rem',
                          color: '#333333',
                          fontWeight: '400',
                          textTransform: 'uppercase',
                        }}
                      />
                      <Chip
                        // label={getNameFromId(sjd.priorityId, LookupTypes.TAD_PRIORITY)}
                        label='L1 Interview'
                        style={{
                          borderRadius: '0.3125rem',
                          // backgroundColor: (() => {
                          //   const selectedOption = priorityOptions.find(
                          //     (status: any) =>
                          //       status.id === (sjd.priorityId !== undefined && sjd.priorityId),
                          //   );
                          //   return selectedOption ? selectedOption.color : 'transparent';
                          // })(),
                          color: '#333333',
                          fontWeight: '400',
                          // textTransform: 'uppercase',
                        }}
                      />
                      <Chip
                        label='Rejected'
                        // label={getNameFromId(sjd.priorityId, LookupTypes.TAD_PRIORITY)}

                        style={{
                          borderRadius: '0.3125rem',
                          // backgroundColor: (() => {
                          //   const selectedOption = priorityOptions.find(
                          //     (status: any) =>
                          //       status.id === (sjd.priorityId !== undefined && sjd.priorityId),
                          //   );
                          //   return selectedOption ? selectedOption.color : 'transparent';
                          // })(),
                          color: '#333333',
                          fontWeight: '400',
                          // textTransform: 'uppercase',
                        }}
                      />
                    </div>

                    <div className='overview' style={{ marginBottom: '0.87rem' }}>
                      <span style={{ fontSize: '1rem' }}>{candidate.currentRole}</span>
                    </div>
                    <div style={{ display: 'flex', gap: '1rem', flexDirection: 'column' }}>
                      <div className='overview'>
                        <LocationIcon style={{ color: '#868686', marginRight: '1rem' }} />
                        {/* <span style={{ fontSize: '1rem' }}>{candidate.location}</span> */}
                      </div>
                      <div className='overview'>
                        <EmailIcon style={{ marginRight: '1rem' }} />
                        <span style={{ fontSize: '1rem', textTransform: 'lowercase' }}>
                          {candidate.email}
                        </span>
                      </div>
                      <div className='overview'>
                        <PhoneNumberIcon style={{ marginRight: '1rem', color: '#868686' }} />
                        <span style={{ fontSize: '1rem' }}>
                          {candidate.phoneNumber}
                          {/* {parseLocation(sjd.jobLocation).join(', ')} */}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{ display: 'flex', padding: '1.69rem 0rem 0rem 1.19rem' }}>
                  <div style={{ display: 'flex' }}>
                    <div style={{ marginRight: '3rem' }}>
                      <div style={{ marginRight: '3rem', marginBottom: '3.06rem' }}>
                        <Typography
                          className='feildHeader'
                          sx={{ fontSize: '1rem', marginBottom: '0.5rem' }}>
                          Career Growth Score
                        </Typography>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                          <span className='text-label'>{candidate.careerGrowthScore}</span>
                          <TrendingUp style={{ color: '#4BB543' }} />
                        </div>
                      </div>
                      <div style={{ marginRight: '3rem', marginBottom: '3.06rem' }}>
                        <Typography
                          className='feildHeader'
                          sx={{ fontSize: '1rem', marginBottom: '0.5rem' }}>
                          Suitability Score
                        </Typography>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                          <span className='text-label'>
                            {candidate?.sjdCandidateInfo &&
                              candidate?.sjdCandidateInfo[0]?.suitabilityScore}
                          </span>
                          <TrendingDown style={{ color: '#DC3857' }} />
                        </div>
                      </div>
                    </div>
                    <div style={{ marginRight: '3rem' }}>
                      <div style={{ marginRight: '3rem', marginBottom: '3.06rem' }}>
                        <Typography
                          className='feildHeader'
                          sx={{ fontSize: '1rem', marginBottom: '0.5rem' }}>
                          Experience
                        </Typography>
                        <span className='text-label'>{`${candidate.totalExperience} ${
                          candidate.totalExperience > 1 ? 'Years' : 'Year'
                        }`}</span>
                      </div>
                      <div style={{ marginRight: '3rem', marginBottom: '3.06rem' }}>
                        <Typography
                          className='feildHeader'
                          sx={{ fontSize: '1rem', marginBottom: '0.5rem' }}>
                          Current Company
                        </Typography>
                        <span className='text-label'>{candidate.lastEmployer}</span>
                      </div>
                    </div>
                    <div style={{ marginRight: '3rem' }}>
                      <div style={{ marginRight: '3rem', marginBottom: '3.06rem' }}>
                        <Typography
                          className='feildHeader'
                          sx={{ fontSize: '1rem', marginBottom: '0.5rem' }}>
                          Notice Period (Days)
                        </Typography>
                        <span className='text-label'>{`${candidate.noticePeriod} Days`}</span>
                      </div>
                      <div
                        style={{
                          marginRight: '3rem',
                          marginBottom: '3.06rem',
                        }}>
                        <Typography
                          className='feildHeader'
                          sx={{ fontSize: '1rem', marginBottom: '0.5rem' }}>
                          Source
                        </Typography>
                        <span className='text-label'>
                          {getNameFromId(candidate.sourceChannel, LookupTypes.TAD_SOURCE_CHANNEL)}
                        </span>
                      </div>
                    </div>
                    <div>
                      <div style={{ marginBottom: '2.5rem' }}>
                        <Typography
                          className='feildHeader'
                          sx={{ fontSize: '1rem', marginBottom: '0.5rem' }}>
                          Uploaded by
                        </Typography>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                          <div
                            style={{
                              backgroundColor: '#DC3857',
                              color: 'white',
                              borderRadius: '50%',
                              width: '1.89731rem',
                              height: '1.89731rem',
                              fontWeight: '400',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'space-around',
                            }}>
                            {candidate.createdBy && getInitials(candidate.createdBy)}
                          </div>
                          <Link
                            onClick={(e) => {
                              setContact(candidate.createdBy);
                              setContactAnchor(e.currentTarget);
                            }}>
                            <span className='text-label' style={{ color: '#1976D2' }}>
                              {`${candidate.createdBy?.firstName} ${candidate.createdBy?.lastName}`}
                            </span>
                          </Link>
                          <ContactCard
                            contact={contact}
                            anchorEl={contactAnchor}
                            onClose={() => {
                              setContactAnchor(null);
                              setContact(null);
                            }}
                          />
                        </div>
                      </div>
                      <div style={{ marginBottom: '3.06rem' }}>
                        <Typography
                          className='feildHeader'
                          sx={{ fontSize: '1rem', marginBottom: '0.5rem' }}>
                          Sponsorship Required
                        </Typography>
                        <span className='text-label'>
                          {candidate.sponsorshipRequired ? 'Yes' : 'No'}
                        </span>
                      </div>
                    </div>
                    <div>
                      <IconButton onClick={(e) => setAnchorEl(e.currentTarget)}>
                        <DotsIcon />
                      </IconButton>
                      <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={() => setAnchorEl(null)}>
                        {actionsList.map((action, index) => (
                          <MenuItem
                            key={index}
                            onClick={() => {
                              setAnchorEl(null);
                              if (action.name === 'Add to Job Definition') {
                                setOpenDrawer(true);
                              }
                              // if (action.name === 'Put On Hold') {
                              //   sjdActionPatch(sjd, SJD_STATUS.ON_HOLD);
                              // } else if (action.name === 'Edit') {
                              //   navigate([
                              //     {
                              //       label: 'New Job Definition',
                              //       link: `/job-definition/${btoa(sjd.id)}`,
                              //     },
                              //   ]);
                              // }
                            }}>
                            <ListItemIcon>
                              <action.icon
                                style={{
                                  color: action.name === 'Check for Duplicate' ? '#1976d2' : '',
                                }}
                              />
                            </ListItemIcon>
                            <ListItemText primary={action.name} />
                          </MenuItem>
                        ))}
                      </Menu>
                      <Drawer
                        sx={{ width: '21.0625rem', flexShrink: 0 }}
                        anchor='right'
                        open={openDrawer}
                        onClose={() => setOpenDrawer(false)}>
                        <AddCandidateToJobDrawer candidate={candidate} />
                      </Drawer>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <Divider />
            </div>
            <div style={{ padding: '2.5rem' }}>
              <SliderComponent
                noOfSteps={sampleData.noOfSteps}
                selectedIndex={sampleData.selectedIndex}
                currentStatus={sampleData.currentStatus}
                sjd={sampleData.sjd}
              />
            </div>
          </Paper>
        </div>
        <div className='candidate-content'>
          <div className='candidate-content-left'>
            <div>
              <CandidateDetails
                values={candidate}
                next={(_value: TadCandidateDetailsModel) => {
                  throw new Error('Function not implemented.');
                }}
                mode='details-page'
              />
            </div>
            <div>
              <InitialScreeningRatings screeningratings={candidate.sjdCandidateInfo} />
            </div>
            <div style={{ width: '100%' }}>
              <CandidateInterviewes />
            </div>
            <div style={{ width: '100%' }}>
              <CandidateEmployementDetails employmentdetails={candidate.employmentDetails} />
            </div>
            <div style={{ width: '100%', height: '33.6875rem' }}>
              <CandidatesAttachments />
            </div>
            <div style={{ width: '100%' }}>
              <CandidateMatchingJob />
            </div>
            <div style={{ width: '100%' }}>
              <CandidateHistory _values={candidate.events} />
            </div>
            <div style={{ width: '100%' }}>
              <CandidateJobDefinition />
            </div>
          </div>
          <div className='candidate-content-right'>
            <div>
              <CandidateResume resumeId={candidate?.resumeId} />
            </div>
            <div>
              <CandidatePrimarySkills primaryskills={candidate.skills} />
            </div>
            <div>
              <AdditionalDetails values={candidate} mode='details-page' />
            </div>
            <div>
              <CandidateCertificateTable certificates={candidate.certificationDetails} />
            </div>
            <div></div>
            <div>
              <CandidateEducationTable education={candidate.educationDetails} />
            </div>
            <div>
              <TadInterviewFeedback />
            </div>
            <div>
              {/* Display JSON stringified data or "Data is null" if candidate is null */}
              {/* <pre>{candidate ? JSON.stringify(candidate, null, 2) : 'Data is null'}</pre> */}
            </div>
          </div>
          {/* <div>
            <span>{JSON.stringify(candidate)}</span>
          </div> */}
          {loading && (
            <Backdrop
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={true}>
              <CircularProgress color='inherit' />
            </Backdrop>
          )}
        </div>
      </div>
    </div>
  );
};

export default CandidateDetailsPage;
