import { Box, Button, FormControlLabel, Paper, Radio, RadioGroup, TextField } from '@mui/material';
import './initial-screening-ratings.scss';
import Rating from '@mui/material/Rating';
import StarIcon from '@mui/icons-material/Star';
import { ReactComponent as CloseIcon } from '../../assets/sjd/close.svg';
import { ReactComponent as AddIcon } from '../../assets/sjd/add.svg';
import GradeIcon from '@mui/icons-material/Grade';
import { useEffect, useState } from 'react';
import { SjdCandidateInfo } from '../../models/tad-candidate-form-model';

interface CandidateIntialScreenRatingsProps {
  screeningratings: SjdCandidateInfo[] | undefined;
}

const InitialScreeningRatings: React.FC<CandidateIntialScreenRatingsProps> = ({
  // eslint-disable-next-line react/prop-types
  screeningratings,
}) => {
  const [selectedUsers, setSelectedUsers] = useState<SjdCandidateInfo[]>([]);

  useEffect(() => {
    if (screeningratings) {
      setSelectedUsers(screeningratings);
    }
  }, [screeningratings]);
  const data = [
    {
      name: 'Hari Willson',
      date: '25/09/2023 04:55 PM',
      comments:
        'Experience and Skills are matching to this Requirements.However, CTC is in Budget. Having good Technical Knowledge into ABAP, s4 Hana .Can join in 90 Days. Can be proceed it for further.',
    },
  ];

  const screeningParameters = [
    { id: 0, value: 'Skills Matching', type: 'QC' },
    { id: 1, value: 'CTC in Budget', type: 'QC' },
    { id: 2, value: 'Can join in 90 days', type: 'PR' },
  ];
  const [addscreeningValues, setAddScreeningValues] = useState(screeningParameters);

  const [ratingType, setRatingType] = useState('QC');

  const handleClose = (id: number) => {
    const updateScreeningParam = addscreeningValues.filter((val) => val.id != id);
    setAddScreeningValues(updateScreeningParam);
  };

  const addNewRow = () => {
    setAddScreeningValues((addscreeningValues) => [
      ...addscreeningValues,
      { id: addscreeningValues.length, value: '', type: ratingType },
    ]);
  };
  const handleSkillChange = (id: number, value: string) => {
    const temp = [...addscreeningValues];
    const index = temp.findIndex((val) => val.id === id);
    temp[index].value = value;
    setAddScreeningValues(temp);
  };

  const filteredScreeningValues = addscreeningValues.filter((param) => param.type === ratingType);

  const displayRatingValue =
    ratingType === 'QC'
      ? selectedUsers[0]?.qcRating ?? 2.5
      : selectedUsers[0]?.profilerRating ?? 2.5;

  const currentRatingValue =
    ratingType === 'QC' ? selectedUsers[0]?.qcRating ?? 0 : selectedUsers[0]?.profilerRating ?? 0;

  return (
    <Paper style={{ height: '100%', paddingBottom: '30px' }}>
      <div className='header-container'>
        <div className='header-content-title'>
          <span>Initial Screening Ratings</span>
        </div>
      </div>
      <div style={{ paddingLeft: '1.63rem', paddingRight: '1.37rem' }}>
        <div style={{ marginTop: '20px', display: 'flex', flexDirection: 'column' }}>
          <span className='semi-bold'>Provide your inital resume screening ratings</span>
          <span style={{ marginTop: '0.32rem' }}>
            These initial ratings help recruiters in determining the next steps in hiring process
          </span>
        </div>
        <RadioGroup
          row
          aria-labelledby='demo-controlled-radio-buttons-group'
          name='controlled-radio-buttons-group'
          value={ratingType}
          onChange={(e) => setRatingType(e.target.value)}>
          <FormControlLabel value={'QC'} control={<Radio />} label='Quality Check (QC)' />
          <FormControlLabel value={'PR'} control={<Radio />} label='Profile Rating (PR)' />
        </RadioGroup>

        <div className='semi-bold' style={{ marginTop: '30px' }}>
          <span>{ratingType === 'QC' ? 'Quality Check (QC)' : 'Profile Rating (PR)'}</span>
        </div>

        {/* <div className='semi-bold' style={{ marginTop: '30px' }}>
          <span>Quality Check (QC)</span>
        </div> */}
        <div style={{ marginTop: '10px' }}>
          <div>
            <Rating
              className='rating'
              name='half-rating'
              value={
                ratingType === 'QC'
                  ? selectedUsers[0]?.qcRating ?? 0
                  : selectedUsers[0]?.profilerRating ?? 0
              }
              precision={0.5}
              emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize='inherit' />}
            />
          </div>
          <div>
            <span style={{ marginLeft: '8px' }}>
              {ratingType === 'QC'
                ? selectedUsers[0]?.qcRating ?? 2.5
                : selectedUsers[0]?.profilerRating ?? 2.5}
            </span>
          </div>
        </div>

        <div style={{ marginTop: '30px' }}>
          <span className='semi-bold'>Screening Parameters</span>
          {filteredScreeningValues.map((val) => (
            <Box key={val.id} className='box-bg'>
              <TextField
                variant='outlined'
                size='small'
                value={val.value}
                onChange={(e) => handleSkillChange(val.id, e.target.value)}
              />
              <Rating
                className='rating'
                name='half-rating'
                defaultValue={2.5}
                precision={0.5}
                // onChange={(e)=>handleRatingsChange}
                icon={<StarIcon className='filled-star' fontSize='inherit' />}
                emptyIcon={<StarIcon className='empty-star' fontSize='inherit' />}
                style={{ gap: '1rem', marginLeft: '20px' }}
              />
              <CloseIcon style={{ marginLeft: '350px' }} onClick={() => handleClose(val.id)} />
            </Box>
          ))}
        </div>

        <div style={{ marginTop: '40px', display: 'flex', alignItems: 'center' }}>
          <AddIcon className='add-icon path' onClick={addNewRow} />
          <span style={{ color: '#1976D2', textDecoration: 'underline' }}>Add</span>
        </div>

        <TextField
          fullWidth
          placeholder='Additional feedback if any'
          multiline
          minRows={3}
          maxRows={6}
          sx={{ marginTop: '20px' }}
        />

        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            style={{
              background: '#1976D2',
              color: '#ffffff',
              marginTop: '20px',
              width: '7rem',
              height: '2.5rem',
            }}>
            Submit
          </Button>
        </div>
        <hr style={{ background: '#868686', marginTop: '20px' }} />
        <div style={{ display: 'flex', marginTop: '30px' }}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <span className='semi-bold'>Quality Check Parameters</span>
            <div style={{ marginTop: '10px' }}>
              <div>
                <Rating
                  className='rating'
                  name='half-rating'
                  precision={0.5}
                  value={currentRatingValue}
                  icon={<StarIcon className='filled-star' fontSize='inherit' />}
                  emptyIcon={<StarIcon className='empty-star' fontSize='inherit' />}
                  style={{ gap: '1rem', marginTop: '20px' }}
                />
              </div>
              <div>
                <span>{displayRatingValue}</span>
              </div>{' '}
            </div>
            <div>
              {filteredScreeningValues.map((val) => (
                <div
                  key={val.id}
                  style={{
                    display: 'flex',
                    backgroundColor: '#F1F4F8',
                    width: '17.8125rem',
                    height: '2.3125rem',
                    alignItems: 'center',
                    paddingLeft: '10px',
                    marginBottom: '10px',
                  }}>
                  <span>{val.value}</span>
                  <div
                    style={{
                      marginLeft: '130px',
                      display: 'flex',
                      alignItems: 'center',
                    }}>
                    <GradeIcon style={{ color: '#FDD835' }} />{' '}
                    <span style={{ marginLeft: '5px' }}>3.5</span>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className='semi-bold' style={{ marginLeft: '10rem' }}>
            Profile Rating Parameters
            {filteredScreeningValues
              .filter((val) => val.type === 'PR')
              .map((val) => (
                <div
                  key={val.id}
                  style={{
                    display: 'flex',
                    backgroundColor: '#F1F4F8',
                    width: '17.8125rem',
                    height: '2.3125rem',
                    alignItems: 'center',
                    paddingLeft: '10px',
                    marginBottom: '10px',
                  }}>
                  <span>{val.value}</span>
                  <div
                    style={{
                      marginLeft: '130px',
                      display: 'flex',
                      alignItems: 'center',
                    }}>
                    <GradeIcon style={{ color: '#FDD835' }} />{' '}
                    <span style={{ marginLeft: '5px' }}>3.5</span>
                  </div>
                </div>
              ))}
            <p style={{ color: '#868686' }}>There are no profile ratings yet.</p>
          </div>
        </div>
        <div style={{ marginTop: '30px' }}>
          {data.map((data) => (
            <>
              <span className='header-label' style={{ fontWeight: 700, marginTop: '20px' }}>
                {data.name}
              </span>
              <br />
              <span className='feildHeader' style={{ marginBottom: '0.94rem' }}>
                {data.date}
              </span>
              <br />
              <span style={{ width: '18.4375rem' }}>{data.comments}</span>
            </>
          ))}
        </div>
      </div>
    </Paper>
  );
};

export default InitialScreeningRatings;
