import { Breadcrumbs, Link, Typography } from '@mui/material';
import { useState, useEffect } from 'react';
import { BreadCrumbConfig } from '../../../../common/commonUtil';
import useNavigate from '../../../../common/useNavigate';

interface Props {
  title?: string;
}

const TadPageHeader = ({ title }: Props) => {
  const [links, setLinks] = useState([] as BreadCrumbConfig[]);
  const [navigate, redirect] = useNavigate();

  useEffect(() => {
    const existingConfig = localStorage.getItem('breadcrumb-config');
    if (existingConfig) {
      setLinks(JSON.parse(existingConfig));
    }
  }, []);

  const handleClick = (index: number) => {
    const existingConfig = localStorage.getItem('breadcrumb-config');
    if (existingConfig) {
      const parsed = JSON.parse(existingConfig);
      localStorage.setItem('breadcrumb-config', JSON.stringify(parsed.slice(0, index + 1)));
      redirect(parsed[index].link);
    }
  };

  return (
    <div
      className='breadcrumb-block'
      id='breadcrumb-block'
      style={{
        background: 'var(--Secondary-colors-Grey-3)',
        display: 'flex',
        top: 52,
        // top: 'var(--header-height)',
        justifyContent: 'space-between',
      }}>
      <div style={{ display: 'flex' }}>
        {
          <Breadcrumbs aria-label='breadcrumb'>
            <Link
              underline='always'
              color='primary'
              onClick={() => {
                navigate(
                  [
                    {
                      label: 'Dashboard',
                      link: '/dashboard',
                    },
                  ],
                  true,
                );
              }}
              sx={{ cursor: 'pointer' }}>
              {'DASHBOARD'}
            </Link>
            {links.slice(0, links.length - 1).map((link, index) => (
              <Link
                key={index}
                underline='always'
                color='primary'
                onClick={() => handleClick(index)}
                sx={{ cursor: 'pointer' }}>
                {link.label}
              </Link>
            ))}
            {links && links.length > 0 && (
              <Link underline='none' color='inherit'>
                {links[links.length - 1].label}
              </Link>
            )}
            {title && (
              <Typography
                // variant='h1'
                sx={{ cursor: 'pointer' }}>
                {title}
              </Typography>
            )}
          </Breadcrumbs>
        }
      </div>
    </div>
  );
};

export default TadPageHeader;
