import React, { useState } from 'react';
import {
  Button,
  AvatarGroup,
  Avatar,
  FormControl,
  FormLabel,
  MenuItem,
  Select,
  FormControlLabel,
  Radio,
  RadioGroup,
  Drawer,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
// import { DatePicker, DateTimePicker } from '@mui/x-date-pickers';
import { ReactComponent as MoreVertIcon } from '../../../assets/dashboard-assets/more_vert.svg';
import { DateTimePicker } from '@mui/x-date-pickers';

const CandidateInterviewes = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  // const [startTime, setStartTime] = useState('');
  // const [endTime, setEndTime] = useState('');

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [interviewMode, setInterviewMode] = useState('');
  const [interviewLevel, setInterviewLevel] = useState('');
  const [evaluator, setEvaluator] = useState('');
  const [data, setData] = useState<any[]>([]);

  const handleInterviewModeChange = (event: any) => {
    setInterviewMode(event.target.value);
    console.log('Selected Interview Mode:', event.target.value);
  };

  const handleInterviewLevelChange = (event: any) => {
    setInterviewLevel(event.target.value);
    console.log('Selected Interview Level:', event.target.value);
  };

  const handleEvaluatorChange = (event: any) => {
    setEvaluator(event.target.value);
    console.log('Selected Evaluator:', event.target.value);
  };

  const handleSchedule = () => {
    const newAttachment = {
      documentName: `HR Interview with ${evaluator}`,
      uploadedBy: evaluator,
      // uploadedOn: `${startDate} ${startTime} - ${endTime}`,
      interviewMode: interviewMode,
    };

    setData([...data, newAttachment]);
  };

  const DrawerList = (
    <div
      style={{
        position: 'relative',
        margin: '2.5rem 1.81rem 0rem 1.81rem',
      }}>
      <h2 style={{ margin: '0', paddingBottom: '16px' }}>Schedule an Interview</h2>
      <IconButton
        aria-label='close'
        onClick={() => setMenuOpen(false)}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}>
        <CloseIcon />
      </IconButton>
      <hr style={{ color: '#C1C0C0', marginLeft: 'rem', marginRight: '1.25rem' }}></hr>

      <div style={{ marginBottom: '1rem', display: 'flex', flexDirection: 'row' }}>
        <FormControl fullWidth>
          <FormLabel
            style={{
              color: '#333',
              fontFamily: 'Roboto',
              fontSize: '1rem',
              fontWeight: 400,
              lineHeight: '1.5625rem',
            }}>
            Choose Interview Level
          </FormLabel>
          <Select
            fullWidth
            className='input-field'
            name='interviewLevel'
            value={interviewLevel}
            onChange={handleInterviewLevelChange} // Attach the event handler
            style={{ height: '2.5rem' }}>
            <MenuItem value='Technical L1'>Technical L1</MenuItem>
            <MenuItem value='Technical L2'>Technical L2</MenuItem>
            <MenuItem value='Technical L3'>Technical L3</MenuItem>
            <MenuItem value='Client Round'>Client Round</MenuItem>
            <MenuItem value='HR Round'>HR Round</MenuItem>
          </Select>
        </FormControl>
      </div>

      <FormControl component='fieldset' style={{ marginBottom: '1rem' }}>
        <FormLabel
          style={{
            color: '#333',
            fontFamily: 'Roboto',
            fontSize: '1rem',
            fontWeight: 400,
            lineHeight: '1.5625rem',
            marginLeft: '0.5rem',
          }}>
          Interview Mode
        </FormLabel>
        <RadioGroup
          name='templateOption'
          row
          style={{ alignItems: 'center', marginLeft: '0.5rem' }}
          value={interviewMode}
          onChange={handleInterviewModeChange}>
          <FormControlLabel
            value='Online'
            control={<Radio sx={{ width: '1.5rem', height: '1.5rem', flexShrink: 0 }} />}
            label={
              <span
                style={{
                  color: '#333',
                  fontFamily: 'Roboto',
                  fontSize: '1rem',
                  fontWeight: 400,
                  lineHeight: '1.5625rem',
                }}>
                Online
              </span>
            }
          />
          <FormControlLabel
            value='In Person'
            control={
              <Radio
                sx={{ width: '1.5rem', height: '1.5rem', flexShrink: 0, marginLeft: '2rem' }}
              />
            }
            label={
              <span
                style={{
                  color: '#333',
                  fontFamily: 'Roboto',
                  fontSize: '1rem',
                  fontWeight: 400,
                  lineHeight: '1.5625rem',
                }}>
                In Person
              </span>
            }
          />
        </RadioGroup>
      </FormControl>

      <div style={{ marginBottom: '1rem', display: 'flex', flexDirection: 'row' }}>
        <FormControl fullWidth>
          <FormLabel
            style={{
              color: '#333',
              fontFamily: 'Roboto',
              fontSize: '1rem',
              fontWeight: 400,
              lineHeight: '1.5625rem',
            }}>
            Choose Evaluator
          </FormLabel>
          <Select
            fullWidth
            className='input-field'
            name='evaluator'
            value={evaluator}
            onChange={handleEvaluatorChange} // Attach the event handler
            style={{ height: '2.5rem' }}>
            <MenuItem value='Divya'>Divya</MenuItem>
            <MenuItem value='Sandhya'>Sandhya</MenuItem>
            <MenuItem value='Deependra'>Deependra</MenuItem>
            <MenuItem value='Deepak'>Deepak</MenuItem>
            <MenuItem value='Sandy'>Sandy</MenuItem>
          </Select>
        </FormControl>
      </div>

      <div style={{ marginBottom: '1rem', display: 'flex', flexDirection: 'row' }}>
        <FormControl fullWidth>
          <div style={{ display: 'flex', flexDirection: 'row', gap: '2rem' }}>
            <div style={{ display: 'flex', flexDirection: 'column', flexShrink: 0 }}>
              <FormLabel
                style={{
                  color: '#333',
                  fontFamily: 'Roboto',
                  fontSize: '1rem',
                  fontWeight: 400,
                  lineHeight: '1.5625rem',
                }}>
                Start Date & Time
              </FormLabel>

              <div style={{ display: 'flex', gap: '1.88rem', width: '100%' }}>
                {/* <DatePicker
                  className='date-picker'
                  format='DD/MM/YYYY'
                  disablePast
                  value={startDate}
                  onChange={(newValue) => {
                    setStartDate(newValue); // Set the date directly as it comes from DatePicker
                  }}
                  sx={{ height: '2.5rem', flexGrow: 1, width: '10.8rem' }} // Use flexGrow to make it fill available space
                /> */}
                <DateTimePicker
                  sx={{ width: '100%' }}
                  // value={dayjs(formik.values.endTime)}
                  value={startDate}
                  format='DD/MM/YYYY hh:mm A'
                  className='date-picker'
                  onChange={(newValue: any) => {
                    setStartDate(newValue);
                  }}
                  // onChange={(value) => {
                  //   formik.setFieldValue(
                  //     'endTime',
                  //     dateFormat(value?.toString(), 'isoDateTime'),
                  //     true,
                  //   );
                  // }}
                  // style={{ height: '2.5rem', flexGrow: 1, width: '10.8rem' }}
                />
              </div>
            </div>

            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                flexShrink: 0,
                marginTop: '-1rem',
              }}>
              <FormLabel
                style={{
                  color: '#333',
                  fontFamily: 'Roboto',
                  fontSize: '1rem',
                  fontWeight: 400,
                  lineHeight: '1.5625rem',
                  marginTop: '1rem',
                }}>
                End Date & Time
              </FormLabel>

              <div style={{ display: 'flex', gap: '1.88rem', width: '100%' }}>
                {/* <DatePicker
                  className='date-picker'
                  format='DD/MM/YYYY'
                  disablePast
                  value={endDate}
                  onChange={(newValue) => {
                    setEndDate(newValue);
                  }}
                  sx={{ height: '2.5rem', flexGrow: 1, width: '10.8rem' }}
                /> */}

                <DateTimePicker
                  sx={{ width: '100%' }}
                  // value={dayjs(formik.values.endTime)}
                  value={endDate}
                  format='DD/MM/YYYY hh:mm A'
                  className='date-picker'
                  onChange={(newValue) => {
                    setEndDate(newValue);
                  }}
                  // onChange={(value) => {
                  //   formik.setFieldValue(
                  //     'endTime',
                  //     dateFormat(value?.toString(), 'isoDateTime'),
                  //     true,
                  //   );
                  // }}
                />
              </div>
            </div>
          </div>
        </FormControl>
      </div>

      <hr style={{ color: '#C1C0C0', marginLeft: 'rem', marginRight: '1.25rem' }}></hr>

      <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
        <Button
          sx={{
            width: '7.375rem',
            height: '2.5rem',
            borderRadius: '5px',
            border: '1px solid #2D7DFE',
            backgroundColor: '#FFF',
            color: 'black',
            textTransform: 'none',
            fontSize: '1rem',
            fontWeight: 400,
          }}
          onClick={() => setMenuOpen(false)}
          style={{ marginRight: '10px' }}>
          Cancel
        </Button>
        <Button
          sx={{
            textTransform: 'none',
            fontWeight: 400,
            fontSize: '1rem',
            width: '7.375rem',
            height: '2.5rem',
            color: 'white !important',
            background: '#2D7DFE !important',
          }}
          color='primary'
          onClick={handleSchedule}>
          Schedule
        </Button>
      </div>
    </div>
  );

  return (
    <>
      <div
        style={{
          height: '61px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          backgroundColor: 'var(--Secondary-Grey-2, #E7E7E7)',
        }}>
        <div style={{ display: 'flex' }}>
          <span
            className='heading'
            style={{ height: '24px', fontSize: '18px', marginLeft: '1.81rem' }}>
            Interviews
          </span>
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span
            style={{
              marginRight: '2rem',
              width: '1.5rem',
              height: '1.5rem',
              flexShrink: 0,
              fontSize: '1.5rem',
              lineHeight: '1.5rem',
              display: 'flex',
              alignItems: 'center',
              cursor: 'pointer',
            }}
            onClick={() => setMenuOpen(true)}>
            +
          </span>
          <MoreVertIcon style={{ marginRight: '10px' }} />
        </div>
      </div>

      <div style={{ backgroundColor: 'var(--White-Color, #FFF)' }}>
        {data.map((attachment, index) => (
          <div
            key={index}
            style={{
              width: '38.5rem',
              height: '11.1875rem',
              margin: '1.37rem 1.38rem 0rem 1.63rem',
              backgroundColor: 'var(--White-Color, #FFF)',
            }}>
            <div
              style={{
                height: '61px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}>
              <div style={{ display: 'flex', gap: '1.0rem' }}>
                <span
                  className='heading'
                  style={{ height: '24px', fontSize: '18px', marginLeft: '1.81rem' }}>
                  {attachment.documentName}
                </span>
                <span
                  className='heading'
                  style={{
                    height: '1.875rem',
                    display: 'flex',
                    justifyContent: 'center',
                    width: '6.4375rem',
                    fontSize: '18px',
                    background: 'rgba(122, 224, 139, 0.59)',
                    borderRadius: '0.3125rem',
                  }}>
                  Scheduled
                </span>
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <MoreVertIcon style={{ marginRight: '10px' }} />
              </div>
            </div>

            <div style={{ height: '1.5625rem', display: 'flex', justifyContent: 'start' }}>
              <span
                className='heading'
                style={{
                  height: '24px',
                  fontSize: '18px',
                  marginLeft: '1.81rem',
                  color: 'var(--Secondary-colors-Grey, #868686)',
                }}>
                {attachment.uploadedOn} {/* Displaying the scheduled time */}
              </span>
            </div>

            <div style={{ height: '1.5625rem', display: 'flex', justifyContent: 'start' }}>
              <AvatarGroup
                max={4}
                sx={{
                  justifyContent: 'flex-end',
                  height: '30px',
                  width: '30px',
                  marginLeft: '1.81rem',
                }}>
                <Avatar
                  alt='Remy Sharp'
                  src='/static/images/avatar/1.jpg'
                  sx={{ height: '30px', width: '30px' }}
                />
                <Avatar
                  alt='Travis Howard'
                  src='/static/images/avatar/2.jpg'
                  sx={{ height: '30px', width: '30px' }}
                />
              </AvatarGroup>
            </div>

            <div
              style={{
                height: '61px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}>
              <div style={{ display: 'flex', gap: '1.0rem' }}>
                <span
                  className='heading'
                  style={{ height: '24px', fontSize: '1rem', marginLeft: '1.81rem' }}>
                  {attachment.interviewMode} Via Microsoft Teams {/* Displaying interview mode */}
                </span>
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Button variant='contained' color='primary'>
                  Join Meeting
                </Button>
              </div>
            </div>
          </div>
        ))}
      </div>

      <Drawer
        sx={{ width: '21.0625rem', height: '64rem', flexShrink: 0 }}
        anchor='right'
        open={menuOpen}
        onClose={() => setMenuOpen(false)}>
        {DrawerList}
      </Drawer>
    </>
  );
};

export default CandidateInterviewes;
